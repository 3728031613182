import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import { TabView, TabPanel } from "primereact/tabview";
import service from '../../service/apiService';
import moment from "moment";
import { colorPalettes } from "../../lib/constants";
import { dateFormatter, formatToTwoDigits } from "../../utilities/utillFunction";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { chartHeadingStyle } from "../../utilities/constant";

const B2COrders = ({ cr = 'nq-dashboard-b2c-orders-cr-by-channel', received = 'nq-dashboard-b2c-orders-by-channel', shipped = 'nq-dashboard-b2c-orders-shipped-by-channel', b2b = false }) => {
    const serviceId = localStorage.getItem('serviceId')
    const [forPeriod, setForPeriod] = useState([])
    const [today, setToday] = useState([])
    const [yesterday, setYesterday] = useState([])
    const [activeIndex, setActiveIndex] = useState(0)
    const [todayInfoMsg, setTodayInfoMsg] = useState(null)
    const [yesterdayInfoMsg, setYesterdayMsg] = useState(null)
    const [lineChart, setLineChart] = useState(null)
    const [lastPeriod, setLastPeriod] = useState(null)
    const [crLineChart, setCrLineChart] = useState(null)
    const todayDt = moment().format("YYYY-MM-DD")
    useEffect(() => {
        getChartData()
        getData(todayDt, "forPeriod")
        getData(todayDt, 'today')
    }, [])

    useEffect(() => {
        let matchObj = {
            Status: { $in: ["Pending", "Failed"] },
        date: {
            $gte: moment().subtract(10, "days").format("YYYY-MM-DD"),
        }}
        if(!b2b)matchObj["fdChannelDesc"]={ "$ne": "CMS" }
        let query = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: matchObj
                },
                {
                    $group: {
                        _id: {
                            status: "$Status",
                            date: "$date"
                        },
                        totalCount: { $sum: "$Count" }
                    }
                },
                {
                    $sort: {
                        "_id.date": 1
                    }
                }
            ]
        }
        Promise.all([service.httpDBService(query, serviceId, cr)]).then((res) => {
            let chartData = {
                category: [],
                series: []
            };
            let pendFailMap = {};
            let statuses = new Set();
            if (res && res.length) {
                res[0].forEach((item) => {
                    if (item?._id?.date && item?.totalCount) {
                        const date = item._id.date;
                        const status = item._id.status;
                        statuses.add(status);
                        if (!pendFailMap[date]) {
                            pendFailMap[date] = {};
                        }
                        pendFailMap[date][status] = item.totalCount;
                    }
                });
                const sortedDates = Object.keys(pendFailMap).sort((a, b) => new Date(a) - new Date(b));
                chartData.category = sortedDates;
                const uniqueStatuses = Array.from(statuses);
                const statusColors = {
                    pending: '#184EA0',
                    failed: '#E76F51',
                    completed: '#0000ff'
                };
                uniqueStatuses.forEach((status) => {
                    const seriesEntry = {
                        name: status.toUpperCase(),
                        data: [],
                        color: statusColors[status.toLowerCase()] || colorPalettes['color' + (chartData.series.length + 1)]
                    };
                    sortedDates.forEach((date) => {
                        seriesEntry.data.push(pendFailMap[date][status] || 0);
                    });
                    chartData.series.push(seriesEntry);
                });
                const lineChartData = {
                    chart: {
                        type: "spline"
                    },
                    title: {
                        text: "Content Request Trend (Pending & Failure)",
                        style: chartHeadingStyle
                    },
                    xAxis: {
                        categories: chartData.category,
                        type: 'datetime',
                        labels: {
                            formatter: function () {
                                return Highcharts.dateFormat('%b %e', new Date(this.value));
                            }
                        }
                    },
                    yAxis: {
                        title: {
                            text: "Count"
                        }
                    },
                    tooltip: {
                        crosshairs: true,
                        shared: true,
                        formatter: function () {
                            return `${Highcharts.dateFormat('%b %e', new Date(this.x))}<br>` +
                                this.points.map(point =>
                                    `<span style="color:${point.color}">●</span> ${point.series.name}: ${point.y}`
                                ).join('<br>');
                        }
                    },
                    plotOptions: {
                        spline: {
                            marker: {
                                enabled: true
                            },
                            dataLabels: {
                                enabled: true,
                                style: {
                                    fontWeight: 'bold',
                                    fontSize: '10px'
                                },
                                formatter: function () {
                                    return this.y !== null ? this.y : '';
                                }
                            }
                        }
                    },
                    series: chartData.series
                };
                setCrLineChart(lineChartData);
            }
        });
    }, [])
    const getCollection = (type) => {
        let collection = {}
        if (type === "forPeriod") {
            collection = {
                'cr': cr,
                'received': received,
                'shipped': shipped
            }
        }
        else if (type === "today" || type === "yesterday") {
            collection = {
                'cr': cr,
                'received': received,
                'shipped': shipped
            }
        }
        return collection
    }
    const getData = (date, type) => {
        const collections = getCollection(type)
        let query = { filter: { date: date } }
        query["filter"] = b2b ? {
            ...query["filter"],
            fdChannelDesc: { "$nin": ["Amazon", 'Flipkart', 'D2C'] }
        } : {
            ...query["filter"],
            fdChannelDesc: { "$ne": "CMS" }
        }

        Promise.all([
            service.httpDBService(query, serviceId, collections.cr),
            service.httpDBService(query, serviceId, collections.received),
            service.httpDBService(query, serviceId, collections.shipped)
        ])
            .then((res) => {
                let cr_data = res[0], rec_data = res[1], ship_data = res[2]
                let cr_data_len = cr_data.length, rec_data_len = rec_data.length, ship_data_len = ship_data.length
                if (!res[0].length && !res[1].length && !res[2].length) {
                    if (type == "today") setTodayInfoMsg("Data is not available for the day")
                    else if (type == "yesterday") setYesterdayMsg("Data is not available")
                    return
                }
                let ecomm_arr = [], rec_ecomm_map = {}, ship_ecomm_map = {}, cr_ecomm_map = {}, orders_Revived = {}
                if (cr_data_len) {
                    cr_data.forEach(c => {
                        cr_ecomm_map[c.fdChannelDesc + c.Status] = c
                        ecomm_arr.push(c.fdChannelDesc)
                    })
                }
                if (rec_data_len) {
                    rec_data.forEach(c => {
                        rec_ecomm_map[c.fdChannelDesc] = c
                        ecomm_arr.push(c.fdChannelDesc)
                    })
                }
                if (ship_data_len) {
                    ship_data.forEach(c => {
                        ship_ecomm_map[c.fdChannelDesc] = c
                        ecomm_arr.push(c.fdChannelDesc)
                    })
                }

                if (type == 'forPeriod' && rec_data.length) {

                    let dt_obj = { from: rec_data[0].date + " " + formatToTwoDigits(rec_data[0].start_hr) + ":00:00", to: rec_data[0].date + " " + formatToTwoDigits(rec_data[0].end_hr) + ":00:00" }
                    setLastPeriod(dt_obj)
                }

                let ecomm_set = new Set(...[ecomm_arr])
                let ecomm_ch = [...ecomm_set], arr = []
                let tcrS = 0, tcrF = 0, tcrP = 0, tship = 0, tcrPro = 0, Trec = 0
                ecomm_ch.sort().forEach(e => {
                    let succ = cr_ecomm_map[e + 'Successful'] ? cr_ecomm_map[e + 'Successful']?.Count || 0 : 0, fail = cr_ecomm_map[e + 'Failed'] ? cr_ecomm_map[e + 'Failed']?.Count || 0 : 0;
                    let pending = cr_ecomm_map[e + 'Pending'] ? cr_ecomm_map[e + 'Pending']?.Count || 0 : 0, inprocess = cr_ecomm_map[e + 'In Process'] ? cr_ecomm_map[e + 'In Process']?.Count || 0 : 0
                    let pod = ship_ecomm_map[e] ? ship_ecomm_map[e].Shipped_Count_POD || 0 : 0, psm = ship_ecomm_map[e] ? ship_ecomm_map[e].Shipped_Count_PSM || 0 : 0
                    let rec = rec_ecomm_map[e] ? rec_ecomm_map[e].totalOrderQty : 0
                    let ship = pod + psm
                    let obj = {
                        ecommerce: e, rec: rec, cr_pending: pending, cr_in_process: inprocess, cr_success: succ,
                        cr_failure: fail, ship_pod: pod, ship_psm: psm, ship
                    }
                    // tcrS += succ
                    // tcrF += fail
                    // tcrP += pending
                    // tcrPro += inprocess
                    // tship += ship
                    // Trec += rec
                    arr.push(obj)
                })
                // if(b2b){
                //     arr.push({
                //         cr_failure: tcrF,
                //         cr_in_process: tcrPro,
                //         cr_pending: tcrP,
                //         cr_success: tcrS,
                //         ecommerce: "Total",
                //         rec: Trec,
                //         ship: tship
                //     }); 
                // }               

                if (type == 'forPeriod') setForPeriod(arr)
                else if (type == 'today') {
                    setToday(arr)
                }
                else if (type == 'yesterday') setYesterday(arr)
            }
            ).catch(e => {
                console.error('Error while getting data', e)
            })
    }
    const getChartData = async () => {
        let prev10dayDt = moment().subtract(10, "days").format("YYYY-MM-DD")
        let dis_query = {
            operation: "distinct",
            key: "fdChannelDesc",
            query: { date: { $gte: prev10dayDt, $lte: todayDt } }
        }
        if (!b2b) dis_query = { ...dis_query, query: { date: { $gte: prev10dayDt, $lte: todayDt }, fdChannelDesc: { "$ne": 'CMS' } } }
        let dis_channel = await service.httpDBService(dis_query, serviceId, received)
        let group_by = { _id: '$date' }
        let channel_chart_data = {}
        dis_channel.forEach(c => {
            group_by[c] = { $sum: { $cond: [{ $eq: ["$fdChannelDesc", c] }, '$totalOrderQty', 0] } }
            channel_chart_data[c] = []
        })

        let orders_q = {
            operation: "aggregate",
            aggregate: [
                { $match: { date: { $gte: prev10dayDt, $lte: todayDt } } },
                { $group: group_by },
                { $sort: { _id: 1 } }
            ]
        }

        let channel_data = await service.httpDBService(orders_q, serviceId, received)
        let dates = []
        if (channel_data.length) {
            channel_data.forEach(o => {
                dates.push(dateFormatter(o._id, 'DD MMM', 'YYYY-MM-DD'))
                dis_channel.forEach(c => {
                    channel_chart_data[c].push(o[c])
                })
            })
        }
        let series = []
        dis_channel.forEach((c, i) => {
            series.push({ name: c, data: channel_chart_data[c], color: colorPalettes['color' + (i + 1)] })
        })

        let lineChartData = {
            chart: {
                type: "spline",
            },
            title: {
                text: "Orders Received Trend",
                style: chartHeadingStyle,
            },

            xAxis: {
                categories: dates,
            },
            yAxis: {
                title: {
                    text: "Orders Received",
                },
                labels: {
                    formatter: function () {
                        return this.value + "";
                    },
                },
            },
            tooltip: {
                crosshairs: true,
                shared: true,
            },
            plotOptions: {
                spline: {
                    dataLabels: {
                        enabled: true
                    },
                },
            },
            series: series
        }
        setLineChart(lineChartData)
    }

    const todayCrStatusSum = (arr, type) => {
        let data = {
            [type]: 0
        }
        arr.map((item) => {
            for (const key in data) {
                data[key] += item[key]
            }
        })
        return  data[type];
    }

    const customHeader = (
        <ColumnGroup>
            {/* <Row >
                <Column headerClassName="blue-bg" header="" colSpan={6} className="text-center"></Column>
                <Column headerClassName="blue-bg" header="Shipped" colSpan={2} className="text-center"></Column>
                <Column style={{backgroundColor: "#80808059"}} header="" className="text-center"></Column>
            </Row> */}
            <Row>
                {!b2b ?<Column headerClassName="blue-bg" header="" className="text-center" ></Column>:null}
                {/* <Column headerClassName="blue-bg" header="Received" className="text-center" ></Column> */}
                {/* <Column headerClassName="blue-bg" header="Orders Placed" className="text-center" ></Column> */}
                {/* {b2b && <Column headerClassName="blue-bg" header="Orders Shipped" className="text-center" ></Column>} */}
                {b2b && <Column headerClassName="blue-bg" header="Orders Qty" className="text-center" ></Column>}
                <Column headerClassName="blue-bg" header="CR Pending" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="CR In Process" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="CR Success" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="CR Failure" className="text-center" ></Column>
                {/* <Column headerClassName="blue-bg" header="POD" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="PSM" className="text-center" ></Column>
                <Column style={{backgroundColor: "#80808059"}} header=" % Shipped (18hrs)" className="text-center" ></Column> */}
            </Row>
        </ColumnGroup>
    )
    const TableView = (data) => {
        return (
            <div>
                <DataTable value={data} responsiveLayout="scroll" showGridlines headerColumnGroup={customHeader}>
                    {!b2b ? <Column headerClassName="blue-bg" className="text-center" field='ecommerce'></Column>:null}
                    {/* <Column headerClassName="blue-bg" className="text-center" field='rec'></Column> */}
                    {/* <Column headerClassName="blue-bg" header="Orders Placed" className="text-center" ></Column> */}
                    {/* {b2b && <Column headerClassName="blue-bg" field="ship" className="text-center" ></Column>} */}
                    {b2b && <Column headerClassName="blue-bg" field='rec' className="text-center" ></Column>}
                    <Column headerClassName="blue-bg" className="text-center" field='cr_pending'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='cr_in_process'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='cr_success'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='cr_failure'></Column>
                    {/* <Column headerClassName="blue-bg" className="text-center" field='ship_pod'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='ship_psm'></Column>
                    <Column bodyStyle={{backgroundColor: "#80808059"}} className="text-center" field='shiiped_18_hr'></Column> */}
                </DataTable>
            </div>
        )
    }
    const onTabChange = (e) => {
        if (e.index === 0 || e.index === 1) {
            let dt = todayDt
            let type = 'today'
            if (e.index === 1) {
                dt = moment().subtract(1, "days").format("YYYY-MM-DD")
                type = 'yesterday'
            }
            if ((e.index === 0 && !today.length) || (e.index === 1 && !yesterday.length)) getData(dt, type)
        }
        setActiveIndex(e.index)
    }
    const totalRec = (arr) => {
        let res = 0;
        arr.map((item) => {
            res += item.rec
        })
        return res;
    }
    return (
        <>
            <div className="col-12" style={{ overflow: "auto" }}>
                <div class="card border-radius-12px pt-0 pt-2 px-0 font-medium mb-2 ">
                    <div className="flex flex-wrap">
                        {b2b && today && today.length ?<div className="lg:col-3 p-0 border-right-1  border-300 flex-grow-1 text-2xl  align-items-center">
                            <p className="m-0 text-center text-lg" style={{ color: "#5E6771" }}>{"Recived"}</p>
                            <p className={"text-5xl m-0 text-center font-bold color"} style={{ color: "blue" }}>{totalRec(today)}</p>
                        </div>:null}
                        {
                            today.length ?
                                [{ key: "cr_success", color: "#5AA786", value: "CR Success" }, { key: "cr_pending", color: "#184EA0", value: "CR Pending" }, { key: "cr_failure", color: "#E76F51", value: "CR Failure" }].map((item, i) => {
                                    return (
                                        <div key={'received' + i} className="lg:col-3 p-0 border-right-1  border-300 flex-grow-1 text-2xl  align-items-center">
                                            <p className="m-0 text-center text-lg" style={{ color: "#5E6771" }}>{item["value"]}</p>
                                            <p className={"text-5xl m-0 text-center font-bold color" + (i + 1)} style={{ color: item["color"] }}>{todayCrStatusSum(today, item["key"])}</p>
                                        </div>
                                    )
                                })
                                : todayInfoMsg ?
                                    <div className="my-info-message col-12">{todayInfoMsg}</div> : null
                        }
                    </div>
                </div>
                <div className="col-12 border-radius-12px pt-0 pt-2 px-0 font-medium mb-2">
                    <div className="card border-radius-12px">
                        <HighchartsReact highcharts={Highcharts} options={crLineChart} />
                    </div>
                </div>
                <div className="mt-3">
                    <h4 className="mb-1">Content Request</h4>
                </div>
                <TabView activeIndex={activeIndex} onTabChange={onTabChange}>
                    {/* <TabPanel header="For The Period">
                    {lastPeriod ?
                        <>
                            <div className="displayCard bg-white mb-2">
                                {"From:  " + lastPeriod.from + " To : " + lastPeriod.to}
                            </div>

                        </> : null}
                    {forPeriod.length ? TableView(forPeriod) : null}
                </TabPanel> */}
                    <TabPanel header="For The Day">
                        {
                            today.length ? TableView(today) : todayInfoMsg ?
                                <div className="my-info-message">{todayInfoMsg}</div> : null
                        }
                    </TabPanel>
                    <TabPanel header="Previous Day">
                        {
                            yesterday.length ? TableView(yesterday) : yesterdayInfoMsg ?
                                <div className="my-info-message">{yesterdayInfoMsg}</div> : null
                        }
                    </TabPanel>
                </TabView>
            </div>
            {!b2b ?<div class="card border-radius-12px pt-0 pt-2 px-0 font-medium mb-2">
                <p className="pl-3 mb-1 text-2xl font-bold">For Today</p>
                <p className="m-0 pl-3 text-xl font-semibold" style={{ color: "#74788D" }}>Received</p>
                <div className="flex flex-wrap">
                    {
                        today.length ?
                            today.map((t, i) => {
                                return (
                                    <div key={'received' + i} className="lg:col-4 p-0 border-right-1  border-300 flex-grow-1 text-2xl">
                                        <p className="m-0 text-center text-lg" style={{ color: "#5E6771" }}>{t.ecommerce}</p>
                                        <p className={"text-5xl m-0 text-center font-bold color" + (i + 1)}>{t.rec || 0}</p>
                                    </div>
                                )
                            })
                            : null
                    }
                </div>
                <p className="mt-2 m-0 pl-3 text-xl font-semibold" style={{ color: "#74788D" }}>Shipped</p>
                <div className="flex flex-wrap">
                    {
                        today.length ?
                            today.map((t, i) => {
                                return (
                                    <div key={'shipped' + i} className="lg:col-4 p-0 border-right-1  border-300 flex-grow-1 text-2xl">
                                        <p className="m-0 text-center text-lg" style={{ color: "#5E6771" }}>{t.ecommerce}</p>
                                        <p className={"text-5xl m-0 text-center font-bold color" + (i + 1)}>{(t.ship_pod + t.ship_psm) || 0}</p>
                                    </div>
                                )
                            })
                            : null
                    }
                </div>
            </div>:null}
            <div className="col-12 border-radius-12px pt-0 pt-2 px-0 font-medium mb-2">
                <div className="card border-radius-12px">
                    <HighchartsReact highcharts={Highcharts} options={lineChart} />
                </div>
            </div>
        </>
    )
}
export default B2COrders;