import React, { useEffect, useState } from "react"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { ColumnGroup } from "primereact/columngroup"
import { Row } from "primereact/row"
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { chartHeadingStyle, newChartColors } from "../../utilities/constant";
import { colorPalettes } from "../../lib/constants";
import apiService from '../../service/apiService'
import moment from "moment"
import { ecomm_channels, channels } from './ReproService'
import { dateFormatter } from "../../utilities/utillFunction";
import { plazaServices } from "../tollPlaza/plazaReportService"

const ListingContentIngestion = ({b2b=false}) => {
    const [ingestionStatus, setIngestionStatus] = useState([])
    const [reproCategory, setReproCategory] = useState([])
    const [ageingLineChart, setAgeingLineChart] = useState(null)
    const [listingTotal, setListingTotal] = useState([])
    const [agingErrorTotal, setAgingErrorTotal] = useState([])
    const [agingErrorData, setAgingErrorData] = useState([])
    const [channelAEData, setchannelAEData] = useState([])
    const [forToday, setForToday] = useState({})
    const [forTodayAvg, setForTodayAvg] = useState({})
    const [contentTrend, setContentTrend] = useState({})

    const todayDt = moment().format("YYYY-MM-DD")
    const serviceId = localStorage.getItem('serviceId')
    const time_range = [{
        title: '< 24 Hrs', key: "less_than_24hrs"
    }, { title: "24-48 Hrs", key: "24_to_48hrs" }, { title: "> 48 Hrs", key: "more_than_48hrs" }]
    useEffect(async () => {
        let last24_hr_con_list_query = {
            operation: "aggregate",
            aggregate: [
                { "$unwind": "$ingestionStatus" },
                { 
                    $match: { 
                        date: todayDt, 
                        "ingestionStatus.ingestionStatus": { $nin: ["Delete", "Unavailable"] } 
                    } 
                },
                { 
                    $group: {
                        _id: "$date",
                        created_at: { $first: "$created_at" }, 
                        hr: { $first: "$hr" },
                        reproCategory: { $first: "$reproCategory" }, // Collect into an array
                        ingestionStatus: { $push: "$ingestionStatus" },
                        totalReproCategoryCount: { $sum: "$reproCategory.count" },
                        totalIngestionStatusCount: { $sum: "$ingestionStatus.count" }
                    }
                },
                { 
                    $project: {
                        _id: 1,
                        created_at: 1,
                        hr: 1,
                        reproCategory: 1,
                        ingestionStatus: 1,
                        totalReproCategoryCount: 1,
                        totalIngestionStatusCount: 1
                    }
                }
            ]
        };
        

        let listing_query = {
            filter: { date: todayDt },
            projection: { _id: 0 }
        }

        let aging_error_query = {
            operation: "aggregate",
            aggregate: [
                { $match: { date: todayDt } },
                {
                    $project: {
                        error: {
                            $filter: {
                                input: "$error",
                                as: "error",
                                cond: { $and: [{ $ne: ["$$error.mspErrorCategory", ''] }, { $ne: ["$$error.mspErrorCategory", null] }] }
                            }
                        }
                    }
                }
            ]
        }

        let aging_error_total_query = getAgingDataQuery()
        let aging_error_today_total = {
            operation: "aggregate",
            aggregate: [
                { $unwind: "$error" },
                {
                    $match: {
                        "error.ErrorCategory": { $in: ["Listing-Content", "Listing Channel team","Listing Channel Team"] }
                    }
                },
                {
                    $group: {
                        _id: null,
                        less_than_24_hrs: { $sum: "$error.amazon_less_than_24hrs" },
                        "24_to_48_hrs": { $sum: "$error.amazon_24_to_48hrs" },
                        greater_than_48_hrs: { $sum: "$error.amazon_more_than_48hrs" }

                    }
                }
            ]
        }

        let channel_aging_q = {
            operation: "aggregate",
            aggregate: [
                { $match: { date: todayDt } },
                {
                    $project: {
                        error: {
                            $filter: {
                                input: "$error",
                                as: "error",
                                cond: { $in: ["$$error.ErrorCategory", ["Listing-Content", "Listing Channel team","Listing Channel Team"]] }
                            }
                        }
                    }
                }
            ]
        }
        const currentTime = moment();
        const startTime = currentTime.clone().subtract(24, 'hours');
        const endTime = currentTime.clone().subtract(1, 'hour');
        const startFormatted = startTime.startOf('hour').format('YYYY-MM-DD HH:mm:ss');
        const endFormatted = endTime.startOf('hour').format('YYYY-MM-DD HH:mm:ss');
        let avg_24hr_errors = {
            operation: "aggregate",
            aggregate: [
                {
                    $addFields: {
                        formattedDateTime: {
                            $concat: [
                                "$date",
                                " ",
                                {
                                    $cond: {
                                        if: { $lt: ["$hr", 10] },
                                        then: { $concat: ["0", { $toString: "$hr" }] },
                                        else: { $toString: "$hr" }
                                    }
                                },
                                ":00:00"
                            ]
                        }
                    }
                }
                ,
                { "$project": { 'formattedDateTime': 1, date: 1, hr: 1, error: 1 } },
                {
                    "$match": {
                        "formattedDateTime": {
                            "$gte": startFormatted, "$lte": endFormatted
                        }
                    }
                },

                {
                    "$unwind": "$error"
                },
                {
                    "$match": {
                        "error.ErrorCategory": {
                            "$in": [
                                "Listing-Content",
                                "Listing Channel team",
                                "Listing Channel Team"
                            ]
                        }
                    }
                },
                {
                    "$group": {
                        "_id": { date: '$date', hr: "$hr" },
                        "avg_less_than_24_hrs": {
                            "$sum": "$error.amazon_less_than_24hrs"
                        },
                        "avg_24_to_48_hrs": {
                            "$sum": "$error.amazon_24_to_48hrs"
                        },
                        "avg_more_than_48_hrs": {
                            "$sum": "$error.amazon_more_than_48hrs"
                        }
                    }
                },
                {
                    "$group": {
                        "_id": null,
                        "avg_less_than_24_hrs": {
                            "$avg": "$avg_less_than_24_hrs"
                        },
                        "avg_24_to_48_hrs": {
                            "$avg": "$avg_24_to_48_hrs"
                        },
                        "avg_more_than_48_hrs": {
                            "$avg": "$avg_more_than_48_hrs"
                        }
                    }
                }
            ]
        };
        Promise.all([
            apiService.httpDBService(last24_hr_con_list_query, serviceId, "nq-dashboard-b2c-ContentIngestion_hourly_24hr-meta"),
            apiService.httpDBService(listing_query, serviceId, "nq-dashboard-b2c-Listing_hourly_24hr-meta"),
            apiService.httpDBService(aging_error_query, serviceId, "nq-dashboard-b2c-Aging_ErrorBucket_hourly_24h-meta"),
            apiService.httpDBService(aging_error_total_query, serviceId, "nq-dashboard-b2c-Age_Channel_ErrBkt_hourly_24h-data"),
            apiService.httpDBService(channel_aging_q, serviceId, "nq-dashboard-b2c-Age_Channel_ErrBkt_hourly_24h-meta"),
            apiService.httpDBService(aging_error_today_total, serviceId, "nq-dashboard-b2c-Age_Channel_ErrBkt_hourly_24h-meta"),
            apiService.httpDBService(avg_24hr_errors, serviceId, "nq-dashboard-b2c-Age_Channel_ErrBkt_hourly_24h-data"),
        ])
            .then((res) => {
                let ing_repro_category = res[0]
                if (ing_repro_category && ing_repro_category.length) {
                    ing_repro_category = ing_repro_category[0]
                    if (ing_repro_category.ingestionStatus) {
                        ing_repro_category.ingestionStatus.push({ ingestionStatus: "Total", count: ing_repro_category.totalIngestionStatusCount })
                        setIngestionStatus(ing_repro_category.ingestionStatus)
                    }
                    if (ing_repro_category.reproCategory) {
                        ing_repro_category.reproCategory.push({ reproCategory: "Total", count: ing_repro_category.totalReproCategoryCount })
                        setReproCategory(ing_repro_category.reproCategory)
                    }
                }

                if (res[1] && res[1].length) {
                    const channelWiseMapData = {}

                    res[1].forEach((item) => {
                        delete item.date
                        delete item.created_at
                        delete item.hr
                        if (item.Amazon_listing_succ) item['Amazon_success_%'] = item.Amazon_listing_succ ? ((item.Amazon_listing_succ / item.Amazon_eligible_succ) * 100).toFixed(2) : 0
                        if (item.Bookscape_listing_succ) item['Bookscape_success_%'] = item.Bookscape_listing_succ ? ((item.Bookscape_listing_succ / item.Bookscape_eligible_succ) * 100).toFixed(2) : 0
                        if (item.FlipkartLSP_listing_succ) item['FlipkartLSP_success_%'] = item.FlipkartLSP_listing_succ ? ((item.FlipkartLSP_listing_succ / item.FlipkartLSP_eligible_succ) * 100).toFixed(2) : 0
                        if (item.FlipkartHSP_listing_succ) item['FlipkartHSP_success_%'] = item.FlipkartHSP_listing_succ ? ((item.FlipkartHSP_listing_succ / item.FlipkartHSP_eligible_succ) * 100).toFixed(2) : 0

                        let keys = Object.keys(item)
                        keys.forEach(key => {
                            let fidx = channels.findIndex((ch) => key.toLocaleLowerCase().includes(ch.toLowerCase()))
                            if (fidx > -1) {
                                let channel = channels[fidx]
                                let newkey = key.toLowerCase().replace((channel.toLowerCase() + "_"), "")
                                let mapKey = channel
                                if (mapKey in channelWiseMapData) channelWiseMapData[mapKey][newkey] = item[key]
                                else channelWiseMapData[mapKey] = { [newkey]: item[key], "channel": channel }
                            }
                        })
                    })
                    const listingTotalData = Object.values(channelWiseMapData)
                    setListingTotal(listingTotalData)

                    if (res[2] && res[2].length) {
                        setAgingErrorTotal(res[2][0].error)
                    }

                    if (res[3] && res[3].length) {
                        getlineChart(res[3])
                        setAgingErrorData(res[3])
                    }

                    if (res[4] && res[4].length) {
                        setchannelAEData(res[4][0].error)
                    }
                    if (res[5] && res[5].length) {
                        let todayData = res[5][0]
                        delete todayData._id
                        todayData['< 24 Hrs'] = todayData.less_than_24_hrs
                        delete todayData.less_than_24_hrs
                        todayData[' 24 - 48 Hrs'] = todayData['24_to_48_hrs']
                        delete todayData['24_to_48_hrs']
                        todayData['> 48 Hrs'] = todayData.greater_than_48_hrs
                        delete todayData.greater_than_48_hrs
                        setForToday(todayData)
                    }
                    if (res[6] && res[6].length) {
                        let todayAvgData = res[6][0]
                        delete todayAvgData._id
                        todayAvgData['< 24 Hrs'] = todayAvgData.avg_less_than_24_hrs
                        delete todayAvgData.avg_less_than_24_hrs
                        todayAvgData[' 24 - 48 Hrs'] = todayAvgData['avg_24_to_48_hrs']
                        delete todayAvgData['avg_24_to_48_hrs']
                        todayAvgData['> 48 Hrs'] = todayAvgData.avg_more_than_48_hrs
                        delete todayAvgData.avg_greater_than_48_hrs
                        setForTodayAvg(todayAvgData)
                    }
                }

            })
            .catch(e => {
                console.error(e)
            })
    }, [])

    useEffect(() => {
        const formPayload = (last48) => {
            let matchObj = {
                $match: {
                    "date": moment().subtract(1, 'days').format('YYYY-MM-DD'),  // Yesterday's date
                    "hr": moment().hour()  // Current hour
                }
            }
            let generalPayload = {
                operation: "aggregate",
                aggregate: [

                    { "$unwind": "$ingestionStatus" },
                    {
                        $match:{
                            "ingestionStatus.ingestionStatus":{$nin:["Delete","Unavailable"]}
                        }
                    }, 
                    {
                        "$group": {
                            "_id": {date:"$date",hr:"$hr"}, // Group by ingestion status type
                            "totalCount": { "$sum": "$ingestionStatus.count" } // Sum counts for each status
                        }
                    },
                    // {
                    //     "$project": {
                    //         "ingestionStatus": "$_id",
                    //         "totalCount": 1,
                    //         "_id": 0
                    //     }
                    // }
                ]
            };
            if (last48) generalPayload["aggregate"] = [matchObj, ...generalPayload["aggregate"]]
            return generalPayload;
        }
        const formAvgPayload = (currentTime, start, end) => {
            const startTime = currentTime.clone().subtract(start, 'hours');
            const endTime = currentTime.clone().subtract(end, 'hour');
            const startFormatted = startTime.startOf('hour').format('YYYY-MM-DD HH:mm:ss');
            const endFormatted = endTime.startOf('hour').format('YYYY-MM-DD HH:mm:ss');
            let avg_24hr_ingestion = {
                operation: "aggregate",
                aggregate: [
                    {
                        $addFields: {
                            formattedDateTime: {
                                $concat: [
                                    "$date",
                                    " ",
                                    {
                                        $cond: {
                                            if: { $lt: ["$hr", 10] },
                                            then: { $concat: ["0", { $toString: "$hr" }] },
                                            else: { $toString: "$hr" }
                                        }
                                    },
                                    ":00:00"
                                ]
                            }
                        }
                    }
                    ,
                    { "$project": { 'formattedDateTime': 1, date: 1, hr: 1, ingestionStatus: 1 } },
                    {
                        "$match": {
                            "formattedDateTime": {
                                "$gte": startFormatted, "$lte": endFormatted
                            }
                        }
                    },

                    {
                        "$unwind": "$ingestionStatus"
                    },
                    {
                        $match:{
                            "ingestionStatus.ingestionStatus":{$nin:["Delete","Unavailable"]}
                        }
                    },
                    {
                        "$group": {
                            "_id": { date: '$date', hr: "$hr" },
                            "sumCount": { "$sum": "$ingestionStatus.count" }
                        }
                    },
                    {
                        "$group": {
                            "_id": null,
                            "avg_cont": {
                                "$avg": "$sumCount"
                            },
                        }
                    }
                ]
            };
            return avg_24hr_ingestion;
        }
        let more48Hours = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        date: {
                            $gte: moment().subtract(32, 'days').format('YYYY-MM-DD'),
                            $lte: moment().subtract(2, 'days').format('YYYY-MM-DD')
                        },
                        hr: 23
                    }
                },
                { "$project": { 'formattedDateTime': 1, date: 1, hr: 1, ingestionStatus: 1 } },
                {
                    "$unwind": "$ingestionStatus"
                },
                {
                    $match:{
                        "ingestionStatus.ingestionStatus":{$nin:["Delete","Unavailable"]}
                    }
                },
                {
                    "$group": {
                        "_id": null, // Group by ingestion status type
                        "totalCount": { "$sum": "$ingestionStatus.count" } // Sum counts for each status
                    }
                },
                {
                    "$project": {
                        "ingestionStatus": "$_id",
                        "totalCount": 1,
                        "_id": 0
                    }
                }
            ]
        };
        let last24 = plazaServices.general(serviceId, formPayload(false), "nq-dashboard-b2c-ContentIngestion_hourly_24hr-meta")
        let hour24_48hour = plazaServices.general(serviceId, formPayload(true), "nq-dashboard-b2c-ContentIngestion_hourly_24hr-data")
        let last24Avg = plazaServices.general(serviceId, formAvgPayload(moment(), 24, 1), "nq-dashboard-b2c-ContentIngestion_hourly_24hr-data")
        let hour24_48hourAvg = plazaServices.general(serviceId, formAvgPayload(moment(), 48, 25), "nq-dashboard-b2c-ContentIngestion_hourly_24hr-data")
        let more48HoursPrr = plazaServices.general(serviceId, more48Hours, "nq-dashboard-b2c-ContentIngestion_hourly_24hr-data")
        Promise.all([last24, hour24_48hour, last24Avg, hour24_48hourAvg, more48HoursPrr]).then((res) => {
            let data = {
                "last24": {
                    keyText:"< 24 Hrs",
                    data: 0,
                    change: 0
                },
                "hour24_48hour": {
                    keyText:"24-48 Hrs",
                    data: 0,
                    change: 0
                },
                "more48Hours": {
                    keyText:">48 Hrs",
                    data: 0,
                    change: null
                }

            }
            if (res && res.length) {
                if (res[0] && res[2]) {
                    let prev = res[2] && res[2].length ? res[2][0]["avg_cont"] : 0
                    data["last24"].data = res[0] && res[0].length ? res[0][0]["totalCount"] : 0
                    data["last24"].change = prev ? ((data["last24"].data - prev) / prev) * 100 : ""
                }
                if (res[1] && res[3]) {
                    let prev = res[3] && res[3].length ? res[3][0]["avg_cont"] : 0
                    data["hour24_48hour"].data = res[1] && res[1].length ? res[1][0]["totalCount"] : 0
                    data["hour24_48hour"].change = prev ? ((data["hour24_48hour"].data - prev) / prev) * 100 : ""
                }
                if (res[4]) {
                    data["more48Hours"].data = res[4] && res[4].length ? res[4][0]["totalCount"] : 0
                }
            }
            setContentTrend([data["last24"], data["hour24_48hour"], data["more48Hours"]])
        })
    }, []);

    useEffect(()=>{
        Promise.all([plazaServices.general(serviceId,{
            filter:{
                date:{$gte:moment().subtract(3, 'days').format('YYYY-MM-DD')}},
                "sort":[["date",1]]
            }
                ,"nq-dashboard-b2c-ContentIngestion_hourly_24hr-data")]).then((res)=>{
        })
    })


    const getAgingDataQuery = () => {
        let nofdays = 5
        let startdt = moment().subtract(nofdays, "days").format("YYYY-MM-DD")
        let aging_error_total_query = {
            operation: "aggregate",
            aggregate: [
                { $match: { date: { "$gte": startdt, "$lte": todayDt }, hr: { "$in": [0, 6, 12, 18] } } },
                { $unwind: "$error" },
                {
                    $match: {
                        "error.ErrorCategory": { $in: ["Listing-Content", "Listing Channel Team","Listing Channel team"] }
                    }
                },
                {
                    $group: {
                        _id: { date: "$date", hr: "$hr" },
                        less_than_24_hrs: { $sum: "$error.amazon_less_than_24hrs" },
                        "24_to_48_hrs": { $sum: "$error.amazon_24_to_48hrs" },
                        greater_than_48_hrs: { $sum: "$error.amazon_more_than_48hrs" }
                    }
                }
            ]
        }
        return aging_error_total_query
    }
    const tileCountTable = (data, firstColKey, firstColumnNm, paginate) => {
        return (
            <DataTable value={data} style={{ marginTop: '0' }} paginator={paginate} rows={4} className='mt-1 inter-font' paginatorTemplate="PrevPageLink NextPageLink">
                <Column
                    className="text-center"
                    headerClassName='text-blue-700 font-bold'
                    header={firstColumnNm}
                    bodyClassName='text-style'
                    body={(e) => { return <span className='p-2'>{e[firstColKey]}</span> }}>
                </Column>
                <Column
                    className="text-center"
                    style={{ color: "#5E6771" }}
                    headerClassName=" font-bold text-blue-700"
                    header="Count of Tiles"
                    body={(e) => (
                        <span style={{ color: colorPalettes.color2 }}>
                            {e.count}
                        </span>
                    )}
                />

            </DataTable>
        )
    }

    const TableView = (data) => {
        return (
            <div>
                <DataTable value={data} responsiveLayout="scroll" className="inter-font" rowGroupMode="rowspan" showGridlines headerColumnGroup={customHeader}>
                    <Column headerClassName="blue-bg" className="text-center" field='channel'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='eligible_succ'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='eligible_fail'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='listing_succ'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='listing_fail'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='success_%'></Column>
                </DataTable>
            </div>
        )
    }

    const customHeader = (
        <ColumnGroup>
            <Row >
                <Column headerClassName="blue-bg" header="" colSpan={1} className="text-center"></Column>
                <Column headerClassName="blue-bg" header="Eligibilty" colSpan={2} className="text-center"></Column>
                <Column headerClassName="blue-bg" header="Listing" colSpan={2} className="text-center"></Column>
                <Column headerClassName="blue-bg" header="" className="text-center"></Column>
            </Row>
            <Row>
                <Column headerClassName="blue-bg" header="" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Success" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Failure" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Success" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Failure" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Success % " className="text-center" ></Column>
            </Row>
        </ColumnGroup>
    )

    const getlineChart = (data) => {
        let lessThan24 = []
        let between24_48 = []
        let greaterThan48 = []
        let dates = []
        data.forEach(d => {
            d.date_time = d._id.date + " " + d._id.hr + ":00:00"
            d.dt = moment(d.date_time)
        })
        data.sort((a, b) => a.dt - b.dt).forEach(r => {
            let dt = r.date_time
            dt = dateFormatter(dt, 'DD MMM hh A', 'YYYY-MM-DD HH:mm:ss')

            lessThan24.push(r.less_than_24_hrs)
            between24_48.push(r['24_to_48_hrs'])
            greaterThan48.push(r.greater_than_48_hrs)
            dates.push(dt)
        })
        let lineChartData = {
            chart: {
                type: "spline",
            },
            title: {
                text: "6 Hourly Listing Error Aging Trend",
                style: chartHeadingStyle,
            },
            xAxis: {
                categories: dates,
            },
            yAxis: {
                title: {
                    text: "Error Count",
                },
                labels: {
                    formatter: function () {
                        return this.value + "";
                    },
                },
            },
            tooltip: {
                crosshairs: true,
                shared: true,
            },
            plotOptions: {
                spline: {
                    dataLabels: {
                        enabled: true
                    },
                },
            },
            series:
                [
                    {
                        name: "< 24 hrs",
                        data: lessThan24,
                        color: colorPalettes.color1
                    },
                    {
                        name: "24 - 48 hrs",
                        data: between24_48,
                        color: colorPalettes.color2
                    },
                    {
                        name: "> 48 hrs",
                        data: greaterThan48,
                        color: colorPalettes.color3
                    }]
        }
        setAgeingLineChart(lineChartData)
    }

    const agingCustomHeader = (
        <ColumnGroup>
            <Row >
                <Column headerClassName="blue-bg" header="" colSpan={1} className="text-center"></Column>
                <Column headerClassName="blue-bg" header="Ageing" colSpan={3} className="text-center"></Column>
            </Row>
            <Row>
                <Column headerClassName="blue-bg" header="Error Buckets" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header=" < 24 Hrs" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header=" 24 - 48 Hrs " className="text-center" ></Column>
                <Column headerClassName="blue-bg" header=" >  48 Hrs " className="text-center" ></Column>
            </Row>
        </ColumnGroup>
    )

    const agingErrorTable = (data) => {
        return (
            <DataTable value={data} responsiveLayout="scroll" className="inter-font" rowGroupMode="rowspan" showGridlines headerColumnGroup={agingCustomHeader}>
                <Column headerClassName="blue-bg" className="text-center" field='mspErrorCategory'></Column>
                <Column headerClassName="blue-bg" className="text-center" field='less_than_24_hrs'></Column>
                <Column headerClassName="blue-bg" className="text-center" field='24_to_48_hrs'></Column>
                <Column headerClassName="blue-bg" className="text-center" field='greater_than_48_hrs'></Column>
            </DataTable>
        )
    }

    const channelAgingCustomHeader = (
        <ColumnGroup>
            <Row >
                <Column headerClassName="blue-bg" header="" colSpan={1} className="text-center"></Column>
                {
                    time_range.map(tr => {
                        return (<Column headerClassName="blue-bg" header={tr.title} colSpan={ecomm_channels.length} className="text-center"></Column>)
                    })
                }
            </Row>
            <Row>
                <Column headerClassName="blue-bg" header="Channel Error Buckets" className="text-center" ></Column>
                {
                    time_range.map(tr => {
                        return ecomm_channels.map(c => {
                            return (
                                <Column headerClassName="blue-bg" header={c} className="text-center" ></Column>
                            )
                        })
                    })
                }
            </Row>
        </ColumnGroup>
    )

    const channelAgingErrorTable = (data) => {
        return (
            <DataTable value={data} responsiveLayout="scroll" className="inter-font" rowGroupMode="rowspan" showGridlines headerColumnGroup={channelAgingCustomHeader}>
                <Column headerClassName="blue-bg" className="text-center" field='ErrorCategory'></Column>
                {
                    time_range.map(tr => {
                        return ecomm_channels.map(c => {
                            return (
                                <Column headerClassName="blue-bg" sortField={c.toLowerCase() + "_" + tr.key} sortOrder={c === "Amazon" ? -1 : 1} field={c.toLowerCase() + "_" + tr.key} className="text-center" ></Column>
                            )
                        })
                    })
                }
            </DataTable>
        )
    }

    return (
        <>
            <div className="col-12" style={{ overflow: "auto" }}>
                <div className="card mb-2 border-radius-12px">
                    <h4 className="text-2xl m-0 mb-1 font-bold">Content Ingestion</h4>
                    <div className="flex flex-wrap">
                        {
                            contentTrend.length ?
                                contentTrend.map((item, i) => {
                                    return (
                                        <div key={i} className={"lg:col-4 p-0 border-300 flex-grow-1 text-2xl " + (i != 2 ? "border-right-1" : "")}>
                                            <p className="m-0 text-center text-lg font-semibold" style={{ color: "#5E6771" }}>{item["keyText"]}</p>
                                            <p className={"text-5xl m-0 text-center font-bold color" + (i + 1)}>{item["data"] || 0}</p>
                                            <p className={"text-2xl m-0 text-center font-bold color" + (i + 1)}>{item["change"] && <p style={{ color: item["change"] < 0 ? "red" : "green" }}>
                                                {item["change"].toFixed
                                                    (2)}%
                                                {item.change > 0 ? <i className="pi pi-arrow-up"></i> : <i className="pi pi-arrow-down"></i>}</p>}</p>
                                        </div>
                                    )
                                })
                                : null
                        }
                    </div>
                    <h4 className="text-2xl m-0 mb-1 font-bold">Listing Errors</h4>
                    <div className="flex flex-wrap">
                        {
                            contentTrend ?
                                Object.keys(forToday).map((t, i) => {
                                    return (
                                        <div key={i} className={"lg:col-4 p-0 border-300 flex-grow-1 text-2xl " + (i != 2 ? "border-right-1" : "")}>
                                            <p className="m-0 text-center text-lg font-semibold" style={{ color: "#5E6771" }}>{t}</p>
                                            <p className={"text-5xl m-0 text-center font-bold color" + (i + 1)}>{forToday[t] || 0}</p>
                                            <p className={"text-2xl m-0 text-center font-bold color" + (i + 1)}>{forTodayAvg[t] && <p style={{ color: forToday[t] - forTodayAvg[t] > 0 ? "red" : "green" }}>{(((forToday[t] - forTodayAvg[t]) / forTodayAvg[t]) * 100).toFixed(2) || 0}%{(((forToday[t] - forTodayAvg[t]) / forTodayAvg[t]) * 100) > 0 ? <i className="pi pi-arrow-up"></i> : <i className="pi pi-arrow-down"></i>}</p>}</p>
                                        </div>
                                    )
                                })
                                : null
                        }
                    </div>
                </div>
            </div>
            <div className="col-12">
                {
                    ageingLineChart ?
                        <div className="card">
                            <HighchartsReact highcharts={Highcharts} options={ageingLineChart} />
                        </div> : null
                }
            </div>
            <div className="col-12">
                <div className="card">
                    <span className="font-bold text-xl">Eligibilty Failures</span>
                    {agingErrorTotal.length ? agingErrorTable(agingErrorTotal) : null}
                </div>
            </div>

            <div className="col-12">
                <div className="card">
                    <span className="font-bold text-xl">Listing Failures</span>
                    {channelAEData.length ? channelAgingErrorTable(channelAEData) : null}
                </div>
            </div>

            <div className="col-12">
                <div className="grid card m-0">
                    <div className="col-12" >
                        <span className="font-bold text-xl ">Content Ingestion - Last 24 Hrs</span>
                    </div>
                    {
                        ingestionStatus && ingestionStatus.length ?
                            <div className="col-6"  >
                                {tileCountTable(ingestionStatus, 'ingestionStatus', 'Status', false)}
                            </div>
                            : null
                    }
                    <div className="mx-4" style={{ borderRight: "1px solid #e4e4e4" }}></div>
                    {
                        reproCategory && reproCategory.length ?
                            <div className="col-5">
                                {tileCountTable(reproCategory, 'reproCategory', "Repro Category", true)}
                            </div>
                            : null
                    }
                </div>
            </div>
            <div className="col-12">
                <div className="card">
                    <span className="font-bold text-xl">Listing - Last 24 Hrs</span>
                    {listingTotal.length ? TableView(listingTotal) :
                        <div className="my-info-message border-radius-12px mt-1">{"Data not available"}</div>
                    }
                </div>
            </div>
        </>
    )
}

export default ListingContentIngestion