import { changeUnit, statesList,setThingName } from "../Actions/index";
import service from "../service/dbService";
import moment from "moment";
const serviceDetails = JSON.parse(localStorage.getItem("service"))
export function getMax(arr, key) {
    const values = arr.map((object) => {
        return object[key];
    });

    return Math.max(...values);
}

export function getMin(arr, key) {
    const values = arr.map((object) => {
        return object[key];
    });

    return Math.min(...values);
}

export function minToHrMinSec(n) {
    let num = n;
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    let hDisplay = rhours > 9 ? rhours + ":" : "0" + rhours + ":";
    let mDisplay = rminutes > 9 ? rminutes + ":" : "0" + rminutes + ":";
    let sDisplay = Math.floor((n * 60) % 60) > 9 ? Math.floor((n * 60) % 60) : "0" + Math.floor((n * 60) % 60);

    return hDisplay + mDisplay + sDisplay;
}

export function minToHrMin(n) {
    let num = n;
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return rhours + "hrs " + rminutes + "min";
}

export function secondsToHms(sec) {
    if (!Number(sec)) {
        return 0;
    }
    sec = Number(sec);
    let h = Math.floor(sec / 3600);
    let m = Math.floor((sec % 3600) / 60);
    let s = Math.floor((sec % 3600) % 60);

    let hDisplay = h > 9 ? h + ":" : "0" + h + ":";
    let mDisplay = m > 9 ? m + ":" : "0" + m + ":";
    let sDisplay = s > 9 ? s : "0" + s;

    return hDisplay + mDisplay + sDisplay;
}
export function secondsToHm(sec) {
    if (!Number(sec)) {
        return 0;
    }
    sec = Number(sec);
    let h = Math.floor(sec / 3600);
    let m = Math.floor((sec % 3600) / 60);
    // let s = Math.floor(sec % 3600 % 60);

    let hDisplay = h > 9 ? h + " hr " : "0" + h + " hr ";
    let mDisplay = m > 9 ? m + " min " : "0" + m + " min ";
    // let sDisplay = s > 9 ? s : "0" + s

    return hDisplay + mDisplay;
}

export function checkIfInSameMinute(ts1, ts2) {
    ts1 = new Date(ts1);
    ts2 = new Date(ts2);

    return ts1.getMinutes() === ts2.getMinutes();
}

export function removeSecStr(str) {
    let arr = str.split(" ");
    arr.pop();
    str = arr.join(" ");
    return str;
}

export function parseResponse(promise) {
    return promise
        .then((data) => {
            return [null, data];
        })
        .catch((err) => [err]);
}

export const dispatchAction = (type, data) => {
    return (dispatched) => dispatched({ type, data });
};

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function isPasswordValid(password) {
    let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    return reg.test(password);
}

export const convertCamelCase = (str) => {
    if (str === str.toUpperCase()) return str;
    else if (str.includes("/")) return str;
    let res = str.replace(/[A-Z]/g, " $&").trim();
    let output = res.charAt(0).toUpperCase() + res.slice(1);
    return output;
};

export const removeDomainName = (url) => {
    const domain = new URL(url).origin;
    return url.replace(domain, "");
};

export const SetUserServiceDataInLocalStorage = async(userData, dispatch) =>{
    localStorage.setItem("service", JSON.stringify(userData.service));
    localStorage.setItem("region", userData.service.region);

    localStorage.setItem("states", JSON.stringify(userData.states ? userData.states : []));
    localStorage.setItem("cities", JSON.stringify(userData.cities ? userData.cities : []));
    localStorage.setItem("zipcodes", JSON.stringify(userData.zipcodes ? userData.zipcodes : []));
    localStorage.setItem("permissions", JSON.stringify(userData.permissions));
    localStorage.setItem("uid", userData._id);
    localStorage.setItem("profileImg", userData.image);
    localStorage.setItem("serviceId", userData.serviceId);
    localStorage.setItem("email",(userData.user.mobile? userData.user.mobile: userData.user.email || "" ));
    localStorage.setItem("emailId", (userData && userData.user ? userData.user.email : ""));

    dispatch(statesList(userData.states));
        if( JSON.parse(localStorage.getItem("states")).length>0 || JSON.parse(localStorage.getItem("cities")).length>0 || JSON.parse(localStorage.getItem("zipcodes")).length>0){
                let res = await service.getThingNames(localStorage.getItem("serviceId"));
                let serviceBlock = JSON.parse(localStorage.getItem("service")).serviceBlock;
                let thingName=res.map((item)=>{
                    return item[serviceBlock+"Name"];
                })
               let temp=[...res,thingName];
           await dispatch(setThingName(temp)) 
        }
        else{
            dispatch(setThingName([]))
    
        }
    if(userData.service?.location?.operatingCountry === "canada"){
        dispatch(changeUnit("MKS"));
    }
    else if (userData.service.region.toLowerCase().includes("us")) {
        dispatch(changeUnit("FPS"));
    } 
    else {
        dispatch(changeUnit("MKS"));
    }
}

export function getPaths(obj, parentKey = '') {
    let paths = {};
    
    for (let key in obj) {
        let newKey;
        if(isNaN(key)) newKey= "['" + key  + "']"
        else newKey = "[" + key  + "]"
        
        let fullPath = parentKey ?  parentKey + newKey : newKey
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            let nestedPaths = getPaths(obj[key], fullPath);
            paths = { ...paths, ...nestedPaths };
        } else {
            paths[obj[key]] = fullPath;
        }
    }
    return paths;
}
//takes date, to format to string, if date is string then its format
export const dateFormatter = (date, toFormat, dateStringFormat) => {
    if(date){
        let format = toFormat ? toFormat : dateFormat()
        if (!dateStringFormat) {
            return moment.utc(Number(date)).local().format(format) 
        }
        else { return moment(date, dateStringFormat).local().format(format) }
    }
}

const dateFormat = () => {
    if (serviceDetails && serviceDetails.region && (serviceDetails.region === "us-east-1" || serviceDetails.region === "us" || serviceDetails.region === "US")) {
        return "MMM DD, HH:mm"
    }
    else return "Do MMM, HH:mm"
}

export const userRoleMap = (service, role) =>{
    return service.userAccessKeysDescription ? service.userAccessKeysDescription[role] : role
}

export function formatToTwoDigits(num) {
    return num.toString().padStart(2, '0');
}