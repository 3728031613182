import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { commonConfigCaller } from './FeatureDetails';
import PurchaseInvoice from './PurchaseInvoice';
import { plazaServices } from './tollPlaza/plazaReportService';
import PaymentOptionsScreen from './PaymentOptionsScreen';

export const sortByPropValue = (arr, prop) => {
    return arr.sort((a, b) => {
        if (!a[prop] && b[prop]) return 1;
        if (a[prop] && !b[prop]) return -1;
        return 0;
    });
};

const PlanPurchase = ({planPath="getRetainerPlans",designerPlan=false}) => {
    const [planDetails, setPlanDetails] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [admin,setAdmin] = useState(false)
    const serviceId = localStorage.getItem("serviceId");

    useEffect(async() => {
        let reqObj =  { "enabled": true }
        let userType ;
        let res;
        let arr = JSON.parse(localStorage.getItem("permissions"));
        if (arr.includes("UserManagement.READ") && arr.includes("UserManagement.WRITE")) setAdmin(true)
        if(designerPlan)res = await plazaServices.general("nextqore",{filter:{"user.email":localStorage.getItem("emailId"),"serviceId" : serviceId},projection:{user:1}},"nq-userpermissions")
        if(designerPlan && !admin)reqObj["planUser"]= res && res.length && res[0] && res[0]["user"] &&res[0]["user"]["userType"]
        commonConfigCaller(planPath, "post", { serviceId, "filter": reqObj })
            .then((res) => {
                let arr = res?.data?.responseData || [];
                arr = sortByPropValue(arr, "charge").reverse();
                setPlanDetails(arr);
            })
            .catch((err) => console.error(err));
    }, []);

    const GetPlanDetails = ({ plan }) => {
        let arr = [];
        if (Array.isArray(plan.booleanBenefits)) arr = [...plan.booleanBenefits];
        if (Array.isArray(plan.quantifiableBenefits)) arr = [...arr, ...plan.quantifiableBenefits];
        arr = sortByPropValue(arr, "value");
        return (
            <div className="col-12 grid m-0" id="imgTab">
                <div className="col-12 grid justify-content-between purchasePlanSubHeading m-0 small-font">
                    <p className="m-0">Features</p>
                    <p className="m-0">{designerPlan?"Duration":"Availability"}</p>
                </div>
                {arr.map((item, index) => (
                    <div key={index} className="grid col-12 mt-1 justify-content-between m-0 small-font">
                        <p className="col-8 m-0 p-0 purchaseplandetails">
                            {designerPlan?`Full Acess of All Features`:<> {item.name}
                            {typeof (item.value) === "boolean" ? null : ` : ${item.value}`}
                            {item.supports ? `:${item.supports}` : null}</>}
                        </p>
                        {designerPlan?<p className='m-0 p-0 purchaseplandetails' style={{fontSize:"12px"}}>{item.value +" Months"}</p>:<i className={item.value ? "pi pi-check m-0 p-0" : `pi pi-lock m-0 p-0`} style={{ color: "#9AAB89", fontSize: "0.8rem" }}></i>}
                    </div>
                ))}
                {plan.charge ? <Button
                    className="addFeatureButton addFeatureButtonFont lg:col-2 col-12"
                    label={plan.charge ? `${plan.planNm} for $${plan.charge} /Project` : `Continue With ${plan.planNm}`}
                    style={{ color: `${plan.charge ? "white" : "#47422F"}`, background: `${plan.charge ? "#47422F" : "white"}`, padding: "12px 0px" }}
                    onClick={() => { setSelectedPlan(plan) }}
                /> : <p className="col-12 inline-flex justify-content-center m-0 p-0 purchasePlanSubHeading mt-1" style={{ fontSize: "1rem" }}>
                    You have used your {plan.planNm} plan
                </p>}
            </div>
        );
    };

    // Inline styles for Custom Tab Panel
    const tabPanelStyles = {
        tabviewNavContainer: {
            padding: '0%',
            borderRadius: '63px',
            border: '2px solid #BFAD5B',
            background: '#BFAD5B',
        },
        tabviewNavLinkHighlight: {
            borderRadius: '20px',
            border: '2px solid #BFAD5B',
            background: '#BFAD5B',
            color: '#47422F',
            fontFamily: 'Larken',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '20px',
            letterSpacing: '1.44px',
            textTransform: 'uppercase',
        },
        tabviewTitle: {
            color: '#47422F',
            fontFamily: 'Larken',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '20px',
            letterSpacing: '1.44px',
            textTransform: 'uppercase',
        }
    };

    return (
        <>
            {!selectedPlan ? (
                <div>
                    <h3 className="col-12 planPurchaseHeading mt-0">Select Plans</h3>
                    <div className="imgplanBuy mt-2 custom_tab" >
                        <TabView style={tabPanelStyles.tabviewNavContainer}>
                            {planDetails &&
                                Array.isArray(planDetails) &&
                                planDetails.map((plan, index) => (
                                    <TabPanel header={plan.planNm || "Unnamed Plan"} key={plan.planId} className='custom_tab'>
                                        <GetPlanDetails plan={plan} />
                                    </TabPanel>
                                ))}
                        </TabView>
                    </div>
                </div>
            ) : (
                <PaymentOptionsScreen plan={selectedPlan} comeBackFunc={setSelectedPlan} designerPlan={designerPlan}/>
            )}
        </>
    );
};

export default PlanPurchase;

