import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import service from '../../service/apiService'
import { Dropdown } from 'primereact/dropdown'
import ProjectDetail from './ProjectDetail'
import { Button } from 'primereact/button'
import { httpConstants } from '../../lib/constants'

const accept = ".jpg, .jpeg, .png"
const ddStyle = {
    borderRadius: '20px',
    border: '2px solid #9AAB89',
    width: "100%",
    backgroundColor: "#FBF9F3",
    color: "#1D1B1C",
    fontFamily: "Gerstner-ProgramRegular"
}
const borderBottom = { borderBottom: "1px solid #9AAB89" }

const DesignerProfile = ({ user = "", back }) => {
    const serviceId = localStorage.getItem("serviceId")
    const email = !user ? localStorage.getItem("emailId") : user
    const isAdmin = useMemo(() => ["UserManagement.WRITE", "UserManagement.READ"].every((p) => JSON.parse(localStorage.getItem("permissions").includes(p))), [])
    const [designer, setDesigner] = useState({})
    const [selectedCustomer, setSelectedCustomer] = useState("")
    const [selectedProject, setSelectedProject] = useState("")
    const [designerProjects, setDesignerProjects] = useState([])
    const [designerProjectsCopy, setDesignerProjectsCopy] = useState([])
    const [showProjectDetails, setShowProjectDetails] = useState(null)
    const activeAndTotalProj = useRef({})
    const customersList = useRef([])
    const projectsList = useRef({})

    useEffect(() => {
        let nqPayload = { "filter": { "user.email": email, "serviceId": serviceId, "user.role": "ihdesigner" } }
        const fetchDesignerProjectsPayload = {
            filter: {
                "designerID": email,
            },
            sort: [["created_at", -1]],
            projection: {
                "_id": 0,
                "created_at": 1,
                "designerID": 1,
                "isProjectActive": 1,
                "customerID": 1,
                "ProjectName": 1
            }
        }
        Promise.all([service.httpDBService(nqPayload, "nextqore", "nq-userpermissions"), service.httpDBService(fetchDesignerProjectsPayload, serviceId, "nq-customer-project-designer-mappings")]).then((res) => {
            const designerDetails = res[0]
            const designerProjects = res[1]
            if (designerDetails && designerDetails.length) setDesigner(designerDetails[0])
            if (designerProjects && designerProjects.length) {
                const projectsMap = {}
                const customers = []

                //verify logic for active proj
                let activeProj = 0
                designerProjects.forEach(ele => {
                    const custid = ele.customerID
                    if (ele.isProjectActive) activeProj++
                    if (!(custid in projectsMap)) {
                        projectsMap[custid] = [{ name: ele.ProjectName, value: ele.ProjectName }]
                        customers.push({ name: custid, value: custid })
                    }
                    else {
                        projectsMap[custid].push({ name: ele.ProjectName, value: ele.ProjectName })
                    }
                });
                projectsList.current = projectsMap
                customersList.current = customers
                activeAndTotalProj.current = { active: activeProj, total: designerProjects.length }
                setDesignerProjects(designerProjects)
                setDesignerProjectsCopy(designerProjects)
            }
        })

    }, [])

    const handleClick = (e, toOpen) => {
        const splitted_id = e.currentTarget.id.split("_")
        const customerId = splitted_id[0]
        const projectName = splitted_id[1]
        setShowProjectDetails({ customerId, projectName, toOpen })
    }

    const handleCopy = (e) => {
        const copiedDCode = document.getElementById("designerCode").innerText
        navigator.clipboard.writeText(copiedDCode).then(() => {
            document.getElementById("copy-tooltip").classList.add("copied-tooltip");
            //reset the copied to empty
            setTimeout(() => {
                document.getElementById("copy-tooltip").classList.remove("copied-tooltip");
            }, 2000);
        })
    }

    const changeProfilePic = (e) => {
        let file = e.target.files[0]
        let url = process.env.REACT_APP_TENANT_SERVICE_URL + "/update-profile-pic"

        const formData = new FormData()
        formData.append("serviceId", serviceId)
        formData.append("email", email)
        formData.append("image", file)
        service.httpService(httpConstants.METHOD_TYPE.POST, formData, url, { "Content-Type": httpConstants.CONTENT_TYPE.MULTIPART_FORM_DATA }).then((res) => {
            if (res && res.success === true) {
                document.getElementById("profile-pic").src = process.env.REACT_APP_IMAGE_STATIC_URL + "/" + res.responseData
            }
        })
    }

    useEffect(async () => {
        let filteredRecords = []
        if (selectedCustomer || selectedProject) {
            if (selectedCustomer && selectedProject) filteredRecords = designerProjectsCopy.filter((item) => item.customerID.includes(selectedCustomer) && item.ProjectName.includes(selectedProject))
            else if (selectedCustomer) filteredRecords = designerProjectsCopy.filter((item) => item.customerID.includes(selectedCustomer))
            else filteredRecords = designerProjectsCopy.filter((item) => item.ProjectName.includes(selectedProject))
        }
        else filteredRecords = designerProjectsCopy
        setDesignerProjects(filteredRecords)
    }, [selectedCustomer, selectedProject])

    const onBack = useCallback(() => {
        setShowProjectDetails(null)
    }, [])

    if (showProjectDetails) return <ProjectDetail designer={user} customerId={showProjectDetails.customerId} projectName={showProjectDetails.projectName} toShow={showProjectDetails.toOpen} back={onBack} />
    else return (
        <>
            {isAdmin && <div className='flex align-items-center' style={{ gap: 10, fontFamily: "Larken" }}>
                <img onClick={() => back()} src='/images/Back.svg' className='cursor-pointer' />
                <h2 className='m-0 font-light'>Designer</h2>
            </div>}
            <div className='flex flex-wrap col-12 p-0'>
                <div className='col-12 md:col-6 mt-3 flex'>
                    <div className='designer-card-border p-2 flex-grow-1'>
                        <div className='flex align-items-center'>
                            <div className='relative'>
                                <div className='designerProfilePicture m-2'>
                                    <img id="profile-pic" style={{ width: "100%", objectFit: "cover" }} src={process.env.REACT_APP_IMAGE_STATIC_URL + "/" + designer.image} alt={`${designer.user?.name}'s profile`} loading='lazy' onError={(e) => e.target.src = (process.env.REACT_APP_IMAGE_STATIC_URL + "/" + "images/dummy.jpg")} />
                                </div>
                                {!isAdmin ? <div className="fileUpload changeProfileImg">
                                    <div className="text-6xl mb-2" >
                                        <Button icon="pi pi-camera" className=" cursor-pointer sage-ivory p-button-rounded p-1 m-0" />
                                    </div>
                                    <input style={{ width: "50%", top: "22%", right: "5%" }} type="file" className="upload mt-2 h-2rem w-2rem cursor-pointer" value="" onChange={changeProfilePic} accept={accept} />
                                </div> : null}
                            </div>

                            <div className='ml-3'>
                                <h3 className='mb-2 font-Gerstner-ProgramRegular charcoal-color' style={{ letterSpacing: "1px" }}>{designer.user?.name ?? "--"}</h3>
                                <p className='m-1 font-Gerstner-ProgramRegular text-base' style={{ color: "#47422F", wordBreak: "break-all" }}><i className='pi pi-phone mr-2'></i>{`${designer.user?.countryCode ?? ""} ${designer.user?.mobile?.split(designer.user?.countryCode)[1]}`}</p>
                                <p className='m-1 font-Gerstner-ProgramRegular text-base' style={{ color: "#47422F", wordBreak: "break-all" }}><i className='pi pi-envelope mr-2'></i>{designer.user?.email}</p>
                                <p className='m-1 font-Gerstner-ProgramRegular text-base' style={{ color: "#47422F", wordBreak: "break-all" }}>
                                    <span className='mr-2'>Code:</span>
                                    <span id="designerCode">{designer.user?.designerCode ?? "--"}</span>
                                    <i id="copy-btn" className='pi pi-clone ml-2 cursor-pointer font-bold' onClick={handleCopy} style={{ transform: 'scaleX(-1)' }}></i>
                                    <span id="copy-tooltip" className='font-bold ml-2' style={{color:"green", visibility:"hidden"}}>Copied!</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 flex md:col-6 mt-3 '>
                    <div className='flex flex-wrap flex-grow-1' style={{ gap: 15 }}>
                        <div className='p-3 designer-card-border flex-grow-1'>
                            <span className='text-base charcoal-color font-semibold'>Total Projects</span>
                            <h2 className='m-0 md:mt-4 charcoal-color  my-auto  text-7xl font-bold'>{activeAndTotalProj.current["total"] ?? 0}</h2>
                        </div>
                        <div className='p-3 designer-card-border flex-grow-1'>
                            <span className='text-base charcoal-color font-semibold'>Active Projects</span>
                            <h2 className='m-0 text-7xl md:mt-4 font-bold' style={{ color: "#9AAB89" }}>{activeAndTotalProj.current["active"] ?? 0}</h2>
                        </div>
                    </div>

                </div>
            </div>
            <div className='col-12 flex flex-wrap align-items-center'>
                <div className='col-12 lg:col-4'>
                    <label className='font-larken text-lg'>
                        Select Customer
                        <Dropdown value={selectedCustomer} options={customersList.current} onChange={(e) => { setSelectedCustomer(e.value); setSelectedProject("") }} style={ddStyle} className="w-full mt-1" optionLabel="name" placeholder="Select Customer" />
                    </label>
                </div>
                <div className='col-12 lg:col-4'>
                    <label className='font-larken text-lg'>
                        Select Project
                        <Dropdown value={selectedProject} options={projectsList.current[selectedCustomer] ?? []} disabled={!selectedCustomer} onChange={(e) => setSelectedProject(e.value)} style={ddStyle} className="w-full mt-1" optionLabel="name" placeholder="Select Projects" />
                    </label>
                </div>
                <div className='col-12 lg:col-4'>
                    <Button label="Reset" onClick={() => { setSelectedCustomer(""); setSelectedProject("") }} style={{ letterSpacing: "1.2px" }} className="p-button-rounded px-4 p-button-danger mt-5" />
                </div>
            </div>
            <div id="projects-table-container" className='overflow-x-auto'>
                <table className='w-full p-3 designer-card-border' cellSpacing={0}>
                    <thead className='text-left'>
                        <tr className='' >
                            <th className='pb-2 px-1 white-space-nowrap font-larken text-lg font-semibold' style={borderBottom}>Project Name</th>
                            <th className='pb-2 px-1 white-space-nowrap font-larken text-lg font-semibold' style={borderBottom}>Client</th>
                            <th className='pb-2 px-1 white-space-nowrap font-larken text-lg font-semibold' style={borderBottom}>Start Date</th>
                            <th className='pb-2 px-1 white-space-nowrap font-larken text-lg font-semibold' style={borderBottom}>Survey Data</th>
                            <th className='pb-2 px-1 white-space-nowrap font-larken text-lg font-semibold' style={borderBottom}>Manage Project</th>
                        </tr>
                    </thead>
                    <tbody>
                        {designerProjects.map((item, index) => {
                            let bstyle = index === designerProjects.length - 1 ? {} : borderBottom
                            return <tr className='' key={index}>
                                <td className='py-2 px-1 text-base' style={bstyle}>{item.ProjectName}</td>
                                <td className='py-2 px-1 text-base' style={bstyle}>{item.customerID}</td>
                                <td className='py-2 px-1 text-base' style={bstyle}>{item.created_at}</td>
                                <td className='py-2 px-1 text-base cursor-pointer' style={bstyle}><a id={item.customerID + "_" + item.ProjectName} onClick={(e) => handleClick(e, "survey")}>View</a></td>
                                <td className='py-2 px-1 text-base cursor-pointer' style={bstyle}><a id={item.customerID + "_" + item.ProjectName} onClick={(e) => handleClick(e, "manage")}>Manage</a></td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default DesignerProfile