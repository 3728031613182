import React, { useState } from 'react'
import { useEffect } from 'react'
import { plazaServices } from './plazaReportService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GetFilters } from './GeneralBlackListReport';
import service from "../../service/dbService";
import * as XLSX from "xlsx";
import moment from 'moment';
import { Button } from 'primereact/button';

const AvcAccuraccyReports = ({ clearAll }) => {
    const serviceId = localStorage.getItem('serviceId')
    const [masterAvcData, setMasterAvcData] = useState([])
    const [plazaList, setPlazaList] = useState([])
    const [selectedPlaza, setSelectedPlaza] = useState("All")
    const [allPlazas,setAllPlazas] = useState([])
    const [dateRange, setDateRange] = useState([new Date(moment().subtract(1, "days").format('YYYY-MM-DD')), new Date(moment().subtract(1, "days").format('YYYY-MM-DD'))]);
    const TableView = (data, key,ind) => {
        return (
            <div className='card col-12 grid align-items-center justify-content-between m-0 mb-2' key={ind}>
                <h2>{key}</h2>
                <div className="download-box ">
                    <Button className=" p-button-text " onClick={() => { downloadExcel(data, `Details For ${key} plaza.xlsx`, key) }}>
                        <span className="download-text">Download Report</span>
                        <i className="pi pi-download ml-3" style={{ color: '#0C6291' }}></i>
                    </Button>
                </div>
                <DataTable value={data} responsiveLayout="scroll" showGridlines className='mt-2 mb-2 col-12'>
                    <Column headerClassName="blue-bg" className="text-center" header="Vehicle Class" field='mvc'></Column>
                    <Column headerClassName="blue-bg" className="text-center" header="Total Traffic" field='totalTraffic'></Column>
                    <Column headerClassName="blue-bg" className="text-center" header="Matched Class" field='matchedClassTraffic'></Column>
                    <Column headerClassName="blue-bg" className="text-center" header="Mismatch Class" body={(row)=>{return row.totalTraffic-row.matchedClassTraffic}}></Column>
                    <Column headerClassName="blue-bg" className="text-center" header="Percentage" body={(row)=>{return row.totalTraffic ?((row.matchedClassTraffic/row.totalTraffic)*100).toFixed(0)+"%":0}}></Column>
                </DataTable>
            </div>
        )
    }
    const handleDateChange = (e) => {
        const [startDate, endDate] = e.value;
        let formattedStartDate = null;
        let formattedEndDate = null;

        if (startDate !== null) {
            formattedStartDate = moment(startDate).startOf('day').toDate();
        }

        if (endDate !== null) {
            formattedEndDate = moment(endDate).endOf('day').toDate();
        }
        const temp = [formattedStartDate, formattedEndDate];
        setDateRange(temp);
    };
    const downloadExcel = (data, fileName = "data.xlsx", key) => {
        if (!data || data.length === 0) {
            console.warn("No data available to download");
            return;
        }

        const formattedData = data.map(({ _id, mvc, matchedClassTraffic, totalTraffic }) => ({
            Plaza: key,
            Total: totalTraffic,
            "Matched ClassTraffic": matchedClassTraffic,
            "Vehicle Class": mvc,
            Percentage:totalTraffic?((matchedClassTraffic/totalTraffic)*100).toFixed(0):0
        }));

        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, fileName);
    };
    const downloadAllExcel = (data, fileName = "Details for All Plaza.xlsx", key) => {
        if (!data || data.length === 0) {
            console.warn("No data available to download");
            return;
        }

        const formattedData = data.map(({ _id, mvc, matchedClassTraffic, totalTraffic }) => ({
            Plaza: _id["plaza"],
            Total: totalTraffic,
            "Matched ClassTraffic": matchedClassTraffic,
            "Vehicle Class": mvc,
            Percentage:totalTraffic?((matchedClassTraffic/totalTraffic)*100).toFixed(0):0
        }));

        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, fileName);
    };

    const getData = (plazaList, dateRange) => {
        const seprateByPlaza = (arr) => {
            let plazaMap = {}
            arr.length && arr.map((item) => {
                if (!plazaMap[item["_id"]["plaza"]]) {
                    plazaMap[[item["_id"]["plaza"]]] = []
                    plazaMap[[item["_id"]["plaza"]]].push(item)
                }
                else plazaMap[[item["_id"]["plaza"]]].push(item)
            })
            let data = []
            for(const key in plazaMap){
                data = [...data,...plazaMap[key]]
            }
           setAllPlazas(data)
            return plazaMap;
        }

        const makePayload = () => {
            let matchObj = {
                date: {
                    $lte: moment(dateRange[1]).format('YYYY-MM-DD'),
                    $gte: moment(dateRange[0]).format('YYYY-MM-DD')
                }
            }
            if (selectedPlaza && selectedPlaza !== "All") matchObj["plazacode"] = selectedPlaza
            else matchObj["plazacode"] = { $in: plazaList }
            let query = {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            ...matchObj
                        }
                    },
                    {
                        $group: {
                            _id: {
                                date: "$date",
                                plaza: "$plazacode",
                                mvc: "$mvc"
                            },
                            totalTraffic: { $sum: "$totalTraffic" },
                            matchedClassTraffic: { $sum: "$matchedClassTraffic" },
                            mvc: { $first: "$mvc" }

                        }
                    },
                    {
                        $project: {
                            "_id.plaza": 1,
                            totalTraffic: 1,
                            matchedClassTraffic: 1,
                            mvc: 1
                        }
                    },
                    {
                        $sort: {
                            "_id.date": 1
                        }
                    }
                ]
            }
            return query;
        }
        Promise.all([plazaServices.general(serviceId, makePayload(), "nq-avc_accuracy_daily")]).then((res) => {
            let arr;
            if (res && res.length) arr = seprateByPlaza(res[0])
            if (res && res.length) setMasterAvcData(arr)
        })
    }

    useEffect(() => {
        let projection = { spaceName: 1, }
        if (dateRange[0] && dateRange[1]) {
            Promise.all([service.getThingNames(serviceId, projection)])
                .then((res) => {
                    let tempArray = [];
                    let spvPlazaMap = {};
                    let spvZonePlazaMap = {};
                    let plazaList = [{ name: "All", value: "All" }]

                    // Process the results to populate tempArray and maps
                    res[res.length - 1].forEach((item) => {
                        spvPlazaMap[item.spaceName] = item.SPV;
                        spvZonePlazaMap[item.spaceName] = item.Zone;

                        if (item.spaceName && item.spaceName !== "seasn_gnst_134567") {
                            plazaList.push({
                                name: item.spaceName,
                                value: item.spaceName
                            })
                            tempArray.push(item.spaceName);
                        }
                    });
                    if (tempArray.length && dateRange[0] && dateRange[1]) {
                        setPlazaList(plazaList)
                        getData(tempArray, dateRange);

                    }
                })
                .catch((error) => {
                    console.error("Error fetching thing names:", error);
                    // Handle error (optional)
                });
        }
    }, [dateRange, selectedPlaza])
    return (
        <div className='col-12 m-0 grid align-items-center '>
            <div className='col-12 m-0 grid align-items-center card mb-4'> <GetFilters selectedPlaza={selectedPlaza} setSelectedPlaza={setSelectedPlaza} plazaList={plazaList} handleDateChange={handleDateChange} dateRange={dateRange} downloadExcel={downloadAllExcel} masterData={allPlazas} clearAll={clearAll} text="avcAccuracy" /></div>

            {
                masterAvcData && Object.keys(masterAvcData).map((item,ind) => {
                    return TableView(masterAvcData[item], item,ind)
                })
            }</div>
    )
}

export default AvcAccuraccyReports