import React, { useState } from 'react';
import { Button } from 'primereact/button';
import AvcAccuraccyReports from './AvcAccuraccyReports';
import GeneralBlackListReport from './GeneralBlackListReport';

const AccuracyReports = () => {
    const [gftrShow, setGftrShow] = useState({
        avcAccuracy: false,
        malfunction: false,
        blackList:false
    });
    const setFunc = (item)=>{
        let temp = {...gftrShow}
        temp[item]=!temp[item]
        setGftrShow(temp)
    }

    const compKeyMap = {
        avcAccuracy: { comp: <AvcAccuraccyReports clearAll={setFunc}  />, Text: 'Avc Accuracy Reports' },
        malfunction: { comp: <GeneralBlackListReport clearAll={setFunc}  type={"malfunction"}/>, Text: 'Malfunctions Report' },
        "blackList": { comp: <GeneralBlackListReport clearAll={setFunc} type={"blackList"} />, Text: 'Blacklist Report' }
    };

    const reportShow = (item, ind) => {
        if (gftrShow[item]) return compKeyMap[item]?.comp;
        
        return (
            <div key={ind} className="flex flex-column">
                <div className="styled-div flex flex-wrap col-12 mb-4 mt-2">
                    <div className="inter flex">{compKeyMap[item]?.Text}</div>
                    <div className="viewReport flex">
                        <Button
                            className="bg-white reportsColour hover:text-gray-900"
                            label="View reports"
                            onClick={() => {setFunc(item)}}
                        >
                            <i className="pi pi-arrow-right ml-2 reportsColour" style={{ marginRight: '8px' }}></i>
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            {["avcAccuracy", "malfunction", "blackList"].map((item, ind) => reportShow(item, ind))}
        </div>
    );
};

export default AccuracyReports;
