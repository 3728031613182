import React, { useEffect, useRef, useState, memo } from 'react'
import imagineHomeService from "./imagineHomeService"
import { Dropdown } from 'primereact/dropdown'
import ListFiles from './ListFiles'
import { Dialog } from 'primereact/dialog'
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button'
import { Toast } from "primereact/toast";

const DesignerHome = ({ customerId, projectName }) => {
    const serviceId = localStorage.getItem("serviceId")
    const [errMsg, setErrMsg] = useState("")
    const [selectedFileType, setSelectedFileType] = useState("")
    const [loader, setLoader] = useState(true)
    const room_file_type_master = useRef({})
    const [roomWiseFiles, setRoomWiseFiles] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [file, setFile] = useState(null);
    const [disabled, setDisabled] = useState(false)
    const [selectedTab, setSelectedTab] = useState("customer")
    const quotesMap = useRef({})
    const invoiceMap = useRef({})
    const roomName = useRef("")
    const toast = useRef();

    const upload_down_map = {
        0: "customer",
        1: "designer"
    }

    useEffect(() => {
        if (customerId !== "" && projectName !== "") {
            const formData = new FormData()

            formData.append("serviceId", serviceId)
            formData.append("customerID", customerId)
            formData.append("ProjectName", projectName)
            formData.append("isProjectActive", true)

            imagineHomeService.fetchProjectFiles(formData).then((res) => {
                if (res && res.files && res.files.length > 0) {
                    let roomWiseFilesResp = roomWiseFilterFiles(res.files)
                    setRoomWiseFiles(roomWiseFilesResp)

                    const roomTypeLevel = 2
                    const roomLevel = 3

                    if (res.quotes && res.quotes.length) {
                        quotesMap.current = formattedQuoteInvoice(res.quotes, roomTypeLevel, roomLevel)
                    }
                    if (res.invoices && res.invoices.length) {
                        invoiceMap.current = formattedQuoteInvoice(res.invoices, roomTypeLevel, roomLevel)
                    }
                }
                else {
                    setRoomWiseFiles({})
                }
                setLoader(false)
            })
            function formattedQuoteInvoice(data, rtl, rl) {
                let res = {}
                data.forEach((it) => {
                    let splittedItem = it.location.split("/")
                    const key = splittedItem[rtl] + "_" + splittedItem[rl]
                    res[key] = it
                })
                return res
            }
        }
    }, [])

    const roomWiseFilterFiles = (files) => {
        let roomLevel = 3
        const customerFilesMap = {}
        const designerFilesMap = {}
        files.forEach((item, i) => {
            const splittedPath = item.split("/")
            const room = splittedPath[roomLevel]
            const file = splittedPath[splittedPath.length - 1]
            const room_type = splittedPath[roomLevel - 1]
            if (room) {
                const key = room_type + "_" + room
                if (splittedPath[roomLevel + 1]?.toLowerCase() === upload_down_map[0]) {
                    //custSet.add(room_type + "_" + room)
                    if (key in customerFilesMap) customerFilesMap[room_type + "_" + room].push({ filename: file, room: room, roomType: room_type, filekey: item })
                    else customerFilesMap[room_type + "_" + room] = [{ filename: file, room: room, roomType: room_type, filekey: item }]
                    //customerFiles.push({ filename: file, room: room, roomType: room_type, filekey: item })
                }
                else if (splittedPath[roomLevel + 1]?.toLowerCase() === upload_down_map[1]) {
                    //designSet.add(room_type + "_" + room)
                    if (key in designerFilesMap) designerFilesMap[room_type + "_" + room].push({ filename: file, room: room, roomType: room_type, filekey: item })
                    else designerFilesMap[room_type + "_" + room] = [{ filename: file, room: room, roomType: room_type, filekey: item }]
                    //designerFiles.push({ filename: file, room: room, roomType: room_type, filekey: item })
                }
            }
        })
        for (let key in customerFilesMap) {
            if (!(key in designerFilesMap)) {
                designerFilesMap[key] = [{ filename: "", room: key.split("_")[1], roomType: key.split("_")[0], filekey: "" }]
            }
        }
        return { [upload_down_map[0]]: customerFilesMap, [upload_down_map[1]]: designerFilesMap }
    }

    const handleUploadClick = (e) => {
        let data = e.currentTarget.id
        if (!room_file_type_master.current.roomType && !room_file_type_master.current.fileType) {
            const payload = { filter: { "usertype": "Designer" } }
            Promise.all([imagineHomeService.fetchMaster("nq-fileType-master", serviceId, payload)/*, imagineHomeService.fetchMaster("nq-roomType-master", serviceId, {})*/]).then((res) => {
                if (res[0][0].fileTypes.length) room_file_type_master.current["fileType"] = res[0][0].fileTypes?.map(r => ({ name: r, value: r }))
                // if (res[1][0].roomTypes.length) room_file_type_master.current["roomType"] = res[1][0].roomTypes?.map(f => ({ name: f, value: f }))
                setShowModal(true)
            })
        }
        else setShowModal(true)
        roomName.current = data

    }

    const onFileSelect = (e) => {
        setFile(null)
        if (e.files.length > 0) {
            setFile(e.files[0]);
        }
    };
    const handleSubmit = (e, isQuoteOrInvoice = null, qAndIData = {}) => {
        let rtype, rname = ""
        const formData = new FormData()
        if (!isQuoteOrInvoice) {
            setDisabled(true)
            let validateres = validateSubmit()
            if (validateres) {
                setErrMsg(validateres)
                setDisabled(false)
                return
            }
            rtype = roomName.current.split("_")[0]
            rname = roomName.current.split("_")[1]
            formData.append("fileType", selectedFileType)
            formData.append("file", file)
        }
        else {
            rtype = qAndIData["roomType"]
            rname = qAndIData["roomName"]
            formData.append(isQuoteOrInvoice, qAndIData["id"])
            formData.append("fileType",  qAndIData["fileType"])
        }

        formData.append("serviceId", serviceId)
        formData.append("customerID", customerId)
        formData.append("ProjectName", projectName)
        formData.append("roomType", rtype)
        formData.append("roomName", rname)
        formData.append("isCustomer", false)

        imagineHomeService.uploadRoomFile(formData).then((res) => {
            if (res) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: isQuoteOrInvoice ? `${isQuoteOrInvoice} add success` : "File Upload Success" });
                if(!isQuoteOrInvoice){
                    const newRow = { filename: file.name, room: rname, roomType: rtype, filekey: "" }
                    roomWiseFiles[upload_down_map[1]][rtype + "_" + rname].push(newRow)
                    setSelectedFileType("")
                    setFile(null)
                    setShowModal(false)
                    setDisabled(false)
                }
                else {
                    const handleAfterSubmit = qAndIData["handleSubmit"]
                    handleAfterSubmit(qAndIData)
                }
            }
            else toast.current.show({ severity: 'error', summary: 'error', detail: "Error to add/update" });

        }).catch((err) => {
            console.log(err)
        })

    }
    const validateSubmit = () => {
        if (!selectedFileType) return "File Type Not Selected"
        if (!file) return "No File Chosen"
    }

    const changeTab = (e) => {
        if (e.target.tagName === 'LI') {
            document.querySelectorAll('#tab li').forEach(li => li.classList.remove('tab-selected'));
            e.target.classList.add('tab-selected');

            if (selectedTab == e.target.id) return
            let newSelectedTab = ""
            Object.values(upload_down_map).forEach((item) => {
                if (item === e.target.id) {
                    newSelectedTab = item
                }
            })
            setSelectedTab(newSelectedTab)
        }

    }

    return (
        !loader ? <div className='' style={{ color: "#1D1B1C" }}>
            <Toast ref={toast} />
            <div className='col-12 custom-tabview '>
                <ul id="tab" onClick={changeTab}>
                    <li className='text-2xl tab-selected' id="customer">Customer Scan</li>
                    <li className='text-2xl' id="designer">Proposal Upload</li>
                </ul>
                <div className='overflow-y-auto'>
                    <ListFiles submit={handleSubmit} data={roomWiseFiles[upload_down_map[selectedTab === "customer" ? 0 : 1]]} upload={handleUploadClick} quotes={quotesMap.current} invoices={invoiceMap.current} list={selectedTab} />
                </div>
            </div>
            <Dialog showHeader={false} visible={showModal} modal style={{ width: "50vw", color: "#1D1B1C" }} onHide={() => setShowModal(false)}>
                <div className="col-12 pt-3 charcoal">
                    <div className='col-12'>
                        <label>
                            Select File Type
                            <Dropdown value={selectedFileType} options={room_file_type_master.current["fileType"] ?? []} onChange={(e) => setSelectedFileType(e.value)} className="bg-white w-full mt-1" optionLabel="name" placeholder="Select File Type" />
                        </label>
                    </div>
                    <div className='col-12'>
                        <FileUpload name="demo[]" customUpload={true} auto={true} multiple={false} showUploadButton={false} onSelect={onFileSelect} onClear={() => setFile(null)}
                            mode="advanced" />
                    </div>
                    {errMsg && <p className='text-red-500'>{errMsg}</p>}
                    <div className='col-12 flex justify-content-between'>
                        <Button label='Submit' className='dijon-ivory font-Gerstner-ProgramRegular' disabled={disabled} onClick={handleSubmit}></Button>
                        <Button label='Cancel' className='p-button-danger font-Gerstner-ProgramRegular' onClick={() => setShowModal(false)}></Button>
                    </div>
                </div>


            </Dialog>
        </div> : <div style={{ width: "100%", textAlign: "center", height: "80vh", verticalAlign: "center", position: "relative" }}><i className="pi pi-spin pi-spinner" style={{ fontSize: '4rem', verticalAlign: "middle", position: "absolute", top: "45%", left: "45%" }}></i></div>
    )
}

export default memo(DesignerHome)