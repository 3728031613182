import React, { useEffect, useState } from 'react'
import { plazaServices } from './tollPlaza/plazaReportService'
import moment from 'moment'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button'

const ActionIndicator = () => {
    const serviceId = localStorage.getItem('serviceId')
    const dateRange = [moment().subtract(1, "days").format("YYYY-MM-DD"), moment().subtract(20, "days").format("YYYY-MM-DD")]
    const [masterData, setMasterData] = useState([])
    const history = useHistory()
    const returnSampleObj = () => {
        return {
            head: "",
            failure: "",
            sucess: "",
            "%": "",
            "movingAvg": "",
            "status": "",
            "remark": ""
        }

    }

    const makeQuery = (projection, time, type) => {
        let query = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        date: { $lte: dateRange[0], $gte: dateRange[1] },
                        hr: time,
                    }
                },
            ]
        }
        if (type === "ingestion") {
            query.aggregate = [
                {
                    $match: {
                        date: { $lte: dateRange[0], $gte: dateRange[1] },
                        hr: time,
                    }
                },
                { "$unwind": "$ingestionStatus" },
                {
                    $match: {
                        "ingestionStatus.ingestionStatus": { $nin: ["Delete", "Unavailable"] }
                    }
                },
                {
                    "$group": {
                        "_id": { date: "$date", hr: "$hr" },
                        "totalCount": { "$sum": "$ingestionStatus.count" }
                    }
                },
                {
                    "$group": {
                        "_id": null,
                        "avg_cont": {
                            "$avg": "$totalCount"
                        },
                    }
                }
            ]
        }
        return query;
    }

    const calculateAvgPercent = (data, stat) => {
        if (!data || (Array.isArray(data) && data.length === 0)) {
            console.error("Error: Data is undefined or empty.");
            return 0;
        }
        const dataArray = Array.isArray(data) ? data : [data];
        const dailyStats = {};
        dataArray.forEach(entry => {
            const { date, status } = entry._id;
            const count = entry.totalCount;
            if (!dailyStats[date]) {
                dailyStats[date] = { total: 0, successful: 0 };
            }
            dailyStats[date].total += count;
            if (status === stat) {
                dailyStats[date].successful += count;
            }
        });
        const dailyPercentages = Object.values(dailyStats).map(stats =>
            stats.total > 0 ? (stats.successful / stats.total) * 100 : 0
        );
        const avgSuccessPercentage = dailyPercentages.length > 0
            ? dailyPercentages.reduce((sum, percent) => sum + percent, 0) / dailyPercentages.length
            : 0;

        return avgSuccessPercentage.toFixed(2);
    };

    let query = {
        operation: "aggregate",
        aggregate: [
            { "$unwind": "$ingestionStatus" },
            {
                $match: {
                    "ingestionStatus.ingestionStatus": { $nin: ["Delete", "Unavailable"] }
                }
            },
            {
                "$group": {
                    "_id": { date: "$date", hr: "$hr" },
                    "totalCount": { "$sum": "$ingestionStatus.count" }
                }
            },
        ]
    }

    const makeOrdersPayload = (type, payloadType, time) => {
        if (payloadType === "avg") {
            let obj = type === "cr" ? { "$sum": "$Count" } : { "$sum": "$totalOrderQty" };
            const pipeline = [
                {
                    $match: {
                        date: { $lte: dateRange[0], $gte: dateRange[1] },
                        "fdChannelDesc": { $ne: "CMS" },
                        hr: time
                    }
                },
                {
                    "$group": {
                        "_id": type === "cr"
                            ? { status: "$Status", date: "$date" }
                            : { date: "$date" },
                        "totalCount": obj
                    }
                }
            ];

            if (type !== "cr") {
                pipeline.push({
                    "$group": {
                        "_id": null,
                        "totalCount": { "$sum": "$totalCount" },
                        "uniqueDates": { "$addToSet": "$_id.date" }
                    }
                });

                pipeline.push({
                    "$addFields": {
                        "uniqueDateCount": { "$size": "$uniqueDates" }
                    }
                });

                pipeline.push({
                    "$project": {
                        "totalCount": 1,
                        "uniqueDateCount": 1
                    }
                });
            } else {
                pipeline.push({
                    "$group": {
                        "_id": { status: "$_id.status", date: "$_id.date" },
                        "totalCount": { "$sum": "$totalCount" }
                    }
                });

                pipeline.push({
                    "$project": {
                        "totalCount": 1
                    }
                });
            }

            return {
                operation: "aggregate",
                aggregate: pipeline
            };
        } else {
            return {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            date: moment().format("YYYY-MM-DD"),
                            "fdChannelDesc": { $ne: "CMS" }
                        }
                    },
                    {
                        "$group": {
                            "_id": `${type === "cr" ? "$Status" : null}`,
                            "totalCount": { "$sum": `${type === "cr" ? "$Count" : "$totalOrderQty"}` }
                        }
                    }
                ]
            };
        }
    };

    const statusFinder = (row) => {
        let iconCol;
        let movingAvg15 = (15 / 100) * row["movingAvg"]
        let movingAvg10 = 0.1 * row["movingAvg"]
        if (["Content Request", "Amazon", "Bookscape"].includes(row["head"])) {
            const diff = Math.abs(row["%"] - row["movingAvg"]);
            if (row["%"] > row["movingAvg"]) {
                row["remark"] = ""
                iconCol = "#81BE56"
            } else if (diff > 15) {
                row["remark"] = ""
                iconCol = "red"
            }
            else if (diff > 10 && diff < 15) {
                row["remark"] = ""
                iconCol = "#FFBA47"
            }
            else {
                row["remark"] = ""
                iconCol = "#81BE56"
            }
        }
        else {
            const diff = Math.abs(row["sucess"] - row["movingAvg"]);
            if (row["sucess"] > row["movingAvg"]) {
                row["remark"] = ""
                iconCol = "#81BE56"
            } else if (diff > movingAvg15) {
                row["remark"] = ""
                iconCol = "red"
            }
            else if (diff > movingAvg10 && diff < movingAvg15) {
                row["remark"] = ""
                iconCol = "#FFBA47"
            }
            else {
                row["remark"] = ""
                iconCol = "#81BE56"
            }
        }
        return <i className={`pi  pi-circle-fill`} style={{ color: iconCol, fontSize: "1.7rem", width: "1.7rem", height: "1.7rem" }}></i>
    }

    const getTodayData = async () => {
        let masterData = []
        let time = ""
        let amazonSucces = 0;
        let amzSucc;
        let bookScapeSucc;
        let todayAmazonFail = 0;
        let todayBookScapeFail = 0
        let bookScapeSucces = 0;
        let todayContentIngestion;
        let todayAmazonSucces = 0;
        let todayBookScapeSucces = 0;
        let totalContentAvg;
        let OrdersSucc;
        let todayCrSucc;
        let todayCrSucc_perc;
        let todayCrFail;
        let todayCrPending;
        let todayCrPendingperc
        let crPendingAvg;
        let crSuccAvg;
        let ordersAvg;
        await Promise.all([plazaServices.general(serviceId, query, "nq-dashboard-b2c-ContentIngestion_hourly_24hr-meta"), plazaServices.general(serviceId, {}, "nq-dashboard-b2c-Listing_hourly_24hr-meta",),
        plazaServices.general(serviceId, makeOrdersPayload(""), "nq-dashboard-b2c-ordByChannel_hourly_24h-meta"), plazaServices.general(serviceId, makeOrdersPayload("cr"), "nq-dashboard-b2c-ord_CR_ByChannel_hourly_24h-meta")
        ]).then((res) => {
            if (res && res.length && res.length > 2) {
                if (res[0][0] && res[1][0]) {
                    let item = res[1][0]
                    time = res[1][0].hr
                    todayContentIngestion = res[0][0].totalCount
                    todayAmazonSucces = item.Amazon_listing_succ
                    todayBookScapeFail = item.Bookscape_listing_fail
                    todayAmazonFail = item.Amazon_listing_fail
                    todayBookScapeSucces = item.Bookscape_listing_succ
                    amzSucc = (item.Amazon_listing_succ / (item.Amazon_listing_fail + item.Amazon_listing_succ)) * 100
                    bookScapeSucc = (item.Bookscape_listing_succ / (item.Bookscape_listing_fail + item.Bookscape_listing_succ)) * 100
                }
                if (res[2] && res[3]) {
                    OrdersSucc = res[2][0].totalCount
                    let total = 0
                    res[3].map((item) => {
                        if (item['_id'] === "Failed") todayCrFail = item["totalCount"]
                        if (item['_id'] === "Successful") todayCrSucc = item["totalCount"]
                        if (item['_id'] === "Pending") todayCrPending = item["totalCount"]
                        else todayCrPending = 0
                        total += item["totalCount"]
                    })
                    todayCrSucc_perc = (todayCrSucc / total) * 100
                    todayCrPendingperc = (todayCrPending / total) * 100
                }

            }

        })
        await Promise.all([
            plazaServices.general(serviceId, makeQuery({ "ingestionStatus": 1 }, time, "ingestion"), "nq-dashboard-b2c-ContentIngestion_hourly_24hr-data"),
            plazaServices.general(serviceId, makeQuery({ "Amazon_listing_succ": 1, "Amazon_listing_fail": 1 }, time), "nq-dashboard-b2c-Listing_hourly_24hr-data"),
            plazaServices.general(serviceId, makeOrdersPayload("", "avg", time), "nq-dashboard-b2c-ordByChannel_hourly_24h-data"),
            plazaServices.general(serviceId, makeOrdersPayload("cr", "avg", time), "nq-dashboard-b2c-ord_CR_ByChannel_hourly_24h-data")
        ]).then((res) => {
            if (res && res.length && res[0] && res[1]) {
                totalContentAvg = res[0][0].avg_cont
                let amzTotal = 0;
                let bookScapeTotal = 0;
                res[1].map((item) => {
                    amzTotal += (item.Amazon_listing_succ / (item.Amazon_listing_fail + item.Amazon_listing_succ)) * 100
                    bookScapeTotal += (item.Bookscape_listing_succ / (item.Bookscape_listing_fail + item.Bookscape_listing_succ)) * 100
                })
                amazonSucces = amzTotal / 20
                bookScapeSucces = bookScapeTotal / 20
            }
            if (res[2] && res[3]) {
                ordersAvg = res[2][0].totalCount / res[2][0].uniqueDateCount
                crSuccAvg = calculateAvgPercent([...res[3]], "Successful")
                crPendingAvg = calculateAvgPercent([...res[3]], "Pending");
            }
            let contentObj = returnSampleObj()
            contentObj["head"] = "Content Ingestion"
            contentObj["sucess"] = todayContentIngestion
            contentObj["movingAvg"] = totalContentAvg
            masterData[0] = contentObj
            let listing = returnSampleObj()
            listing["head"] = "Listing"
            masterData[1] = listing
            let amazonObj = returnSampleObj();
            amazonObj["head"] = "Amazon"
            amazonObj["sucess"] = todayAmazonSucces
            amazonObj["movingAvg"] = amazonSucces
            amazonObj["failure"] = todayAmazonFail
            amazonObj["%"] = amzSucc
            let bookScapeObj = returnSampleObj()
            bookScapeObj["head"] = "Bookscape"
            bookScapeObj["sucess"] = todayBookScapeSucces
            bookScapeObj["movingAvg"] = bookScapeSucces
            bookScapeObj["failure"] = todayBookScapeFail
            bookScapeObj["%"] = bookScapeSucc
            masterData.push(amazonObj)
            masterData.push(bookScapeObj)
            let ordersProcessingObj = returnSampleObj()
            ordersProcessingObj["head"] = "Order Processing"
            masterData.push(ordersProcessingObj)
            let ordersRecivesObj = returnSampleObj();
            ordersRecivesObj["head"] = "Orders Received"
            ordersRecivesObj["sucess"] = OrdersSucc
            ordersRecivesObj["movingAvg"] = ordersAvg
            masterData.push(ordersRecivesObj)
            let crObj = returnSampleObj();
            crObj["head"] = "Content Request Failure"
            crObj["sucess"] = todayCrSucc
            crObj["failure"] = todayCrFail
            crObj["%"] = todayCrSucc_perc
            crObj["movingAvg"] = crSuccAvg
            masterData.push(crObj)
            let crPendingObj = returnSampleObj();
            crPendingObj["head"] = "Content Request Pending"
            crPendingObj["failure"] = todayCrPending
            crPendingObj["%"] = todayCrPendingperc
            crPendingObj["movingAvg"] = crPendingAvg
            masterData.push(crPendingObj)
            setMasterData(masterData)
        })

    }

    useEffect(() => {
        getTodayData()
    }, [])

    const redirect = (row) => {
        if (["Content Request", "Orders Received", "Order Processing"].includes(row["head"])) history.push('/b2c-orders')
        else history.push('/b2c-listing-content')

    }

    return (
        <div className="col-12 grid card shadow-3 p-3 border-round" style={{ fontFamily: "Inter" }}>
            <p className="chartHeading mb-3 text-2xl font-bold text-primary">
                Action Indicator
            </p>

            <DataTable
                onRowClick={(e) => redirect(e.data)}
                className="datatable-box w-full"
                style={{ cursor: "pointer" }}
                value={masterData}
                responsiveLayout="scroll"
                rows={10}
                rowClassName={(row) => {
                    switch (row['head']) {
                        case 'Listing':
                            return 'bg-cyan-300 text-cyan-300 ';
                        case 'Order Processing':
                            return 'bg-cyan-300 text-cyan-300 ';
                        default:
                            return 'text-blue-800 '; // Light Gray for Empty Rows
                    }
                }}
            >
                <Column
                    bodyClassName="text-xl p-1"
                    headerClassName="bg-blue-700 text-white text-2xl"
                    header="Process"
                    body={(row) => {
                        const getButtonColor = () => {
                            switch (row['head']) {
                                case 'Listing':
                                    return 'bg-cyan-300 text-white';
                                case 'Order Processing':
                                    return 'bg-cyan-300 text-white';
                                default:
                                    return 'bg-white text-blue-700 font-bold'; // Gray for Empty Rows
                            }

                        };

                        return (
                            <Button
                                className={`${getButtonColor()} ${["Content Request Failure", "Content Request Pending", "Orders Received", "Bookscape", "Amazon"].includes(row['head']) ? "ml-5" : ""}`}
                                style={{ fontSize: "1.2rem" }}
                                label={row["head"] || "No Data"}
                                onClick={() => redirect(row)}
                            />
                        );
                    }}
                />

                <Column
                    bodyClassName="text-xl text-center"
                    headerClassName="bg-blue-700 text-white text-center text-2xl"
                    header="Failure"
                    body={(row) => row.failure && row.failure.toFixed()}
                />

                <Column
                    bodyClassName="text-xl text-center"
                    headerClassName="bg-blue-700 text-white text-center text-2xl"
                    header="Success"
                    field="sucess"
                />

                <Column
                    bodyClassName="text-xl text-center"
                    headerClassName="bg-blue-700 text-white text-center text-2xl"
                    header="%"
                    body={(row) => row['%'] ? `${row['%'].toFixed(row["head"] === "Content Request" ? 1 : 0)}%` : null}
                />
                <Column
                    bodyClassName="text-xl text-center"
                    headerClassName="bg-blue-700 text-white text-center text-2xl"
                    header="Last 20 Day Moving Average"
                    body={(row) => {
                        const movingAvg = parseFloat(row["movingAvg"]);
                        const isValidNumber = !isNaN(movingAvg);
                        return isValidNumber && row["head"]
                            ? (["Bookscape", "Amazon", "Content Request"].includes(row["head"])
                                ? movingAvg.toFixed() + "%"
                                : movingAvg.toFixed())
                            : "";
                    }}
                />
                <Column
                    bodyClassName="text-xl text-center"
                    headerClassName="bg-blue-700 text-white text-center text-2xl"
                    header="Status"
                    body={(row) => row["head"] && !["Order Processing", "Listing"].includes(row["head"]) ? statusFinder(row) : ""}
                />

                <Column
                    bodyClassName="text-xl text-center"
                    headerClassName="bg-blue-700 text-white text-center text-2xl"
                    header="Remark"
                    field="remark"
                />
            </DataTable>
        </div>
    )


}

export default ActionIndicator