import React, { useState } from 'react'
import { RadioButton } from 'primereact/radiobutton'
import { Button } from 'primereact/button'
import PurchaseInvoice from './PurchaseInvoice'

const PaymentOptionsScreen = ({ plan, comeBackFunc, designerPlan }) => {
    const [payOptions, setPayOptions] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const achIcon = "ACH"
    const creditIcon = "CREDIT CARD"


    const Options = ({ item }) => {
        return <div className='mb-4' style={{
            display: 'flex',
            padding: '12px',
            alignItems: 'center',
            gap: '10px',
            borderRadius: '20px',
            border: '1px solid #9AAB89'
        }}
        >
            <div className="flex align-items-center col-12">
                <RadioButton inputId="ingredient1" name="pizza" value="Cheese" onChange={() => setPayOptions(item.key)} checked={item.key === payOptions} />
                <label htmlFor="ingredient1" className="ml-2">{item.icon}</label>
            </div>
        </div>
    }
    return <>{confirm ? <PurchaseInvoice plan={plan} comeBackFunc={setConfirm} designerPlan={designerPlan} payOption={payOptions}  /> : <div className='col-12 m-0'>
        <Button style={{ background: "#FBF9F3", color: "#1D1B1C" }} icon="pi pi-arrow-left col-12" onClick={() => comeBackFunc(null)} />
        <h3 className="col-12 planPurchaseHeading mt-0 mb-0" style={{ marginBottom: "2px", color: "#1D1B1C", fontSize: "2rem" }}>Payment Method</h3>
        <p className='m-0 purchasePlanSubHeading col-12' style={{ fontSize: "0.8rem" }}>Select Payment ,mode for transaction</p>
        {[{ icon: achIcon, text: "Ach Method", key: "ACH" }, { icon: creditIcon, text: "Credit Card", key: "CREDITCARD" }].map((item) => {
            return <Options item={item} />
        })}
        <Button className='addFeatureButton addFeatureButtonFont col-12' label='Proceed to Pay' disabled={!payOptions} style={{ backgroundColor: "#47422F", color: "white" }} onClick={()=>setConfirm(true)} />
    </div>}</>


}

export default PaymentOptionsScreen