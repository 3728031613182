import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button'
import { commonConfigCaller } from './FeatureDetails'
import EditPlanImgHome from './EditPlanImgHome'
import { plazaServices } from './tollPlaza/plazaReportService'
import { sortByPropValue } from './PlanPurchase'

const PlanDetails = ({planListPath="getRetainerPlans",designerPlan=false,createPath="addRetainerPlan",updatePath="updateRetainerPlan",benifitCollection = "nq-plan-benefit-masters"}) => {
    const [planDetails, setPlanDetails] = useState([])
    const [disabledPlans, setDisabledPlans] = useState([])
    const [editPlan, setEditPlan] = useState(false);
    const [plan, setPlan] = useState([])
    const [create, setCreate] = useState(false)
    const [admin, setAdmin] = useState(false)
    const serviceId = localStorage.getItem("serviceId")
    const getBenefit = async () => {
        let res = await plazaServices.general(serviceId, {}, benifitCollection)
        const obj = {
        }
        res.map((item) => {
            let val;
            let cat = item.category + "s"
            if (cat === "booleanBenefits") val = false;
            else val = 0;
            item["value"] = val;
            item["name"] = item["benefitNm"]
            if (!obj[cat]) obj[cat] = [item]
            else obj[cat].push(item)
        })
        setPlan({ ...obj, enabled: true, planNm: "", charge: "" })
        setCreate(true)
        setEditPlan(!editPlan)
    }
    useEffect(() => {
        Promise.all([commonConfigCaller(planListPath, "post", { "serviceId": serviceId, })]).then((res) => {
            const responseData = res[0]?.data?.responseData || [];
            let enabledPlans = responseData.filter(item => item.enabled);
            const disabledPlans = responseData.filter(item => !item.enabled);
            enabledPlans = sortByPropValue(enabledPlans, "charge").reverse()
            setPlanDetails(enabledPlans)
            setDisabledPlans(disabledPlans)
            let arr = JSON.parse(localStorage.getItem("permissions"));
            if (arr.includes("UserManagement.READ") && arr.includes("UserManagement.WRITE")) setAdmin(true)
        })
    }, [editPlan])

    const PlanCard = ({ plan }) => {
        plan.booleanBenefits = plan.booleanBenefits && plan.booleanBenefits.length?plan.booleanBenefits:[]
        plan.quantifiableBenefits = plan.quantifiableBenefits && plan.quantifiableBenefits.length ?plan.quantifiableBenefits:[]
        const arr = [...plan.booleanBenefits, ...plan.quantifiableBenefits].filter(item => item.value);
        let headStyle = !plan.enabled ? { color: "#BFAD5B" } : {}
        let borderStyle = !plan.enabled ? { border: "1px solid #E9E2C5" } : {}
        let inputStyle = !plan.enabled ? { color: "#76742" } : { color: "#47422F" }
        return (
            <div className='grid planCard lg:col-4 col-12 mb-4  card ' style={{ cursor: "pointer", ...borderStyle }} onClick={(e) => {
                if (plan.enabled && admin) {
                    setPlan(plan)
                    setCreate(false)
                    setEditPlan(!editPlan)
                }
            }}>
                <div className='lg:col-6 col-12 grid  m-0 align-items-center '>
                    <p className='planCardHead m-0 mr-3' style={headStyle}>{plan.planNm}{designerPlan?` Plan For ${plan.planUser==="normalUser"?"Normal User":"Brand Ambassador"}`:""}</p>
                </div>
                <p className=' col-12 m-0 planPrice'>Cost-$ {plan.charge}</p>
                <div className='col-12 grid m-0 mt-0' style={{ minHeight: "24rem", overflow: "auto" }}>
                    {!designerPlan ?arr.map((item, index) => (
                        <li
                            key={item.name || index}
                            className='grid col-12 mt-1 planDetailsLabel'
                            style={inputStyle}
                        >
                            {item.name}{typeof(item.value)==="boolean"?null:` : ${item.value}`} {item.supports?`:${item.supports}`:null}
                        </li>
                    )):null}
                    {designerPlan ?<li
                            
                            className='grid col-12 mt-1 planDetailsLabel'
                            style={inputStyle}
                        >
                            {`Plan User:${plan.planUser==="normalUser"?"Normal User":"Brand Ambassador"}`}
                        </li>:null}
                </div>
            </div>
        );
    };

    return (
        <>
            {!editPlan ? <div className='grid col-12 justify-content-between m-0 p-4 lg:ml-0 ml-3' >
                <div className='grid justify-content-between col-12 mt-1 align-items-center mb-4'>
                    <p className='m-0 featureHeading'>Plans</p>
                    {admin && !designerPlan ? <Button className='addFeatureButton addFeatureButtonFont ' label='Create Plan' icon="pi pi-plus" onClick={() => {
                        getBenefit()
                    }} /> : null}
                </div>
                <div className='col-12 ' style={{ display: "flex", gap: "20px",flexWrap:"wrap" }}>
                    {
                        Array.isArray(planDetails) && planDetails && planDetails.map((item) => {
                            return item.enabled ? <PlanCard plan={item}  /> : null
                        })
                    }
                </div>
                <p className='m-0 featureHeading'>Inactive Plan</p>
                <div className='col-12  mt-2'  style={{ display: "flex", gap: "20px",flexWrap:"wrap" }}>
                    {
                        Array.isArray(disabledPlans) && disabledPlans && disabledPlans.map((item) => {
                            return !item.enabled ? <PlanCard plan={item}  /> : null
                        })
                    }
                </div>
            </div> : <EditPlanImgHome plan={plan} showplanDet={editPlan} setshowPlanDet={setEditPlan} create={create} allPlan={planDetails} setAllPlan={setPlanDetails} setCreate={setCreate} createPath={createPath} updatePath={updatePath} designerPlan={designerPlan}/>}
        </>

    )
}

export default PlanDetails