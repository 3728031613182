import React from "react";

const ZohoDashboard = () => {
    const service = JSON.parse(localStorage.getItem("service"));
    const zohoDashboard = service.quicksightDashboardIds[0].dashboardId
    return (
        <div className="w-full h-full">
            <iframe title="zoho" frameBorder="0" src={zohoDashboard} className="w-full h-full mt-2" />
        </div>
    );
};

export default ZohoDashboard;
