import React from 'react'
import { useState, useEffect } from 'react'
import { plazaServices } from './plazaReportService'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { Row } from 'primereact/row'
import { Calendar } from 'primereact/calendar'
import moment from 'moment'
import service from "../../service/dbService";
import { Button } from 'primereact/button'
import * as XLSX from "xlsx";
let genUtilsMap = {
    "blackList": {
        collection: "nq-etc_blacklistCount_daily",
        unselected: "blacklistCount",
        key:"blackList"
    },
    "malfunction": {
        collection: "nq-etc_malfunction_daily",
        unselected: "malFuncReject",
        key:"malfunction"

    }
}

export const GetFilters = ({ selectedPlaza, plazaList, setSelectedPlaza, handleDateChange, dateRange, downloadExcel, masterData, clearAll, text }) => {
    return <>
        <div className="field m-0 md:col-3 col-12 mb-3">

            <label htmlFor="Service Name Type" className="mt-1">
                Select Plaza
            </label>
            <Dropdown value={selectedPlaza} options={plazaList} onChange={(e) => { setSelectedPlaza(e.target.value) }} className="general-bar-chart-filter flex align-items-center " optionLabel="name" placeholder="Select Service" name="Service"
                style={{
                    borderRadius: '17px',
                    border: '2px solid #0C6291',
                    width: "100%"
                }}
            />
        </div>
        <div className="field m-0 md:col-3 col-12 mb-3">
            <label htmlFor="Service Name Type" className="mt-1">
                Select Date
            </label>
            <Calendar
                id="range"
                value={dateRange}
                placeholder="Select Date"
                onChange={handleDateChange}
                selectionMode="range"
                showIcon
                readOnlyInput
                className="general-bar-chart-filter flex align-items-center "
                dateFormat="dd-mm-yy"
                style={{
                    borderRadius: '17px',
                    border: '2px solid #0C6291',
                    width: "100%"
                }}
            />
        </div>
        <div className="download-box mt-3">
            <Button className=" p-button-text " onClick={() => { downloadExcel(masterData) }}>
                <span className="download-text">Download Report</span>
                <i className="pi pi-download ml-3" style={{ color: '#0C6291' }}></i>
            </Button>
        </div>
        <Button className='mt-3' label='Cancel' style={{
            borderRadius: '17px',
            backgroundColor: "White",
            color:"black",
            border: '2px solid #0C6291',
            marginLeft:"25rem"

        }} onClick={() => {
            clearAll(text)

        }}></Button>

    </>
}

const GeneralBlackListReport = ({ type, clearAll }) => {
    const [masterData, setMasterData] = useState([])
    const [plazaList, setPlazaList] = useState([])
    const [selectedPlaza, setSelectedPlaza] = useState("All")
    const [dateRange, setDateRange] = useState([new Date(moment().subtract(1, "days").format('YYYY-MM-DD')), new Date(moment().subtract(1, "days").format('YYYY-MM-DD'))]);
    const serviceId = localStorage.getItem("serviceId")
    const makePayload = (plazaList) => {
        let matchObj = {
            date: {
                $lte: moment(dateRange[1]).format('YYYY-MM-DD'),
                $gte: moment(dateRange[0]).format('YYYY-MM-DD')
            }
        }
        if (selectedPlaza && selectedPlaza !== "All") matchObj["plazacode"] = selectedPlaza
        else matchObj["plazacode"] = { $in: plazaList }
        let query = {
            operation: "aggregate",
            aggregate: [
                {
                    $match: {
                        ...matchObj
                    }
                },
                {
                    $group: {
                        _id: "$plazacode",
                        malFuncReject: { $sum: `$${genUtilsMap[type]["unselected"]}` },
                        totalTraffic: { $sum: "$totalTraffic" }
                    }
                },
                {
                    $project: {
                        _id: 1,
                        malFuncReject: 1,
                        totalTraffic: 1
                    }
                }
            ]
        };
        return query;
    };
    const handleDateChange = (e) => {
        const [startDate, endDate] = e.value;
        let formattedStartDate = null;
        let formattedEndDate = null;

        if (startDate !== null) {
            // Create a new Date object using moment to ensure consistent handling
            formattedStartDate = moment(startDate).startOf('day').toDate();
        }

        if (endDate !== null) {
            // Create a new Date object using moment to ensure consistent handling
            formattedEndDate = moment(endDate).endOf('day').toDate();
        }
        const temp = [formattedStartDate, formattedEndDate];
        setDateRange(temp);
    };
    const getData = (plazaList) => {
        Promise.all([plazaServices.general(serviceId, makePayload(plazaList), genUtilsMap[type]["collection"])]).then((res) => {
            if (res && res.length) setMasterData(res[0])
        })
    }


    const downloadExcel = (data, fileName = "data.xlsx", type) => {
        if (!data || data.length === 0) {
            console.warn("No data available to download");
            return;
        }

        // Format data
        const formattedData = data.map((row) => {
            let res = {
                Plaza: row._id,
                Total: row.totalTraffic,
                Malfunction: row.malFuncReject,
            };
            // Add "Pass%" if type is not "blackList"
            if (type !== "blackList") {
                res["Rejected%"] =row.totalTraffic? ((( row.malFuncReject) / row.totalTraffic) * 100).toFixed(0):null;
            }
            return res;
        });

        // Convert to Excel (example with SheetJS - XLSX library)
        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, fileName);
    };


    useEffect(() => {
        let projection = { spaceName: 1, }
        if (dateRange[0] && dateRange[1]) {
            Promise.all([service.getThingNames(serviceId, projection)])
                .then((res) => {
                    let tempArray = [];
                    let spvPlazaMap = {};
                    let spvZonePlazaMap = {};
                    let plazaList = [{ name: "All", value: "All" }]

                    // Process the results to populate tempArray and maps
                    res[res.length - 1].forEach((item) => {
                        spvPlazaMap[item.spaceName] = item.SPV;
                        spvZonePlazaMap[item.spaceName] = item.Zone;

                        if (item.spaceName && item.spaceName !== "seasn_gnst_134567") {
                            plazaList.push({
                                name: item.spaceName,
                                value: item.spaceName
                            })
                            tempArray.push(item.spaceName);
                        }
                    });
                    if (tempArray.length && dateRange[0] && dateRange[1]) {
                        setPlazaList(plazaList)
                        getData(tempArray, dateRange);

                    }
                })
                .catch((error) => {
                    console.error("Error fetching thing names:", error);
                    // Handle error (optional)
                });
        }
    }, [dateRange, selectedPlaza])

    return (
        <div className='card col-12 grid align-items-center'>
            {/* <h2>{key}</h2> */}
            <GetFilters selectedPlaza={selectedPlaza} setSelectedPlaza={setSelectedPlaza} plazaList={plazaList} handleDateChange={handleDateChange} dateRange={dateRange} downloadExcel={downloadExcel} masterData={masterData} clearAll={clearAll} text={genUtilsMap[type]["key"]} />
            <DataTable value={masterData} responsiveLayout="scroll" showGridlines className='mt-2 mb-2 col-12'>
                <Column headerClassName="blue-bg" className="text-center" header="Plaza" field='_id'></Column>
                {type !== "blackList" && <Column headerClassName="blue-bg" className="text-center" header="Total Traffic" field='totalTraffic'></Column>}
                <Column headerClassName="blue-bg" className="text-center" header={`${type !== "blackList" ? "Malfunction Rejected" : "Black Listed"}`} field='malFuncReject'></Column>
                {type !== "blackList" && <Column headerClassName="blue-bg" className="text-center" header="Not Rejected" body={(row)=> {return row.totalTraffic -row.malFuncReject}}></Column>}
                {type !== "blackList" && <Column headerClassName="blue-bg" className="text-center" header="Rejected%" body={(row) => { return row["totalTraffic"]? (((row["malFuncReject"]) / row["totalTraffic"]) * 100).toFixed(0)+"%":0 }}></Column>}
            </DataTable>
        </div>
    )
}

export default GeneralBlackListReport