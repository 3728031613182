import React, { useEffect, useState } from 'react'
import { plazaServices } from './tollPlaza/plazaReportService'
import { TabView } from 'primereact/tabview'
import { TabPanel } from 'primereact/tabview'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Row } from 'primereact/row'
import { ColumnGroup } from 'primereact/columngroup'
import moment from 'moment'

const B2bIngestion = () => {
    const serviceId = localStorage.getItem('serviceId')
    const [today, setToday] = useState([])
    const [period, setPeriod] = useState([])
    const [yest, setYest] = useState([])
    const [todayInfoMsg, setTodayInfoMsg] = useState(null)
    const [yesterdayInfoMsg, setYesterdayMsg] = useState(null)
    const [periodInfoMsg, setPeriodInfoMsg] = useState(null)

    const makePayload = (period) => {
        let query = {
            operation: "aggregate",
            aggregate: [
                {
                    $group: {
                        _id: "$fdPartnerName", // Null groups everything together
                        ReceivedCount: { $sum: "$ReceivedCount" },
                        SI_AlfrescoCount: { $sum: "$SI_AlfrescoCount" },
                        "SI_RWCount": { $sum: "$SI_RWCount" },
                        "VF_MetadataCount": { $sum: "$VF_MetadataCount" },
                        "VF_SwitchCount": { $sum: "$VF_SwitchCount" },
                    }
                },
                {
                    $sort: {
                        "_id.date": 1
                    }
                }
            ]
        }
        if (period === "today") query.aggregate = [{
            $match: {
                date: moment().format("YYYY-MM-DD")
            }
        }, ...query.aggregate]
        if (period === "yest") query.aggregate = [{
            $match: {
                date: moment().subtract(1, "days").format("YYYY-MM-DD")
            }
        }, ...query.aggregate]
        return query;
    }
    const customHeader = (
        <ColumnGroup>
            <Row >
                <Column headerClassName="blue-bg" header="" className="text-center"></Column>
                <Column headerClassName="blue-bg" header="" className="text-center"></Column>
                <Column headerClassName="blue-bg" header="Validation Failure" colSpan={2} className="text-center"></Column>
                <Column style={{ backgroundColor: "#80808059" }} colSpan={2} header="Successfully Ingested" className="text-center"></Column>
            </Row>
            <Row>
                <Column headerClassName="blue-bg" header="" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Recived" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Metadata" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Switch" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="RW" className="text-center" ></Column>
                <Column headerClassName="blue-bg" header="Alfresco" className="text-center" ></Column>
            </Row>
        </ColumnGroup>
    )
    const TableView = (data) => {
        return (
            <div>
                <DataTable value={data} responsiveLayout="scroll" paginator={true} rows={15

                } showGridlines headerColumnGroup={customHeader}>
                    <Column headerClassName="blue-bg" className="text-center" field='_id'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='ReceivedCount'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='VF_MetadataCount'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='VF_SwitchCount'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='SI_RWCount'></Column>
                    <Column headerClassName="blue-bg" className="text-center" field='SI_AlfrescoCount'></Column>
                </DataTable>
            </div>
        )
    }
    useEffect(() => {
        Promise.all([
            plazaServices.general(serviceId, makePayload("today"), "nq-dashboard-b2b-content-ingestion-by-partner"),
            plazaServices.general(serviceId, makePayload("yest"), "nq-dashboard-b2b-content-ingestion-by-partner"),
            plazaServices.general(serviceId, makePayload("meta"), "nq-dashboard-b2b-content-ingestion-by-partner-period-meta")
        ]).then((res) => {
            function aggregateCounts(arr) {
                const publishers = ["Springer Nature India Private Limited", "CPI", "CUP", "Cambridge University Press"];
                let resultArr = [];
                let result = {
                    "_id": "Others",
                    ReceivedCount: 0,
                    SI_AlfrescoCount: 0,
                    SI_RWCount: 0,
                    VF_MetadataCount: 0,
                    VF_SwitchCount: 0,
                };

                for (let i = 0; i < arr.length; i++) {
                    if (!publishers.includes(arr[i]["_id"])) { // Fix: Check `publisher` field
                        result["ReceivedCount"] += arr[i]["ReceivedCount"] || 0;
                        result["SI_AlfrescoCount"] += arr[i]["SI_AlfrescoCount"] || 0;
                        result["SI_RWCount"] += arr[i]["SI_RWCount"] || 0;
                        result["VF_MetadataCount"] += arr[i]["VF_MetadataCount"] || 0;
                        result["VF_SwitchCount"] += arr[i]["VF_SwitchCount"] || 0;
                    } else {
                        resultArr.push(arr[i]);
                    }
                }

                if (Object.values(result).some(value => value > 0)) {
                    resultArr.push(result);
                }

                return resultArr;
            }

            let processedRes = res.map((item) => {
                return item && item.length ? aggregateCounts(item) : [];
            });

            if (processedRes[0]) setToday(processedRes[0]);
            else setTodayInfoMsg("No Data Avalaible")
            if (processedRes[1]) setYest(processedRes[1]);
            else setYesterdayMsg("No Data Avalaible")
            if (processedRes[2]) setPeriod(processedRes[2]);
            else setPeriodInfoMsg("No data Avalaible")
        });
    }, []);

    return (
        <div>
            <TabView >
                <TabPanel header="For The Day">
                    {
                        today.length ? TableView(today) : todayInfoMsg ?
                            <div className="my-info-message">{todayInfoMsg}</div> : null
                    }
                </TabPanel>
                <TabPanel header="For The Period">
                    {period ?
                        <>
                            {/* <div className="displayCard bg-white mb-2">
                                {"From:  " + lastPeriod.from + " To : " + lastPeriod.to}
                            </div> */}

                        </> : null}
                    {period.length ? TableView(period) : periodInfoMsg ? <div className="my-info-message">{periodInfoMsg}</div> : null}
                </TabPanel>
                <TabPanel header="Previous Day">
                    {
                        yest.length ? TableView(yest) : yesterdayInfoMsg ?
                            <div className="my-info-message">{yesterdayInfoMsg}</div> : null
                    }
                </TabPanel>
            </TabView>
        </div>
    )
}

export default B2bIngestion