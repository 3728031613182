import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import moment from 'moment'
import { plazaServices } from './plazaReportService'
import { Calendar } from 'primereact/calendar';

const ExemptionReports = ({ heading,plazaCode, selectedPlaza}) => {
    const [masterData, setMasterData] = useState([])
    const [totals, setTotals] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date(moment().subtract(1, "days")))
    const rowStyle = { border: "1px solid black", padding: "10px" }
    const serviceId = localStorage.getItem("serviceId")
    const handleDateChange = async (e) => {
        setSelectedDate(e.target.value)
    };
    let getReports = async (plazaCodes, SelectedDate) => {
        const makePayload = (type, project, match, startDate, endDate, yearsAgo = 0) => {
            return {
                operation: "aggregate",
                aggregate: [
                    {
                        $match: {
                            plazacode: selectedPlaza ?plazaCodes:{ $in: plazaCodes },
                            date: {
                                "$gte": startDate,
                                "$lte": endDate,
                            },
                            ...match
                        },
                    },
                    {
                        $group: {
                            _id: {
                                plaza: "$plazacode",
                                traffic: "$trafficType",
                                exemptType:"$exemptedCategory"
                            },
                            "traffic": { $sum: `$count` },
    
                        },
                    },
                    {
                        $project: {
                            "traffic": 1,
                            _id: 1,
                        },
                    },
                    {
                        $sort: {
                            "plazaCode": -1,
                        }
                    }
                ],
            };
        }
        setMasterData([])
        setTotals([])
        const yesterday = makePayload("count", "yesterdayTraffic", {}, SelectedDate, SelectedDate)
        const preYesterday = makePayload("count", "yesterdayTraffic", {}, moment(SelectedDate).subtract(1, "days").format("YYYY-MM-DD"), moment(SelectedDate).subtract(1, "days").format("YYYY-MM-DD"))

        const getPlazaDetailsPayload = {
            filter: {
                PlazaCode: selectedPlaza ?plazaCodes:{ $in: plazaCodes },
            },
            projection: {
                "Zone": 1,
                "SPV": 1,
                "PlazaCode": 1,
                "PlazaName": 1,
            }
        }
        let promiseArray = [plazaServices.general(serviceId, yesterday, "nq-total_traffic_daily"), plazaServices.general(serviceId, preYesterday, "nq-total_traffic_daily")]
        promiseArray.push(plazaServices.general(serviceId, getPlazaDetailsPayload, "nq-plaza-master"))
        Promise.all(promiseArray).then((res) => {
            let spvPlazaMap = {};
            let spvZonePlazaMap = {};
            res[res.length - 1].map((item) => {
                spvPlazaMap[item.PlazaCode] = item.SPV;
                spvZonePlazaMap[item.PlazaCode] = item.Zone
            })
            let arr = [];
            for (let i = 0; i < res.length - 1; i++) {
                const spvMaster = {};
                let plTrafficMap = {};
                res[i].length &&
                    res[i].forEach((item) => {
                        const key = `${item._id?.plaza}`;
                        const trafficType = item._id?.traffic;
                        const notPaid = item._id?.exemptType==="Not Paid"?true:false
                        const trafficCount = item.traffic;

                        if (!plTrafficMap[key]) {
                            // Initialize new plaza entry
                            plTrafficMap[key] = {
                                [trafficType]:notPaid? trafficCount:0,
                                plaza: item._id?.plaza,
                                total: trafficCount,
                                date: item._id?.date,
                                type: trafficType,
                            };
                        } else {
                            plTrafficMap[key] = {
                                ...plTrafficMap[key],
                                [trafficType]:notPaid? plTrafficMap[key][trafficType]+trafficCount:plTrafficMap[key][trafficType],
                            };
                            plTrafficMap[key]["total"] += trafficCount;
                        }
                    });
                for (const key in plTrafficMap) {
                    const plaza = plTrafficMap[key].plaza;
                    const mappedPlaza = spvPlazaMap[plaza];
                    const EXEMPT = plTrafficMap[key].exempt || 0;

                    if (!spvMaster[mappedPlaza]) {
                        // Initialize SPV entry
                        spvMaster[mappedPlaza] = {
                            ...plTrafficMap[key],
                            zone: spvZonePlazaMap[plaza] || null,
                        };
                    } else {
                        // Update SPV entry
                        const obj = spvMaster[mappedPlaza];
                        obj.total = (obj.total || 0) + plTrafficMap[key].total;
                        obj.exempt = (obj.exempt || 0) + EXEMPT;
                    }
                }
                for (const key in spvMaster) {
                    const total = spvMaster[key].total || 0;
                    const exempt = spvMaster[key].exempt || 0;
                    spvMaster[key].percent = total ? (exempt / total) * 100 : 0;
                }
                arr.push({ ...spvMaster });
            }

            let master = [];
            let middle = {}
            arr.map((item) => {
                for (const key in item) {
                    const obj = item[key];
                    let temp = [];
                    if (!middle[key]) temp.push(obj.zone);
                    if (!middle[key]) temp.push(key)
                    temp.push(obj.total)
                    temp.push(obj.exempt)
                    temp.push(obj.percent)
                    if (middle[key]) master.push([...middle[key], ...temp]);
                    else middle[key] = temp
                }
            })
            let Totals = [0, 0, 0, 0, 0, 0]
            master.map((item) => {
                if (item[2]) Totals[0] += item[2]
                if (item[3]) Totals[1] += item[3]
                if (item[5]) Totals[3] += item[5]
                if (item[6]) Totals[4] += item[6]
            })
            Totals[2] = (Totals[1] / Totals[0]) * 100;
            Totals[5] = (Totals[4] / Totals[3]) * 100;
            setTotals(Totals)
            setMasterData(master)
        })
    }
    const downloadCSV = () => {
        const headers = [
            ["Zone", "Project", `${moment(selectedDate).format('Do MMMM YY')}`, "", "", `${moment(selectedDate).format('Do MMMM YY')}`, "", ""],
            ["", "", "Total Traffic", "Exempt+Not Paid", "", "Total Traffic", "Exempt+Not Paid", ""],
            ["", "", "", "Traffic Count", "%", "", "Traffic Count", "%"],
        ];
        const rows = headers.map(header => header.join(","));
        if (masterData && Array.isArray(masterData)) {
            masterData.forEach(row => {
                const rowData = row.map(item => {
                    if (typeof item === "string") return item;
                    return Number.isInteger(item)  ? item :item ? item.toFixed(2):item;
                });
                rows.push(rowData.join(","));
            });
        }
        if (totals && Array.isArray(totals)) {
            const totalRow = ["", "Total", ...totals.map(item => Math.round(item))];
            rows.push(totalRow.join(","));
        }
        const csvContent = rows.join("\n");
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `table_data_${moment().format('YYYYMMDD')}.csv`);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        setMasterData([])
        setTotals([])
        if(selectedPlaza )getReports(selectedPlaza, moment(selectedDate).format("YYYY-MM-DD"))
            else getReports(plazaCode, moment(selectedDate).format("YYYY-MM-DD"))

    }, [plazaCode,selectedPlaza,selectedDate])
    return (
        <div>
            <div style={{ overflowX: "auto" }} className='card generalCard'>
                <div className=" col-12">
                    <div className=' col-12   m-0' style={{ width: "100%" }}>
                        <div className='pt-2 bg-white  grid col-9 align-items-center ' >
                            <h3 className="chartHeading">{heading} </h3>
                        </div>
                        <div className="grid align-items-center p-2">
                            <label className=" relabel">Timeline:</label>
                            <Calendar
                                id="range"
                                value={selectedDate}
                                maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                placeholder="Select Date"
                                onChange={handleDateChange}
                                showIcon
                                readOnlyInput
                                className="p-calendar flex align-items-center bg-white"
                                style={{
                                    borderRadius: '17px',
                                    border: '2px solid #0C6291',

                                }}
                                dateFormat="dd-mm-yy"
                            />
                            <Button style={{ backgroundColor: "white", color: "#2A9D8F", border: "1px solid #2A9D8F", fontStyle: "Inter" }} className="ml-3 lg:mt-0 mt-2" onClick={downloadCSV}>Download</Button>
                        </div>
                    </div>
                </div>
                <table style={{ width: "100%", borderCollapse: "collapse", textAlign: "center" }}>
                    <thead style={{ backgroundColor: "rgb(0,111,192)", color: "white" }}>
                        <tr style={{ fontWeight: "bold" }}>
                            <th rowSpan="3" style={rowStyle}>Zone</th>
                            <th rowSpan="3" style={rowStyle}>Project</th>
                            <th colSpan="3" style={rowStyle}>{moment(selectedDate).format('Do MMMM YY')}</th>
                            <th colSpan="3" style={rowStyle}>{moment(selectedDate).subtract(1, 'days').format('Do MMMM YY')}</th>

                        </tr>
                        <tr style={{ fontWeight: "bold" }}>
                            <th rowSpan="2" style={rowStyle}>Total Traffic</th>
                            <th colSpan="2" style={rowStyle}>Exempt+Not Paid</th>
                            <th rowSpan="2" style={rowStyle}>Total Traffic</th>
                            <th colSpan="2" style={rowStyle}>Exempt+Not Paid</th>
                        </tr>
                        <tr style={{ fontWeight: "bold" }}>
                            <th style={rowStyle}>Traffic Count</th>
                            <th style={rowStyle}>%</th>
                            <th style={rowStyle}>Traffic Count</th>
                            <th style={rowStyle}>%</th>
                        </tr>
                    </thead>
                    <tbody>
                        {masterData && Array.isArray(masterData) && masterData.map((Item, index) => (
                            <tr>
                                {
                                    Item.map((item, index) => {
                                        return <td  style={{ ...rowStyle, textAlign: `${typeof (item) !== "string" ? "right" : "left"}`, fontWeight: `${typeof (item) !== "string" ? 500 : 800}`, fontStyle: "Inter" }}>{item ? Number.isInteger(item) ? `${item}` : typeof (item) !== "string" ? `${item && item.toFixed(2)}` : item : "0"}</td>
                                    })
                                }
                            </tr>
                        ))}
                        <tr style={{ backgroundColor: "rgb(0,111,192)", color: "white" }}>
                            <td colSpan={2} style={rowStyle}>Total</td>
                            {
                                totals && Array.isArray(totals) && totals.map((item, index) => {
                                    return <td  style={{...rowStyle,textAlign:"right"}}>{item && !isNaN(item) ? Math.round(item) : 0}</td>
                                })
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ExemptionReports