import React, { useEffect, useRef, useState } from 'react'
import service from '../../service/apiService'
import { Divider } from 'primereact/divider'
import imagineHomeService from "./imagineHomeService"
import DesignerHome from './DesignerHome'
import ShowSurvey from './ShowSurvey'

/*
handle how urls will behave
how image file url will behave in survey response 
how urls will behave
how to show images in survey response
*/
const ProjectDetail = ({designer="", customerId = "", projectName = "", toShow = "survey", back }) => {
    const serviceId = localStorage.getItem("serviceId")
    const [projectDetail, setProjectDetail] = useState({})
    const [survey, setSurvey] = useState([])
    const email = !designer ? localStorage.getItem("emailId") : designer
    const downloadUrlMap = useRef({})
    useEffect(() => {
        const projectDetailPayload = {
            filter: { "designerID": email, "customerID": customerId, "ProjectName": projectName }
        }
        const surveyPayload = {
            filter: { "email": customerId },
            sort: [["_id", -1]],
            limit: 1
        }
        const promises = [service.httpDBService(projectDetailPayload, serviceId, "nq-customer-project-designer-mappings")]
        if (toShow === "survey") promises.push(service.httpDBService(surveyPayload, serviceId, "nq-customer-surveys"))
        // const formData = new FormData()
        // formData.append("serviceId", serviceId)
        // formData.append("customerID", customerId)
        // formData.append("ProjectName", projectName)
        // formData.append("isProjectActive", true)
        /* imagineHomeService.fetchProjectFiles(formData)*/

        Promise.all(promises).then(((res) => {
            let projectDetail = res[0]
            let surveyData = []
            if (toShow === "survey") surveyData = res[1]

            if (projectDetail && projectDetail.length) setProjectDetail(projectDetail[0])
            if (surveyData && surveyData.length) setSurvey(surveyData[0].data)

            // if (projectFiles && projectFiles.files && projectFiles.files.length > 0) {
            //     const roomTypeWiseFiles = roomWiseFilterFiles(projectFiles.files)
            //     setRoomFiles(roomTypeWiseFiles)
            //     setSelectedRoom(roomTypeWiseFiles[0])
            // }
        })).catch(err => {
            console.error(err)
        })
    }, [])

    // const roomWiseFilterFiles = (files) => {
    //     let roomTypeWiseFilesMap = {}
    //     let roomTypeLevel = 2
    //     let fileLevel = 6
    //     files.forEach((file) => {
    //         let splittedFile = file.split("/")
    //         let roomType = splittedFile[roomTypeLevel]

    //         if (roomType in roomTypeWiseFilesMap) {
    //             roomTypeWiseFilesMap[roomType]["files"].push({
    //                 fileName: splittedFile[fileLevel],
    //                 filekey: file,
    //             })
    //         }
    //         else {
    //             roomTypeWiseFilesMap[roomType] = {
    //                 roomType: roomType,
    //                 files: [{
    //                     fileName: splittedFile[fileLevel],
    //                     filekey: file,
    //                 }]
    //             }
    //         }
    //     })
    //     return Object.values(roomTypeWiseFilesMap)
    // }

    const handledown_preview = async (e) => {

        const fileKey = e.currentTarget.id
        let downloadurl = ""
        if (fileKey) {
            if (!(fileKey in downloadUrlMap.current)) {
                const formData = new FormData()
                formData.append("serviceId", serviceId)
                formData.append("fileKey", fileKey)

                const downloadURLRES = await imagineHomeService.fetchDownloadFileUrl(formData)
                downloadurl = downloadURLRES?.download_url ?? ""
            }
            else downloadurl = downloadUrlMap.current[fileKey]

            window.open(downloadurl, "_blank")
        }
    }
    return (
        <div style={{ color: "#1D1B1C" }}>
            <div className='flex align-items-center' onClick={() => back()} style={{ gap: 10, fontFamily: "Larken" }}>
                <img src='/images/Back.svg' className='cursor-pointer' />
                <h2 className='m-0 font-light'>Project Details</h2>
            </div>
            <Divider style={{ backgroundColor: "#9AAB89", height: "1px" }} />
            <div className='flex flex-wrap mt-3' style={{ gap: 15 }}>
                <div className='flex-grow-1'>
                    <h3 className='text-xl mb-2 font-larken'>Project Name</h3>
                    <p className='m-0 text-lg font-Gerstner-ProgramRegular'>{projectDetail.ProjectName}</p>
                </div>
                <div className='flex-grow-1'>
                    <h3 className='text-xl mb-2 font-larken'>Status</h3>
                    <p className='m-0 text-lg font-Gerstner-ProgramRegular' style={{ color: "#9AAB89" }}>{projectDetail.isProjectActive ? "Active" : "InActive"}</p>
                </div>
                <div className='flex-grow-1'>
                    <h3 className='text-xl mb-2 font-larken'>Client Name</h3>
                    <p className='m-0 text-lg font-Gerstner-ProgramRegular'>{projectDetail.customerID}</p>
                </div>
                <div className='flex-grow-1'>
                    <h3 className='text-xl mb-2 font-larken'>Created Date</h3>
                    <p className='m-0 text-lg font-Gerstner-ProgramRegular'>{projectDetail.created_at}</p>
                </div>
            </div>
            <div className='mt-4'>
                {toShow === "manage" ?
                    <>
                        <DesignerHome customerId={customerId} projectName={projectName} />
                    </> :
                    <>
                        <h3 className='font-larken charcoal-color text-2xl' style={{fontWeight: 100}}>Survey Details</h3>
                        <ShowSurvey survey={survey} />
                    </>}
            </div>
            <Divider style={{ backgroundColor: "#9AAB89", height: "1px" }} />
        </div>
    )
}

export default ProjectDetail