import { Route } from "react-router-dom";
import ThingsList from "./pages/waterQuality/wsThings";
import Calibration from "./pages/waterQuality/calibration";
import VehicleListComponent from "./pages/vehicleTraker/VehicleListComponent";
import FuelReport from "./pages/fuelMonitoring/FuelReport";
import WaterQuality from "./pages/waterQuality/WaterQuality";
import VehicleOBDList from "./pages/vehicleOBDMonitoring/VehicleOBDList";
import WindSpeedDirection from "./pages/windSpeedDirection/WindSpeedDirection";
import TiltMonitoring from "./pages/tiltMonitoring/TiltMonitoring";
import DgParametersTrend from "./pages/assetsMonitoring/DgParametersTrend";
import DgOverview from "./pages/assetsMonitoring/DgOverview";
import SiteDetails from "./pages/assetsMonitoring/SiteDetails";
import ServiceObservability from "./pages/assetsMonitoring/ServiceObservability";
import PortfolioAnalysis from "./pages/assetsMonitoring/PortfolioAnalysis";
import PerformanceDashboard from "./pages/assetsMonitoring/PerformanceDashboard";
import VehicleSummery from "./pages/vehicleOBDMonitoring/VehicleSummery";
import DeviceConfig from "./pages/deviceConfig/DeviceConfig";
import ThingList from "./pages/deviceConfig/thingList";
import VehListForPerformanceAndDaignostics from "./pages/vehicleOBDMonitoring/vehListForPerfAndDiagno";
import PerformanceAndDaignostics from "./pages/vehicleOBDMonitoring/perfAndDiagno";
import AssetLiveState from "./pages/assetsMonitoring/AssetLiveState";
import EmployeeList from "./pages/employeeTracking/EmployeeList";
import FuelConsumptionAnalytics from "./pages/fuelReports/FuelConsumptionAnalytics";
import FuelPortfolioAnalysis from "./pages/fuelMonitoring/FuelPortfolioAnalysis";
import FuelingEvents from "./pages/fuelMonitoring/FuelingEvents";
import ReportingEmployeesSummary from "./pages/employeeTracking/ReportingEmployeesSummary";
import DownloadFile from "./pages/DownloadFile";
import UploadFile from "./pages/UploadFile";
import Users from "./pages/User/Users";
import AllUsers from "./pages/userManagement/allUsers";
import EmployeeLiveLocation from "./pages/employeeTracking/EmployeeLiveLocation";
// import EmployeeSummery from "./pages/employeeTracking/EmployeeSummery";
import QuickSight from "./pages/quickSight/QuickSight";
import GeoFence from "./pages/employeeTracking/GeoFense";
import LocationStore from "./pages/employeeTracking/LocationStore";
import Support from "./pages/Support";
import AllVehicles from "./pages/vehicleOBDMonitoring/AllVehicles";
import SiteDashboard from "./pages/siteManagement/dashboard";
import UploadSites from "./pages/siteManagement/uploadSites";
import SiteReport from "./pages/siteManagement/sitereport";
import VFVehicleUsage from "./pages/vehTrackingWithFuel/vehFuelUsage";
import VehFuelAnalytics from "./pages/VehicleWithFuelObd/VehFuelAnalytics";
import VehFuelUsage from "./pages/VehicleWithFuelObd/VehFuelUsage";
import FuelTrackingUsage from "./pages/fuelReports/FuelUsage";
import NoiseDashbord from "./pages/NoisMonitoring/NoiseDashbord";
import CalibrationDataUpload from "./pages/Common/CalibrationDataUpload";
import Marina from "./pages/marina";
import ChangeUserPassword from "./pages/changeUserPassword";
import LcixDashboard from "./pages/lcixDashboard/LcixDashboard";
import PortfolioComponent from "./pages/Towers/PortfolioComponent";
import TowerTransitionComponent from "./pages/Towers/TowerTransitionComponent";
import TenancyComponent from "./pages/Towers/TenancyComponent";
import TowersComponent from "./pages/Towers/TowersCompoment";
import TowerInsightComponent from "./pages/Towers/TowerInsightComponent";
import RecaptureForecast from "./pages/Towers/recaptureForecast";
import MenuSiteComponent from "./pages/Towers/MenuSiteComponent";
import TowerSummeryComponent from "./pages/Towers/TowerSummeryComponent";
import FaultReportComponent from "./pages/Towers/FaultReportComponent";
import SwaggerUIComponent from "./pages/Towers/swaggerUi";
import MenuSiteProgressComponent from "./pages/Towers/MenuSiteProgressComponent";
import InsightReportComponent from "./pages/Towers/CandedReports/InsiteReportComponent";
import SiteOverView from "./pages/Towers/siteOverview";
import EmployeeDashboard from "./pages/employeeTracking/EmployeeDashboard";
import CapturePhoto from "./components/CapturePhoto";
import FileShare from "./pages/fileshare/FileShare";
import MyEvents from "./pages/fileshare/MyEvents";
import ManageProject from "./pages/fileshare/ManageProject";
import FileDashboard from "./pages/fileshare/FileDashboard";
import AirQuality from "./pages/airQuality/AirQuality";
import SiteMonitoring from "./pages/airQuality/SiteMonitoring";
import BigRockMountainDashboard from "./pages/bigRockMountain/dashboard";
import Report from "./pages/airQuality/Report";
import ReproUpload from "./pages/repro/ReproUpload";
import VisaApi from "./pages/visa/VisaApi";
import VTFreport from "./pages/vehTrackingWithFuel/report";
import WeatherDataDashBooard from "./pages/HistoricWeatherData/dashboard";
import WeatherTrends from "./pages/HistoricWeatherData/weatherTrends";
import FuelSessionReport from "./pages/fuelMonitoring/components/FuelReport";
import LongTermReport from "./pages/vehTrackingWithFuel/LongTermReport";
import { Procedures } from "./pages/quickSight/Procedures";
import WeatherForecast from "./pages/siteManagement/weatherForecast";
import PlazaFileUpload from "./pages/tollPlaza/PlazaFileUpload";
import PlazaDashBoard from "./pages/tollPlaza/plazaDashboard";
import { DailyReport } from './pages/tollPlaza/dailyReport';
import { Monthly } from './pages/tollPlaza/monthly';
import { BiMonthly } from './pages/tollPlaza/bimonthly';
import { SixMonthly } from './pages/tollPlaza/sixMonthly';
import { FilesRecivedInfo } from "./pages/tollPlaza/FilesRecivedInfo";
import Orders from "./pages/Walmart/Orders";
import OrderLineDetails from "./pages/Walmart/orderLineDetails";
import { TittleListingStatus } from "./pages/Walmart/TittleListing";
import { Dashboard } from "./pages/Walmart/Dashboard";
import WalmartUpload from './pages/Walmart/walmartUpload';
import { LabelPrinting } from "./pages/Walmart/labelPrinting";
import IndoorMonitor from "./pages/indoorMonitoring/IndoorMonitor";
import IndoorReport from "./pages/indoorMonitoring/IndoorReport";
import BatteriesLiveStatus from './pages/batteries/liveState';
import BatteriesChargeStatus from './pages/batteries/chargeStatus';
import { Charging} from "./pages/charging";
import { ChargeTrends } from "./pages/ChargeTrends";
import RerouteIHUser from "./pages/imagineHome/RerouteIHUser";
import { VehicleSummary } from "./pages/newVehTrackingWithFuel/VehicleSummary";
import { UserGroup } from "./pages/fileSharing/UserGroup";
import ProjectList from "./pages/fileSharing/ProjectList";
import HelpAndSupport from "./pages/HelpAndSupport";
import WeeklyReports from "./pages/tollPlaza/WeeklyReports";
import Listing from "./pages/repro/listing";
import B2COrders from "./pages/repro/b2cOrders";
import ContentIngestion from "./pages/repro/ContentIngestion";
import PlanDetails from "./pages/planDetails";
import FeatureDetails from "./pages/FeatureDetails";
import EditPlanImgHome from "./pages/EditPlanImgHome";
import PlanPurchase from "./pages/PlanPurchase";
import DesignerDetails from "./pages/DesignerDetails";
import DesignerPlan from "./pages/DesignerPlan";
import PaymentOptionsScreen from "./pages/PaymentOptionsScreen";

import PurchaseInvoice from "./pages/PurchaseInvoice";
import ListingContentIngestion from "./pages/repro/listing_content";
import ZohoDashboard from "./pages/quickSight/zohoDashboard";
import DesinerStatusPage from "./pages/DesinerStatusPage";
import B2bIngestion from "./pages/B2bIngestion";
import AccuracyReports from "./pages/tollPlaza/accuracyReports";
import ActionIndicator from "./pages/ActionIndicator";
const AuthorizedRoutes = () => {
    return (
        <>
            <Route path="/wqThings" component={ThingsList} />
            <Route path="/calibration" component={Calibration} />
            <Route path="/device-config" component={DeviceConfig} />
            <Route path="/allThings" component={ThingList} />
            <Route path="/dashboard" render={() => <VehicleListComponent />} />
            <Route path="/detailed-report" render={() => <AllVehicles />} />
            <Route path="/vehicle-traking" render={() => <VehicleListComponent />} />
            <Route path="/vehicle-tracking-obd" render={() => <VehicleOBDList />} />
            <Route path="/vehicle-summary" render={() => <VehicleSummery />} />
            <Route path="/fuel-monitoring" component={FuelReport} />
            <Route path="/water-quality-dashboard" render={() => <WaterQuality />} />
            <Route path="/wind-speed-direction" render={() => <WindSpeedDirection />} />
            <Route path="/tower-tilt-monitoring" render={() => <TiltMonitoring />} />
            <Route path="/dg-map-view" exact component={DgOverview} />
            <Route path="/site-details/:thingName" component={SiteDetails} />
            <Route path="/portfolio-analysis" component={PortfolioAnalysis} />
            <Route path="/performance-dashboard" component={PerformanceDashboard} />
            <Route path="/dg-parameters-trend" component={DgParametersTrend} />
            <Route path="/service-observability" component={ServiceObservability} />
            <Route path="/obdperformance" component={VehListForPerformanceAndDaignostics} />
            <Route path="/performance" component={PerformanceAndDaignostics} />
            <Route path="/asset-live-state" component={AssetLiveState} />
            <Route path="/employee-list" component={EmployeeList} />
            <Route path="/fuel-usage" component={FuelTrackingUsage} />
            <Route path="/fuel-consumption" component={FuelConsumptionAnalytics} />
            <Route path="/fuel-portfolio-analytics" component={FuelPortfolioAnalysis} />
            <Route path="/fueling-events" component={FuelingEvents} />
            <Route path="/reporting-employees-summary" component={ReportingEmployeesSummary} />
            <Route path="/manage-users" component={AllUsers} />
            <Route path="/download-file" component={DownloadFile} />
            <Route path="/upload-file" component={UploadFile} />
            <Route path="/users" component={Users} />
            <Route path="/employee-Live-Location" component={EmployeeLiveLocation} />
            {/* <Route path="/employee-summery" component={EmployeeSummery} /> */}
            <Route path="/employee-summery" component={EmployeeDashboard} />
            <Route path="/repro-dashboard" component={QuickSight} />
            <Route path="/quicksight-dashboard" component={QuickSight} />
            <Route path="/geo-fence" component={GeoFence} />
            <Route path="/location-store" component={LocationStore} />
            <Route path="/support" component={Support} />
            <Route path="/siteDashboard" component={SiteDashboard} />
            <Route path="/uploadSites" component={UploadSites} />
            <Route path="/site-report" component={SiteReport} />
            <Route path="/vehFuelUsage" component={VFVehicleUsage} />
            <Route path="/vehFuelSummary" component={VehicleSummary} />
            <Route path="/vehFuelAnalytics" component={VehFuelAnalytics} />
            <Route path="/vehicle-fuel-usage" component={VehFuelUsage} />
            <Route path="/noise-report" component={NoiseDashbord} />
            <Route path="/calibration-upload" component={CalibrationDataUpload} />
            <Route path="/marina" component={Marina} />
            <Route path="/change-user-password" component={ChangeUserPassword} />
            <Route path="/lcix-dashboard" component={LcixDashboard} />
            <Route path="/portfolioanalysis" component={PortfolioComponent} />
            <Route path="/tower-transition" component={TowerTransitionComponent} />
            <Route path="/tenancy" component={TenancyComponent} />
            <Route exact path="/towers" component={TowersComponent} />
            <Route exact path="/towerinsight" component={TowerInsightComponent} />
            <Route exact path="/recaptureForecast" component={RecaptureForecast} />
            <Route path="/towers-menu/:towerid/:lat/:lng" component={MenuSiteComponent} />
            <Route path="/towers-progress-menu/:towerid/:region/:invcode" component={MenuSiteProgressComponent} />
            <Route path="/tower-report/:state/:condition" component={TowerSummeryComponent} />
            <Route path="/faultreports" component={FaultReportComponent} />
            <Route path="/swaggerui" component={SwaggerUIComponent} />
            <Route exact path="/tower-report/:condition" component={InsightReportComponent} />
            <Route path="/siteOverview" component={SiteOverView} />
            <Route path="/site-image-capture" component={CapturePhoto} />
            <Route path="/air-quality" component={AirQuality} />
            <Route path="/site-monitoring" component={SiteMonitoring} />
            <Route path="/filesharing" component={FileShare} />
            <Route path="/filesharing-dashboard" component={FileDashboard} />
            <Route path="/my-events" component={MyEvents} />
            <Route path="/manage-projects" component={ManageProject} />
            <Route path="/temp-dashboard" component={BigRockMountainDashboard}/>
            <Route path="/sites-report" component={Report} />
            <Route path="/repro-upload" component={ReproUpload} />
            <Route path="/visa-dashboard" component={VisaApi} />
            <Route path="/statistics-report" component={VTFreport} />
            <Route path="/weather-trends" component={WeatherTrends} />
            <Route path="/Historic-Weather-Data" component={WeatherDataDashBooard} />
            <Route path="/fuel-report" component={FuelSessionReport} />
            <Route path="/long-term-report" component={LongTermReport} />
            <Route path="/Procedures-and-Manual" component={Procedures} />
            <Route path="/weather-forecast" component={WeatherForecast} />
            <Route path="/toll-upload-file" component={PlazaFileUpload} />
            <Route path="/toll-reports" component={PlazaDashBoard} />
            <Route path="/toll-daily-reports" component={DailyReport} />
            <Route path="/toll-monthly-reports" component={Monthly} />
            <Route path="/toll-bimonthly-reports" component={BiMonthly} />
            <Route path="/toll-quaterly-reports" component={SixMonthly} />
            <Route path="/files-status" component={FilesRecivedInfo} />     
            <Route path="/walmart-tittle" component={TittleListingStatus} />
            <Route path="/walmart-dashboard" component={Dashboard} />       
            <Route path="/wm-orders" component={Orders} />
            <Route path="/wm-upload" component={WalmartUpload} />
            <Route path="/order-details" component={OrderLineDetails} /> 
            <Route path="/repro" component={LabelPrinting} />
            <Route path= "/indoor-monitoring" component={IndoorMonitor}/> 
            <Route path= "/indoor-report" component={IndoorReport}/>
            <Route path= "/batteries-live-status" component={BatteriesLiveStatus}/>
            <Route path= "/batteries-charge-status" component={BatteriesChargeStatus}/>
            <Route path="/ih-user" component={RerouteIHUser} />      
            <Route path= "/sv-charging" component={Charging}/>
            <Route path= "/sv-trends" component={ChargeTrends}/>     
            <Route path= "/sv-trends" component={ChargeTrends}/>
            <Route path= "/list-projects" component={ProjectList}/>
            <Route path= "/user-group" component={UserGroup}/>
            <Route path= "/help" component={HelpAndSupport}/>          
            <Route path= "/reliance-weekly" component={WeeklyReports}/>
            <Route path= "/b2c-listing" component={Listing}/>   
            <Route path= "/b2c-orders" component={B2COrders}/>
            <Route path= "/b2c-content-ingestion" component={ContentIngestion}/>
            <Route path= "/feature-details" component={FeatureDetails}/>
            <Route path= "/plan-details" component={PlanDetails}/>
            <Route path= "/edit-plans" component={EditPlanImgHome}/>
            <Route path= "/plan-purchase" component={PlanPurchase}/>
            <Route path= "/designer-BenefitDetails" component={DesignerDetails}/>
            <Route path= "/designer-PlanDetails" component={DesignerPlan}/> 
            <Route path= "/purchase-planOptions" component={PaymentOptionsScreen}/>
            <Route
            path="/designerPlan-Purchase"
            component={()=>{return <PlanPurchase planPath="getDesignerPlans"  designerPlan={true}/>} }
            />
            <Route path= "/purchase-invoice" component={PurchaseInvoice}/>           
            <Route path= "/b2c-listing-content" component={ListingContentIngestion}/>  
            <Route path="/analytics" component={ZohoDashboard} />
            <Route path="/b2b-orders" component={()=>{return <B2COrders cr = {"nq-dashboard-b2b-orders-cr-by-channel"}
                received = { 'nq-dashboard-b2b-orders-by-channel'}
                shipped = { 'nq-dashboard-b2b-orders-shipped-by-channel'} b2b={true}/>}} />
            <Route path= "/b2b-listing-content" component={()=> {return <ListingContentIngestion b2b={true}/>}}/>
            <Route path="/designer-status" component={DesinerStatusPage} />
            <Route path= "/b2b-ingestion" component={B2bIngestion}/>
            <Route path= "/accuracy-report" component={AccuracyReports}/> 
            <Route path= "/action-indicator" component={ActionIndicator}/>    
        </>
    );
};

export default AuthorizedRoutes;