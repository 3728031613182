import React, { useEffect } from 'react'
import { Button } from 'primereact/button'
import { commonConfigCaller } from './FeatureDetails'
import { plazaServices } from './tollPlaza/plazaReportService'

const PurchaseInvoice = ({ plan, comeBackFunc,sessionCreatePath="create-slimcd-session",designerPlan=false ,payOption}) => {
    const serviceId = localStorage.getItem("serviceId")
    let sessionId = ""
    let arr = plan ? [{ key: "Plan Name", value: plan.planNm }, { key: "Discount", value: "" }, { key: "Tax", value: "" }] : []
    const getSeesionId = async () => {
        let reqObj = { "serviceId": serviceId, "planId": plan.planId ,paymentMode:payOption}
        if(designerPlan)reqObj["designerPlan"]=true
        let res = await commonConfigCaller(sessionCreatePath, "post",reqObj )
        return res?.data?.responseData?.sessionid
    }
    async function openSlimCDPaymentPage() {
        try {
            sessionId = await getSeesionId();
            window.location.href = `https://stats.slimcd.com/soft/showsession.asp?sessionid=${sessionId}`;
        } catch (error) {
            console.error("Error opening SlimCD Payment Page:", error);
        }
    }

    return (
        <div className=' w-full'>
            <Button style={{ background: "#FBF9F3", color: "#1D1B1C" }} icon="pi pi-arrow-left col-12" onClick={() => comeBackFunc(false)} />
            <h3 className="col-12 planPurchaseHeading mt-0 mb-0" style={{ marginBottom: "2px", color: "#1D1B1C",fontSize:"2rem" }}>Select Plans</h3>
            {/* <div className='col-12 lg:col-12 invoice-details mt-0'>All Pro feature + 3X room project + Multiple project + Delivery status concierge + Fit guaruntee </div> */}
            <p className='m-0 purchasePlanSubHeading col-12' style={{ fontSize: "1.1rem" }}>Order Summary</p>
            {
                arr.map((item) => {
                    return <div className='col-12 grid justify-content-between align-items-center invoice-details' style={{ fontSize: "1.1rem" }}>
                        <p className='m-0 col-6'>{item.key}</p>
                        <p className='m-0 col-6 inline-flex justify-content-end'>{item.value}</p>
                    </div>
                })
            }
            <hr className="col-12" style={{ color: "#BFAD5B", height: "1px" }}></hr>
            <div className='col-12 grid justify-content-between align-items-center purchasePlanSubHeading col-12' style={{ fontSize: "14px" }}>
                <p className='m-0 col-6'>Total</p>
                <p className='m-0 col-6 inline-flex justify-content-end'>{plan.charge}</p>
            </div>
            <div className='col-12 grid justify-content-between align-items-center purchasePlanSubHeading col-12 mt-6' style={{ fontSize: "14px" }}>
                <div className='m-0 col-3 grid align-items-center purchasePlanSubHeading'>
                    <p className='m-0 '>Sub Total <span style={{ fontSize: "18px", lineHeight: "26px" }} className='m-0 p-0'>${plan.charge}</span></p>
                    {/* <p className='m-0 lg:ml-4 ml-0' style={{ fontSize: "18px", lineHeight: "26px" }}> $ {plan.charge}</p> */}
                </div>
                <Button className='addFeatureButton addFeatureButtonFont col-8 lg:col-2' label='Proceed to Pay' style={{ backgroundColor: "#47422F", color: "white" }} onClick={openSlimCDPaymentPage} />
            </div>
        </div>
    )
}

export default PurchaseInvoice