import React, { useEffect, useState, useRef } from 'react'
import Upload from './Upload'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import service from '../service/apiService'
import { httpConstants } from '../lib/constants'
import { dateFormatter } from '../utilities/utillFunction'
import { Toast } from 'primereact/toast'

const BulkUserCreate = ({ setOpenBulkUserCreate }) => {

    const [file, setFile] = useState()
    const [data, setData] = useState([])
    const serviceId = localStorage.getItem("serviceId")
    const uid = localStorage.getItem('uid')
    const toast = useRef()

    useEffect(() => {
        fetchUploadData()
    }, [])
    
    const showDownload = (row) => {
        if (!row.errorFile) return;
        function convertS3UriToHttpsUrl() {
            const httpsUrl = `${process.env.REACT_APP_ERROR_FILE_URL}/${row.bucketname}/${row.errorFile}`;
            return httpsUrl;
        }

        const Download = () => {
            let url = convertS3UriToHttpsUrl();
            var element = document.createElement("a");
            element.setAttribute("href", url);
            element.setAttribute("download", "report" + Date.now() + ".csv");
            element.style.display = "none";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
        return <>
            <Button className=" text-white white-space-nowrap" style={{ backgroundColor: `#0C6291` }} onClick={() => { Download() }} label="Get Error File">
                <i className={"pi pi-download ml-2"} style={{ color: '#FFFFFF' }}></i>
            </Button>
        </>
    }

    const handleChange = (e) => {
        const file = e.target.files[0]
        setFile(file)
    }

    const fetchUploadData = () => {
        const payload = {
            sort: [["_id", -1]]
        }
        service.httpDBService(payload, serviceId, "nq-designer-file-uploads").then((res) => {
            setData(res)
        })
    }

    const handleFileUpload = () => {
        const formData = new FormData()
        formData.append("serviceId", serviceId)
        formData.append("file", file)
        formData.append("uid", uid)

        const url = process.env.REACT_APP_TENANT_SERVICE_URL + "/bulk-user-create"
        const header = { "Content-Type": "multipart/form-data" }
        service.httpService(httpConstants.METHOD_TYPE.POST, formData, url, header).then((res) => {
            toast.current.show({ severity: 'info', detail: "File Received. User Creation in Progress" });
            setTimeout(() => { fetchUploadData() }, 2000)
            fetchUploadData()
            setFile(null)
        }).catch(err => {
            console.error("Error in bulk user create ", err)
        })
    };
    return (
        <div className='font-larken'>
            <Toast ref={toast} />
            <div className='flex align-items-center mb-3' style={{ gap: 10, fontFamily: "Larken" }}>
                <img onClick={() => {setOpenBulkUserCreate((prev) => !prev); setFile(null)}} src='/images/Back.svg' className='cursor-pointer' />
                <h2 className='m-0 font-light'>Bulk Designer Create</h2>
            </div>
            <Upload accept={".xlsx"} onchange={handleChange} fileName={file?.name} error="">
                <>
                    <Button className='text-xl mb-4 font-larken px-5 p-button-rounded sage-ivory' label="Upload" onClick={handleFileUpload} disabled={!file} />
                    <a className='text-xl' href={process.env.REACT_APP_IMAGE_STATIC_URL + `/bulkCreateTemplate.xlsx`} download>
                        Download Template
                    </a>
                </>
            </Upload>
            <div className='col-12 mt-2 card'>
                <DataTable value={data} className='font-Gerstner-ProgramRegular' paginator={true} rows={10} responsiveLayout="scroll" >
                    <Column header="Upload Time" headerClassName="bg-blue-400 text-lg text-white white-space-nowrap" body={(row) => dateFormatter(row.ts ?? "", "YYYY-MM-DD HH:mm:ss")} ></Column>
                    <Column header="File Name" headerClassName="bg-blue-400 text-lg text-white white-space-nowrap" field="fileKey" className='col-2'></Column>
                    <Column header="File Status" headerClassName="bg-blue-400 text-lg text-white white-space-nowrap" field="status" bodyClassName=""></Column>
                    <Column header="Total Uploaded" headerClassName="bg-blue-400 text-lg text-white white-space-nowrap" field='recCount'></Column>
                    <Column header="Succeed" headerClassName="bg-blue-400 text-lg text-white white-space-nowrap" field="successCount"></Column>
                    <Column header="Failed" headerClassName="bg-blue-400 text-lg text-white white-space-nowrap" field="errorCount"></Column>
                    <Column header="" headerClassName="bg-blue-400 text-lg text-white white-space-nowrap" body={showDownload}></Column>
                </DataTable>
            </div>
        </div>
    )
}

export default BulkUserCreate