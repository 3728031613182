import React, { useEffect, useState } from 'react'
import { plazaServices } from './tollPlaza/plazaReportService'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
export const sortObjArrbyKey = (arr, key, value) => {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i][key] !== value) {
            for (let j = i + 1; j < arr.length; j++) {
                if (arr[j][key] === value) {
                    let temp = arr[i];
                    arr[i] = arr[j]
                    arr[j] = temp;
                }

            }
        }
    }
    return arr;
}

const DesinerStatusPage = () => {
    const serviceId = localStorage.getItem("serviceId")
    const [orgData, setOrgData] = useState()
    const [dupData, setDupData] = useState()
    const [selectedStatus,setSelectedStatus] = useState()
    useEffect(() => {
        Promise.all([plazaServices.general(serviceId, { projection: { blockAccess: 1, expired: 1, Balance: 1, expiry_date: 1, plan_MonthCount: 1, planBasePrice: 1, planUser: 1, planName: 1, designerID: 1 } }, "nq-designer-plan")]).then((res) => {
            if (res && res.length){
                setDupData(sortObjArrbyKey(res[0]))
                setOrgData(sortObjArrbyKey(res[0]))
            }
        })
    }, [])
    const handleStatusChange  = (status)=>{
        let temp = orgData && orgData.length && orgData.filter((item)=>{
            return status==="All"?true:item[status]
        })
        setDupData(temp)

    }
    return (
        <div className='col-12 card'>
            <Dropdown
                    id="tollDropdown"
                    value={selectedStatus}
                    optionLabel='name'
                    options={[{name:"All",value:"All"},{name:"Acess Blocked",value:"blockAccess"},{name:"Plan Expired",value:"expired"}]}
                    onChange={(e) => {
                        setSelectedStatus(e.target.value)
                        handleStatusChange(e.target.value)

                    }}
                    placeholder="Select A Status"
                    className="general-bar-chart-filter flex align-items-center lg:col-3 col-12"
                    style={{
                        borderRadius: '20px',
                        border: '2px solid #0C6291',
                        height:"3rem"
                       
                    }}
                />
            <DataTable value={dupData} paginator={true} rows={10} responsiveLayout="scroll" className='mt-2'>
                {
                    [{ head: "DesignerID", field: "designerID" }, { head: "Plan Name", field: "planName" }, { head: "Plan User", field: "planUser" }, { head: "Balance", field: "Balance" }].map((item) => {
                        return <Column headerClassName="blue-bg " className="bg-white font-medium  bg-gray-200" header={item.head} field={item.field}></Column>
                    })
                }
                <Column headerClassName="blue-bg " className="bg-white font-medium  bg-gray-200" header="Expiry Date"  body={(row)=>{return moment(row["expiry_date"], 'YYYY-MM-DD,h:mm').format('Do,MMM YY')}}></Column>
                <Column headerClassName="blue-bg" className="bg-white font-medium  bg-gray-200" header={"Acess Status"} body={(row) => { return row.blockAccess ? "Acess Blocked" : "Allowed" }}></Column>
                <Column  headerClassName="blue-bg " className="bg-white font-medium  bg-gray-200" header={"Plan Status"} body={(row) => { return row.expired ? "Expired" : "Not Expired" }}></Column>
            </DataTable>
        </div>
    )
}

export default DesinerStatusPage