import React from 'react'
import { Divider } from 'primereact/divider';

const ShowSurvey = ({survey=[]}) => {
    const designAnsByType = (ans, type) => {
        const imageExtensions = /^(https?|ftp|s3):\/\/[^\s/$.?#].[^\s]*\.(?:[a-zA-Z0-9]{2,})$|^[^\s/$.?#].[^\s]*\.(?:[a-zA-Z0-9]{2,})$/;
        const urlPattern = /^(https?:\/\/)/;

        if ((type && type === "file_url") || imageExtensions.test(ans)) {
            let splitAns = ans.split("/")
            return <div className='' style={{ padding: "0.75rem 1.5rem", border: "1px dashed #9AAB89", borderRadius: "5px" }}>
                <span style={{ fontFamily: "Montserrat" }} className='charcoal-color text-base cursor-pointer'><i className='pi pi-paperclip'></i> {splitAns[splitAns.length - 1]}</span>
            </div>
        }

        else if ((type && type === "url") || urlPattern.test(ans)) {
            return <span className='' style={{ padding: "0.75rem 1.5rem", border: "1px solid #9AAB89", borderRadius: "5px" }}>
                <a className='text-decoration-none' target='_blank' href={ans}><span style={{ fontFamily: "Montserrat" }} className='charcoal-color text-base'>{ans}</span></a>
            </span>
        }

        else if(type && type.includes("choice")) {
            return <span  style={{ borderRadius: "3.125rem", padding: "0.5rem 0.75rem", fontFamily: "Montserrat", backgroundColor: "#E5E1CF" }} className='charcoal-color m-0 text-base'>{ans}</span>
        }

        else return <p style={{ fontFamily: "Montserrat" }} className='p-0 charcoal-color text-base m-0'>{ans}</p>
    }

    const renderAns = (userResp) => {
        let ans = userResp.answer
        switch (typeof (ans)) {
            case "object":
                if (Array.isArray(ans)) {
                    return (
                        <div className='flex' style={{ gap: 10 }}>
                            {ans.map((item) => { return designAnsByType(item, userResp.type) })}
                        </div>
                    )
                }
                break;
            default:
                return designAnsByType(ans, userResp.type)
        }
    }
    if (!survey.length) return <p className='m-0 text-3xl charcoal text-center'>No Survey Data</p>
    return (
        <div id="survey-container" className=''>
            {survey.map((resp, index) => (
                <div>
                    <p className='m-0 mb-2 font-larken text-base charcoal-color'>{resp.question}</p>
                    {renderAns(resp)}
                    {index !== survey.length - 1 && <Divider type='dashed' />}
                </div>
            ))}
        </div>
    )
}

export default ShowSurvey