import React, { useState } from "react";

const CustomInputSwitch = ({status,OnChange}) => {
  const [checked, setChecked] = useState(status);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "57px",
        height: "30px",
        backgroundColor: status ? "#34C759" : "#ccc",
        borderRadius: "22px",
        position: "relative",
        cursor: "pointer",
        boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.15)",
        transition: "background-color 0.3s ease",
      }}
      onClick={()=>{OnChange(!status)}}
    >
      <div
        style={{
          width: "26px",
          height: "26px",
          backgroundColor: "#fff",
          borderRadius: "50%",
          position: "absolute",
          top: "50%",
          left: status ? "calc(100% - 31px)" : "5px",
          transform: "translateY(-50%)",
          boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.15)",
          transition: "left 0.3s ease",
        }}
      ></div>
    </div>
  );
};

export default CustomInputSwitch;
