import React from 'react'
import DesignerList from './DesignerList'
import DesignerProfile from './DesignerProfile'

const RerouteIHUser = () => {
    const userpermissions = JSON.parse(localStorage.getItem("permissions"))
    const isAdmin = (userpermissions.includes("UserManagement.READ") && userpermissions.includes("UserManagement.WRITE"))
     
  return (
    isAdmin ? <DesignerList/> : <DesignerProfile/>
  )
}

export default RerouteIHUser