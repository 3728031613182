import React, { useRef, useState } from 'react'
import imagineHomeService from "./imagineHomeService"
import { Divider } from 'primereact/divider'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

const ListFiles = ({ data = {}, list, upload, quotes = {}, invoices = {}, submit }) => {
    const downloadUrlMap = useRef({})
    const serviceId = localStorage.getItem("serviceId")
    const isProposalUpload = list === "designer" ? true : false
    const [inputs, setInputs] = useState({})
    const [editEnabled, setEnabled] = useState({})

    const handledown_preview = async (e) => {
        const fileKey = e.currentTarget.id
        let downloadurl = ""
        if (fileKey) {
            if (!(fileKey in downloadUrlMap.current)) {
                const formData = new FormData()
                formData.append("serviceId", serviceId)
                formData.append("fileKey", fileKey)

                const downloadURLRES = await imagineHomeService.fetchDownloadFileUrl(formData)
                downloadurl = downloadURLRES?.download_url ?? ""
            }
            else downloadurl = downloadUrlMap.current[fileKey]

            window.open(downloadurl, "_blank")
        }
    }

    const handleChange = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value })
    }

    const handleClick = (e, toChange = "") => {
        const Id = e.currentTarget.id
        const splitId = Id.split("_")

        let qIid = ""
        if (toChange === "quoteID" && inputs["quoteID_" + Id] && !inputs["quoteID_" + Id]?.trim()) return
        else if (toChange === "invoiceID" && inputs["invoiceID_" + Id] && !inputs["invoiceID_" + Id]?.trim()) return

        if (toChange === "quoteID") qIid = inputs["quoteID_" + Id]
        else qIid = inputs["invoiceID_" + Id]

        let obj = {
            toChange: toChange,
            roomType: splitId[0],
            roomName: splitId[1],
            id: qIid,
            fileType: toChange === "quoteID" ? "Quote" : "Invoice",
            handleSubmit: handleAfterSubmit
        }
        submit(null, toChange, obj)
    }

    const handleAfterSubmit = (data) => {
        if (data["toChange"] === "quoteID") {
            if (!(data["roomType"] + "_" + data["roomName"] in quotes)) {
                quotes[data["roomType"] + "_" + data["roomName"]] = {
                    id: data["id"],
                    status: "Pending"
                }
            }
            else {
                quotes[data["roomType"] + "_" + data["roomName"]].status = "Pending"
                quotes[data["roomType"] + "_" + data["roomName"]].id = data["id"]
            }

            setEnabled((prev) => ({ ...prev, ["quote_" + data["roomType"] + "_" + data["roomName"]]: false }))
            setInputs((prev) => ({ ...prev, ["quoteID_" + data["roomType"] + "_" + data["roomName"]]: "" }))
        }
        else {
            if (!(data["roomType"] + "_" + data["roomName"] in invoices)) {
                invoices[data["roomType"] + "_" + data["roomName"]] = {
                    id: data["id"],
                    status: "Pending"
                }
            }
            else {
                invoices[data["roomType"] + "_" + data["roomName"]].status = "Pending"
                invoices[data["roomType"] + "_" + data["roomName"]].id = data["id"]
            }
            setEnabled((prev) => ({ ...prev, ["invoice_" + data["roomType"] + "_" + data["roomName"]]: false }))
            setInputs((prev) => ({ ...prev, ["invoiceID_" + data["roomType"] + "_" + data["roomName"]]: "" }))

        }
    }


    return (
        <>
            <div className="">
                {Object.keys(data).map((item) => {
                    return <>
                        <div key={item} className='designer-card-border p-4 mt-3 '>
                            <div className={isProposalUpload && 'flex align-items-center mb-4'} style={{ gap: 10 }}>
                                <p className='font-larken text-lg m-0'>{item?.split("_") ? item.split("_")[1] : "--"}</p>
                                {isProposalUpload && <div>
                                    <Button label="UPLOAD" id={item} onClick={(e) => upload(e)} icon="pi pi-file" className="p-button-rounded p-button-info text-xs py-2 font-Gerstner-ProgramRegular font-semibold px-4 charcoal-color" style={{ letterSpacing: "1.2px", backgroundColor: "#9AAB89 " }} />
                                </div>}
                            </div>
                            {!isProposalUpload && <Divider className='m-0 mt-1 mb-3' style={{ backgroundColor: "#9AAB89", height: "1px" }} />}
                            {data[item].map((file, i) => {
                                return <>
                                    <div key={file.fileKey} className='charcoal flex col-12 p-0' style={{ letterSpacing: "1.44px", gap: 15 }}>
                                        <p className='m-0 col-6 p-0' >{file.filename ? <><i className='pi pi-file mr-2'></i>{file.filename}</> : <span className='text-grey-700' style={{ color: "#1D1B1C" }}>No Files Found For this Room</span>}</p>
                                        <p className='m-0 col-3 p-0'>{file.roomType}</p>
                                        {file.filename && <i id={file.filekey} style={{ color: "#5295E2" }} className='pi pi-cloud-download text-3xl cursor-pointer' onClick={(e) => handledown_preview(e)}></i>}
                                    </div>
                                    {i !== data[item].length - 1 && <Divider className='m-0 my-3' style={{ backgroundColor: "#BFAD5B", height: "1px" }} />}
                                </>
                            })}
                            {(isProposalUpload && (data[item]?.length > 1 || (data[item]?.length === 1 && data[item][0].filename !== ""))) ?
                                (<div className='flex mt-4 justify-content-between' style={{ gap: 15 }}>
                                    <div className='flex align-items-end' style={{ gap: 20 }}>
                                        <label className='font-larken font-semibold'>
                                            Quote ID:
                                            <InputText id={"quoteID_" + item} name={"quoteID_" + item} readOnly={!editEnabled["quote_" + item]} value={editEnabled["quote_" + item] ? inputs["quoteID_" + item] ?? "" : quotes[item]?.id} onChange={(e) => handleChange(e)} style={{ border: "1px solid #ADB5BD", borderRadius: "5px" }} className="py-1 w-full outline-none" />
                                        </label>
                                        <div className='flex'>
                                            <Button icon={editEnabled["quote_" + item] ? "pi pi-times" : "pi pi-pencil"} id={"quote_" + item} onClick={(e) => { setEnabled({ ...editEnabled, [e.target.id]: !editEnabled[e.target.id] }) }} className=" cursor-pointer dijon-ivory p-button-rounded p-1 m-0 mr-2" />
                                            {editEnabled["quote_" + item] && <Button label="SUBMIT" id={item} onClick={(e) => handleClick(e, "quoteID")} className="p-button-rounded p-button-info text-xs py-2 font-Gerstner-ProgramRegular font-semibold px-4 charcoal-color" style={{ letterSpacing: "1.2px", backgroundColor: "#9AAB89 " }} />}
                                        </div>
                                        <div className='text-base white-space-nowrap'>Status: <span className='font-italic	'>{quotes[item]?.status ?? ""}</span></div>
                                    </div>
                                    <div className='flex align-items-end' style={{ gap: 10 }}>
                                        <label className='font-larken font-semibold'>
                                            Invoice ID:
                                            <InputText id={"invoiceID_" + item} name={"invoiceID_" + item} readOnly={!editEnabled["invoice_" + item]} value={editEnabled["invoice_" + item] ? inputs["invoiceID_" + item] ?? "" : invoices[item]?.id} onChange={(e) => handleChange(e)} style={{ border: "1px solid #ADB5BD", borderRadius: "5px" }} className="py-1 w-full outline-none" />
                                        </label>
                                        <div className='flex'>
                                            <Button icon={editEnabled["invoice_" + item] ? "pi pi-times" : "pi pi-pencil"} id={"invoice_" + item} onClick={(e) => setEnabled({ ...editEnabled, [e.target.id]: !editEnabled[e.target.id] })} className="cursor-pointer dijon-ivory p-button-rounded p-1 m-0 mr-2" />
                                            {editEnabled["invoice_" + item] && <Button label="SUBMIT" id={item} onClick={(e) => handleClick(e, "invoiceID")} className="p-button-rounded p-button-info text-xs py-2 font-Gerstner-ProgramRegular font-semibold px-4 charcoal-color" style={{ letterSpacing: "1.2px", backgroundColor: "#9AAB89 " }} />}
                                        </div>
                                        <div className='text-base white-space-nowrap'>Status: <span className='font-italic	'>{invoices[item]?.status ?? ""}</span></div>
                                    </div>
                                </div>) : null}
                        </div>
                    </>
                })}
                {/* <DataTable value={data} rowGroupMode="subheader" groupRowsBy="room" emptyMessage="No Customer/Projects Found"
                        sortMode="single" sortField="room" sortOrder={1} scrollable scrollHeight="400px"
                        rowGroupHeaderTemplate={(e) => headerTemplate(e, list)}>
                        <Column field="" header="" style={{ maxWidth: '50px' }} bodyClassName='ml-2 p-3 text-lg charcoal' body={(row, column) => (row.filename && column.rowIndex + 1)}></Column>
                        <Column field="filename" header="" bodyClassName=' p-3 text-lg' style={{ minWidth: '50%' }} body={(row) => (row.filename ? <span className='charcoal'><i className='pi pi-file pr-2 text-lg'></i>{row.filename ?? ""}</span> : <span className='text-grey-700' style={{color: "#1D1B1C"}}>No Files Found For this Room</span>)}></Column>
                        <Column field="roomType" header="" bodyClassName=' p-3 text-lg charcoal' ></Column>
                        <Column field="" header="" bodyClassName='ml-2 p-3 text-lg' body={(row, column) => row.filename && <i id="down" style={{ color: "#5295E2" }} className='pi pi-cloud-download text-3xl cursor-pointer' onClick={(e) => handledown_preview(e, row)}></i>}></Column>
                    </DataTable> */}
            </div>
        </>
    )
}

export default ListFiles