import axios from "axios"
const fetchDistinctDetails = async (payload, serviceId) => {
    let res = await axios.put(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/nq-customer-project-designer-mappings", payload).catch(() => {
        return []
    })
    return res.data
}

const fetchUserDetails = async (payload={}) => {
    let res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/nextqore/nq-userpermissions", payload ).catch(() => {
        return []
    })
    return res.data
}

const fetchProjectFiles = async (payload) => {
    let res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/imaginehomes/list", payload ).catch(() => {
        return []
    })
    return res.data
}

const uploadRoomFile = async (payload) => {
    let res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/imaginehomes/upload/", payload ).catch(() => {
        return {}
    })
    return res.data
}

const fetchMaster = async (col, serviceId, payload={}) => {
    let res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/" + serviceId + "/" + col, payload).catch(() => {
        return []
    })
    return res.data
}

const fetchDownloadFileUrl = async (payload) => {
    let res = await axios.post(process.env.REACT_APP_API_PARSER_URL + "/imaginehomes/downloadURL", payload ).catch(() => {
        return {}
    })
    return res.data
}



const assignDesigner = async (paylaod={}) => {
    let res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/config/assign-cust-to-designer", paylaod ).catch(() => {
        return []
    })
    return res.data
}

const service = {
    assignDesigner,
    fetchDistinctDetails,
    fetchUserDetails,
    fetchProjectFiles,
    uploadRoomFile,
    fetchMaster,
    fetchDownloadFileUrl
}
export default service