import React, { useState, useEffect, useRef } from 'react'
import "./Lcix.scss"
import walmartService from "./walmartService"
import { marketplace, currencyMap } from "./wmConstants"
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import moment from "moment"
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button'
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from 'primereact/multiselect'
import { Dialog } from 'primereact/dialog'
import OrderLineDetails from './orderLineDetails'
import { plazaServices } from '../tollPlaza/plazaReportService'
import { useLocation } from 'react-router-dom';
import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts";
import {newChartColors} from '../../utilities/constant'
import { Row } from 'primereact/row'
import { ColumnGroup} from 'primereact/columngroup'
import { Toast } from 'primereact/toast'
import * as XLSX from "xlsx";

const Orders = () => {
    const [ordersData, setOrdersData] = useState([])
    const [selectedMarketplace, setSelectedMarketPlace] = useState({ name: "Walmart US", code: "walmart_us" })
    const [search, setSearch] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [expandedRowData, setExpandedRowData] = useState({})
    const [first, setFirst] = useState(0)
    const [orderStatus, setOrderStatus] = useState([])
    const [page, setPage] = useState(0)
    const [rowData, setRowData] = useState({})
    const [expandedRows, setExpandedRows] = useState(null)
    const [charts, setCharts] = useState({})
    const prevPage = useRef(null)
    const serviceDetails = JSON.parse(localStorage.getItem("service"))
    const startDate = moment().subtract(15, "d").startOf().format("MM/DD/YYYY");
    const endDate = moment().endOf().format("MM/DD/YYYY");
    const [dateRange, setDateRange] = useState([new Date(startDate), new Date(endDate)]);
    const [orderItemDetails, setOrderItemDetail] = useState(null);
    const serviceId = localStorage.getItem("serviceId")
    const isInitalLoad = useRef(false)
    const location = useLocation();
    const toast = useRef()
    const [totalQtyAndValue, setTotalQtyAndValue] = useState({})
    let state = location.state;
    const [disableBtn, setDisableBtn] = useState(false)
    if (state){
        const statusMap = {
            "Created": "Created",
            "Acknowledged": "Acknowledged",
            "Cancelled": "Cancelled",
            "Shipped": "Shipped",
            "Delivered": "Delivered",
            "SentToRepro": "Sent to Repro"
        };
        state = [{ name: statusMap[`${state.message}`], code: state.message }]
    }
    else state = []
    const [selectedStatus, setSelectedStatus] = useState(state)

    useEffect(() => {
        let paylaod = {
            projection: { "_id": 0 }
        }
        walmartService.orderStatusMaster(serviceId, paylaod).then((res) => {
            res.length && setOrderStatus(res[0]?.orderStatus ?? [])
        })
    }, [])

    useEffect(() => {
        if (dateRange) {
            if (dateRange[1] !== null) {
                let payload = formPayload(0, 20, { searchKey: search, selectStatus: selectedStatus })
                initReport(payload, true)
                setFirst(0)
                setPage(0)
                prevPage.current = 0
            }
        }
    }, [dateRange])
    useEffect(() => {
        let timeoutid;
        if (isInitalLoad.current) {
            timeoutid = setTimeout(() => {
                let paylaod = formPayload(0, 20, { searchKey: search, selectStatus: selectedStatus })
                initReport(paylaod, true)
                setFirst(0)
                setPage(0)
            }, 1000)
        }
        isInitalLoad.current = true
        return () => clearTimeout(timeoutid)
    }, [search, JSON.stringify(selectedStatus)])

    const initReport = async (paylaod, toreset = false) => {
        return walmartService.getOrdersData(serviceId, paylaod).then(res => {
            let data = toreset ? res : [...ordersData, ...res]
            setOrdersData(data)
            setExpandedRows(null)
        })
    }
    useEffect(() => {
            let matchObj = {
                ...(search && {
                    $or: [
                        { purchaseOrderId: { $regex: search, $options: "i" } },
                        { customerOrderId: { $regex: search, $options: "i" } }
                    ]
                }),
                ...(selectedStatus.length && {
                    lastStatus: { "$in": selectedStatus.map((item) => item.code) }
                })
            }
           if(dateRange[1] !== null){
            let fromDate = moment(dateRange[0]).startOf("day").valueOf()
            let toDate = moment(dateRange[1]).endOf("day").valueOf()
            matchObj["orderDate"] = { $gte: fromDate, $lte: toDate }

            let totalQtyAndGrossValue = {
                operation: "aggregate",
                aggregate: [
                    {
                        "$match": matchObj
                    },
                    {
                        "$group": {
                            _id: null,
                            "totalQuantity": { "$sum": "$qty" },
                            "amount": { "$sum": "$charge.amount" },
                        }
                    }
                ]
            }
            let salesInsightsForDateRange = {
                operation: "aggregate",
                aggregate: [
                    {
                        "$match": matchObj
                    },
                    {
                        "$group": {
                            "_id": {
                                "orderDate": {
                                    "$dateToString": {
                                        "format": "%Y-%m-%d",
                                        "timezone" : moment.tz.guess(),
                                        "date": {
                                            "$toDate": "$orderDate"
                                        }
                                    }
                                },
                                "customerID": "$customerOrderId"
                            },
                            "orders": {
                                "$sum": 1
                            },
                            "amount": {
                                "$sum": "$charge.amount"
                            },
                            "qtyOfBooks": {
                                "$sum": "$qty"
                            },
                            "uniqueISBNS": {
                                "$addToSet": "$sku"
                            }
                        }
                    },
                    {
                        "$group": {
                            "_id": "$_id.orderDate",
                            "orders": {
                                "$sum": "$orders"
                            },
                            "amount": {
                                "$sum": "$amount"
                            },
                            "qtyOfBooks": {
                                "$sum": "$qtyOfBooks"
                            },
                            "uniqueISBNS": {
                                "$addToSet": "$uniqueISBNS"
                            }
                        }
                    },
                    {
                        "$addFields": {
                            "uniqueISBNS": {
                                "$reduce": {
                                    "input": "$uniqueISBNS",
                                    "initialValue": [],
                                    "in": { "$setUnion": ["$$value", "$$this"] }
                                }
                            }
                        }
                    },
                    {
                        "$sort": {
                            "_id": 1
                        }
                    }
                ]
            }
        Promise.all([walmartService.getOrdersData(serviceId, totalQtyAndGrossValue), walmartService.getOrdersData(serviceId, salesInsightsForDateRange)]).then((res) => {
            const chart = {}
            if (res[0]) {
                if(res[0].length){
                    setTotalQtyAndValue(res[0][0])
                }
            }
            if(res[1]){
                let startDate = moment(dateRange[0]).format("YYYY-MM-DD")
                let endDate = moment(dateRange[1]).format("YYYY-MM-DD")
                const categories = []
                const ordersCount = []
                const orderValues = []
                const noOfBooks = []
                const uniqueIsbns =  []
                const dataSum ={orders: 0, amount :0, qtyOfBooks:0, uniqueISBNS:0 }
                let index = 0
                    const data = res[1]
                    while (moment(startDate).isSameOrBefore(endDate)) {
                        categories.push(moment(startDate).format("MMM DD"));
                        let tempDate;
                        if(data[index]?._id) tempDate = data[index]?._id 
                        if (tempDate && moment(tempDate).isSame(moment(startDate))) {
                            ordersCount.push(data[index]["orders"]);
                            orderValues.push(Number(data[index]["amount"]?.toFixed(2)) ?? null)
                            noOfBooks.push(data[index]["qtyOfBooks"])
                            uniqueIsbns.push(data[index]["uniqueISBNS"].length)
                            for (const key in dataSum) {
                                if (key === "uniqueISBNS") dataSum[key] = dataSum[key] + data[index][key].length
                                else dataSum[key] = dataSum[key] + data[index][key]
                            }
                            index++
                        } else {
                            ordersCount.push(null);
                            orderValues.push(null)
                            noOfBooks.push(null)
                            uniqueIsbns.push(null)
                        }
                        startDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
                    }
                    chart["salesInsightsForDateRange"] = getLineChart(ordersCount, orderValues, noOfBooks, categories, dataSum)
                }
            setCharts(chart)
        })
    }

    }, [dateRange])
    const dateTemplate = (date) => {
        //moment(arr[i].dt).format('MMM Do YYYY')
        if (!date) return "--"
        if (serviceDetails?.region?.includes("us")) {
            return moment.utc(date).local().format("MMM DD, HH:mm");
        } else {
            return moment.utc(date).local().format("Do MMM, HH:mm");
        }
    };
    const onReset = () => {
        if (!selectedStatus.length && !search) return
        setOrdersData([])
        setSelectedStatus([])
        setSearch("")
        setExpandedRows(null)
        setFirst(0)
        setPage(0)
        prevPage.current = 0
    }
    const ONOrderClick = async (row) => {
        const payload = {
            filter: {
                "purchaseOrderId": row.data.purchaseOrderId
            },
            projection: {
                _id: 0,
                productName: 1,
                orderDate: 1,
                purchaseOrderId: 1,
                shippingInfo: 1,
                statusLog: 1,
                marketPlaceStatusLog: 1,
                "chargeBreakup.tax": 1,
                "indiaShipment.name": 1,
                "charge.amount": 1,
                parcelTrackingInfo: 1,
                indiaShipment: 1,
                parcelInfo: 1
            }
        }
        let res = await plazaServices.general(serviceId, payload, "nq-order-items")
        setOrderItemDetail(res[0])
        setRowData(row?.data)
        setShowModal(true)
    }
    const newPage = (e) => {
        setFirst(e.first)
        setPage(e.page)
        if (e.page > prevPage.current) {
            prevPage.current = e.page
            let payload = formPayload(ordersData.length, 20, { searchKey: search, selectStatus: selectedStatus })
            initReport(payload)
        }
    }
    const formPayload = (skip = 0, limit = 6, filter) => {
        let fromDate = moment(dateRange[0]).startOf("day").valueOf()
        let toDate = moment(dateRange[1]).endOf("day").valueOf()
        let matchObj = {
            orderDate: { $gte: fromDate, $lte: toDate },
            ...(search && {
                $or: [
                    { purchaseOrderId: { $regex: filter.searchKey, $options: "i" } },
                    { customerOrderId: { $regex: filter.searchKey, $options: "i" } }
                ]
            }),
            ...(filter.selectStatus.length && {
                lastStatus: { "$in": filter.selectStatus.map((item) => item.code) }
            })
        }
        let newDataPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    "$match": matchObj
                },
                {
                    "$group": {
                        "_id": "$customerOrderId",
                        "totalQuantity": { "$sum": "$qty" },
                        "cancelledQuantity": { "$sum": { "$cond": [{ "$eq": ["$marketplaceStatus", "Cancelled"] }, "$qty", 0] } },
                        "orderDate": { "$first": "$orderDate" },
                        "listingPartner": { "$first": "$listingPartner" },
                        "currency": { "$first": "$charge.currency" },
                        "amount": { "$sum": "$charge.amount" },
                        "lastStatus": { "$addToSet": "$lastStatus" },
                        "estimatedDeliveryDate" : { "$first": "$shippingInfo.estimatedDeliveryDate"},
                        "estimatedShipmentDate" : { "$first": "$shippingInfo.estimatedShipDate"},
                        "deliveredLogs": {"$push": {
                            $arrayElemAt: [
                                {
                                    $map: {
                                        input: {
                                            $filter: {
                                                input: "$statusLog",
                                                as: "status",
                                                cond: { $eq: ["$$status.status", "Delivered"] }
                                            }
                                        },
                                        as: "filteredStatus",
                                        in: "$$filteredStatus.dt"
                                    }
                                },
                                0
                            ]
                        }},
                        "shippingLogs": {"$push": {
                            $arrayElemAt: [
                                {
                                    $map: {
                                        input: {
                                            $filter: {
                                                input: "$statusLog",
                                                as: "status",
                                                cond: { $eq: ["$$status.status", "Shipped"] }
                                            }
                                        },
                                        as: "filteredStatus",
                                        in: "$$filteredStatus.dt"
                                    }
                                },
                                0
                            ]
                        }},
                        "indiaToUSShipment": {"$push": {"tid" : "$indiaShipment.shipmentId", "url":"$indiaShipment.trackUrl"}},
                        "lastMileShipment": {"$push": {"tid" : "$parcelInfo.trackingCode", "url":"$parcelInfo.packageTrackingUrl"}}

                    }
                },
                { $sort: { "orderDate" : -1 } },
                { $skip: skip }, { $limit: limit }
            ]
        }
        return newDataPayload
    }
    const rowExpansionTemplate = (row) => {
        return (
            <div className='col-12 lg:ml-4'>
                <DataTable value={expandedRowData[row._id] ?? []} headerColumnGroup={customOrderItemsHeader} emptyMessage="loading..." selectionMode='single' onRowClick={(e) => ONOrderClick(e)} rows={10} responsiveLayout="scroll" >
                    <Column header="Order Date" body={(item) => dateTemplate(item.orderDate)} bodyClassName=""></Column>
                    <Column header="Purchase Order ID" field="purchaseOrderId" ></Column>
                    <Column header="Isbn" field="sku" bodyClassName=""></Column>
                    <Column header="Quantity" field="orderQuantity"></Column>
                    <Column header="Amount" field="amount" body={amountTemplate}></Column>
                    <Column header="Last Status" field="lastStatus"></Column>
                    <Column header="Last Status Update Date" body={(item) => dateTemplate(item.lastStatusUpdatedDate)}></Column>
                    <Column body={(item) => item.lastStatus !== "Cancelled" ? dateTemplate(item.estimatedDeliveryDate) : "--"} bodyClassName=""></Column>
                    <Column body={(item) => item.lastStatus !== "Cancelled" ? dateTemplate(item.deliveredTs) : "--"} bodyClassName=""></Column>
                    <Column body={(row)=>trackingIDFormat([{"tid": row.indiaShipmentId, "url": row.indiaShipmenturl}])}></Column>
                    <Column body={(row)=>trackingIDFormat([{"tid": row.lastMileShipmentId, "url": row.lastMileShipmenturl}])}></Column>
                    <Column header="Print Partner" field="printPartner"></Column>
                </DataTable>
            </div>
        )
    }
    const onRowExpandClick = (row) => {
        let payload = {
            operation: "aggregate",
            "aggregate": [
                {
                    $match: {
                        customerOrderId: row.data._id,
                        orderDate: row.data.orderDate
                    }
                },
                {
                    $project: {
                        _id: 0,
                        amount: "$charge.amount",
                        currency: "$charge.currency",
                        purchaseOrderId: 1,
                        sku: 1,
                        printPartner: 1,
                        reproPrintStatus: 1,
                        lastStatusUpdatedDate: "$lastStatusUpdatedAt",
                        lastStatus: 1,
                        orderPrintDate: 1,
                        orderQuantity: "$qty",
                        indiaShipmentId: "$indiaShipment.shipmentId",
                        indiaShipmenturl: "$indiaShipment.trackUrl",
                        lastMileShipmentId: "$parcelInfo.trackingCode",
                        lastMileShipmenturl: "$parcelInfo.packageTrackingUrl",
                        orderDate: 1,
                        estimatedDeliveryDate: "$shippingInfo.estimatedDeliveryDate",
                        deliveredTs: {
                            $arrayElemAt: [
                                {
                                    $map: {
                                        input: {
                                            $filter: {
                                                input: "$statusLog",
                                                as: "status",
                                                cond: { $eq: ["$$status.status", "Delivered"] }
                                            }
                                        },
                                        as: "filteredStatus",
                                        in: "$$filteredStatus.dt"
                                    }
                                },
                                0
                            ]
                        }
                    }
                }
            ]
        }
    
        walmartService.getOrderLineInfo(serviceId, payload).then(res => {
            setExpandedRowData({ ...expandedRowData, [row.data._id]: res })
        })
    }
    const amountTemplate = (row) => {
        let temp = Number.parseFloat(row.amount).toFixed(2)
        return currencyMap[row.currency] + " " + temp
    }

    const trackingIDFormat = (trackingInfo) => {
        if (trackingInfo === "--" || !trackingInfo.length) return <span>--</span>
        else if (trackingInfo.length < 2) return <span>{!trackingInfo[0].tid ? "--" : <a href={trackingInfo[0]?.url ?? "#"} target='_blank'>{trackingInfo[0].tid}</a>}</span>
        else {
            let summaryText = trackingInfo[0].tid
            let restTicket = trackingInfo.slice(1, trackingInfo.length)
            return (<details id="tracking_ids" >
                <a href={trackingInfo[0]?.url ?? "#"} target='_blank'><summary className="mb-1">{"1) " + summaryText}</summary></a>
                <>
                    {restTicket.map((item, i) => {
                        return <>
                            <a href={trackingInfo[0]?.url ?? "#"} target='_blank'><p className="m-0"><span className="w-full">{i + 2 + ")"}</span>{item.tid}</p></a>
                        </>
                    })}
                </>
            </details>)
        }
    }

    const getLineChart = (orders, sales, books, categories,totalSum) => {
        return {
            chart: {
                type: 'areaspline'
            },
            title: {
                text: 'Sales Summary ',
                align: 'center'
            },
            legend: {
                layout: 'horizontal',
                align: 'center',
                itemWidth: "230",
                verticalAlign: 'top',
                itemMarginTop : 10,
                itemMarginBottom : 10,
                useHtml : true,
                labelFormatter: function () {
                    const seriesId = this.options.id; // Changed from this.series.options.id to this.options.id
                    const seriesName = this.name;
                    const value = (seriesId === "amount") ? "$ " +  totalSum[seriesId]?.toFixed(2) : totalSum[seriesId]
                    return `<span style="color: ${this.color}; font-weight: bold; font-size:1rem">${seriesName}: ${value}</span>`;
                },
                x: 0,
                y: 0,
                floating: false,
                borderWidth: 0,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#F8F8F8'
            },
            xAxis: {
                categories: categories,
                title: {
                    text: "Time"
                }
            },
            yAxis: [{
                title: {
                    text: 'Count',
                },
                labels: {
                    format: '{value}',
                },
                min:0,
                opposite: false,
                gridLineWidth: 0 
            },
            {
                title: {
                    text: '$ Sales',
                },
                labels: {
                    format: '{value}',
                },
                opposite: true,
                gridLineWidth: 0
            }],
            credits: {
                enabled: false
            },
            colors : newChartColors,
            plotOptions: {
                areaspline: {
        fillOpacity: 0.5,
        fillColor: {
            linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1 // Gradient from top (y1: 0) to bottom (y2: 1)
            },
            stops: [
                [0, Highcharts.getOptions().colors[0]], // Solid color at the top (start of the gradient)
                [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')] // Transparent at the bottom (end of the gradient)
            ]
        }
    }
            },
            series: [{
                name: 'Orders',
                data: orders,
                id : "orders",
                color: newChartColors[0],
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1 // Gradient from top to bottom
                    },
                    stops: [
                        [0, newChartColors[0]], // Solid color at the top
                        [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')] // Transparent at the bottom
                    ]
                }
            }, {
                name: 'Total Sales',
                id: "amount",
                data: sales,
                color: newChartColors[1],
                yAxis : 1,
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1 // Gradient from top to bottom
                    },
                    stops: [
                        [0, newChartColors[1]], // Solid color at the top
                        [1, Highcharts.color(Highcharts.getOptions().colors[1]).setOpacity(0).get('rgba')] // Transparent at the bottom
                    ]
                }
            }, {
                name: 'No Of books',
                id: "qtyOfBooks",
                data: books,
                color: newChartColors[2],
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1 // Gradient from top to bottom
                    },
                    stops: [
                        [0, newChartColors[2]], // Solid color at the top
                        [1, Highcharts.color(Highcharts.getOptions().colors[2]).setOpacity(0).get('rgba')] // Transparent at the bottom
                    ]
                }
            },
            {
                name: 'Unique Books Sold',
                id: "uniqueISBNS",
                data: books,
                color: newChartColors[3],
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1 // Gradient from top to bottom
                    },
                    stops: [
                        [0,newChartColors[3]], // Solid color at the top
                        [1, Highcharts.color(Highcharts.getOptions().colors[3]).setOpacity(0).get('rgba')] // Transparent at the bottom
                    ]
                }
            }]
        }
    }
    const customOrderHeader = (
        <ColumnGroup>
            <Row >
                <Column headerClassName="bg-blue-700 text-white white-space-nowrap" header="" rowSpan={2}></Column>
                <Column headerClassName="bg-blue-700 text-white p-2" header="Order Date" rowSpan={2}></Column>
                <Column headerClassName="bg-blue-700 text-white p-2" header="Marketplace Order ID" rowSpan={2}></Column>
                <Column headerClassName="bg-blue-700 text-white p-2" header="Current Status" rowSpan={2}></Column>
                <Column headerClassName="bg-blue-700 text-white p-2" header="Order Amount" rowSpan={2}></Column>
                <Column headerClassName="bg-blue-700 text-white p-2" header="Total Qty" rowSpan={2} ></Column>
                <Column headerClassName="bg-blue-700 text-white p-2" header="Cancelled Qty" rowSpan={2} ></Column>
                <Column headerClassName="bg-blue-700 text-white p-2 white-space-nowrap" header="Shipment Tracking" colSpan={2}className="text-center" ></Column>
                <Column headerClassName="bg-blue-700 text-white p-2" header="Listing Partner" rowSpan={2} ></Column>
            </Row>
            <Row>
                <Column headerClassName="bg-blue-700 text-white p-2 white-space-nowrap" header="India To US" className="text-center" ></Column>
                <Column headerClassName="bg-blue-700 text-white p-2 white-space-nowrap" header="Last Mile" className="text-center" ></Column>
            </Row>
        </ColumnGroup>
    )

    const customOrderItemsHeader = (
        <ColumnGroup>
            <Row >
                <Column headerClassName="bg-blue-400 p-2 text-white white-space-nowrap" header="Order Date" rowSpan={2}></Column>
                <Column headerClassName="bg-blue-400 p-2 text-white white-space-nowrap" header="Purchase Order ID" rowSpan={2}></Column>
                <Column headerClassName="bg-blue-400 p-2 text-white white-space-nowrap" header="Isbn" rowSpan={2}></Column>
                <Column headerClassName="bg-blue-400 p-2 text-white white-space-nowrap" header="Quantity" rowSpan={2}></Column>
                <Column headerClassName="bg-blue-400 p-2 text-white white-space-nowrap" header="Amount" rowSpan={2} ></Column>
                <Column headerClassName="bg-blue-400 p-2 text-white white-space-nowrap" header="Last Status" rowSpan={2} ></Column>
                <Column headerClassName="bg-blue-400 p-2 text-white white-space-nowrap" header="Last Status Update Date" rowSpan={2} ></Column>
                <Column headerClassName="bg-blue-400 p-2 text-white white-space-nowrap" header="Expected Delivery Date" rowSpan={2} ></Column>
                <Column headerClassName="bg-blue-400 p-2 text-white white-space-nowrap" header="Delivered Date" rowSpan={2} ></Column>
                <Column headerClassName="bg-blue-400 p-2 text-white white-space-nowrap" header="Shipment Tracking" colSpan={2}className="text-center" ></Column>
                <Column headerClassName="bg-blue-400 p-2 text-white white-space-nowrap" header="Print Partner" rowSpan={2} ></Column>
            </Row>
            <Row>
                <Column headerClassName="bg-blue-400 p-2 text-white white-space-nowrap" header="India To US" className="text-center" ></Column>
                <Column headerClassName="bg-blue-400 p-2 text-white white-space-nowrap" header="Last Mile" className="text-center" ></Column>
            </Row>
        </ColumnGroup>
    )
    const onOrdersDownload = async () => {
        try {
            let fromDate = moment(dateRange[0]).startOf("day").valueOf()
            let toDate = moment(dateRange[1]).endOf("day").valueOf()
            setDisableBtn(true)
            let payload = {
                operation: "aggregate",
                aggregate: [{
                    $match: {
                        orderDate: { $gte: fromDate, $lte: toDate },
                        ...(search && {
                            $or: [
                                { purchaseOrderId: { $regex: search, $options: "i" } },
                                { customerOrderId: { $regex: search, $options: "i" } }
                            ]
                        }),
                        ...(selectedStatus.length && {
                            lastStatus: { "$in": selectedStatus.map((item) => item.code) }
                        })
                    }
                }, {
                    $project: {
                        "OrderDate": "$orderDate",
                        "OrderId": "$customerOrderId",
                        "PurchaseOrderId": "$purchaseOrderId",
                        "ISBN": "$sku",
                        "Title": "$productName",
                        "Qty": "$qty",
                        "Amount": "$charge.amount",
                        "Current Status": "$lastStatus",
                        "Last Status Update Time": "$lastStatusUpdatedAt",
                        "India To Us Shipment Partner": "$indiaShipment.name",
                        "India To US Tracking Url": "$indiaShipment.trackUrl",
                        "Last Mile Shipment Partner": "$parcelInfo.walmartCarrierCode",
                        "Last Mile Tracking Url": "$parcelInfo.packageTrackingUrl",
                        "CustomerName": "$shippingInfo.postalAddress.name",
                        "Line Address 1": "$shippingInfo.postalAddress.address1",
                        "Line Address 2": "$shippingInfo.postalAddress.address2",
                        "Line Address 3": "$shippingInfo.postalAddress.address3",
                        "City": "$shippingInfo.postalAddress.city",
                        "State": "$shippingInfo.postalAddress.state",
                        "Zip": "$shippingInfo.postalAddress.postalCode",
                        "Country": "$shippingInfo.postalAddress.country",
                        "Email": "$shippingInfo.email",
                        "Phone": "$shippingInfo.phone",
                        "Listing Partner": "$listingPartner"
                    }
                }]
            }
            let res = await walmartService.getOrderLineInfo(serviceId, payload)
            
            const downloadData = []
            if(res && res.length){
                const dateKeys = ["OrderDate", "Last Status Update Time"]
                res.forEach((item, index) => {
                    let temp = {}
                    temp["Sr No"] = index + 1
                    for(const key in item){
                        if(key === "_id") continue
                        let value = item[key]
                        if(dateKeys.includes(key)) value = moment.utc(value).format("MMM DD, YYYY HH:mm")
                        temp[key] = value
                    }
                    downloadData.push(temp)
                })
            }
            if(downloadData.length){
                let ws = XLSX.utils.json_to_sheet(downloadData);
                let wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
                XLSX.writeFile(wb, `orderItemsData.xlsx`);
            }
            else toast.current.show({ severity: 'info', summary: 'No Data', detail: "No Data found" });

        }
        catch(err){
            console.error(err)
        }
        finally {
            setDisableBtn(false)
        }
    }

    const marketplaceOrderIdTemplate = (row) => {
        let style = {
            borderRadius: "0.3725rem",
            padding: "0.25em 0.5rem",
            textTransform: "uppercase",
            fontWeight: 700,
            letterSpacing: "0.3px",
            fontSize:"0.8rem",
            borderRadius: "2px", 
            backgroundColor : "#FFCDD2", 
            color :"#C63737"
        }
        if (row && Object.keys(row || {}).length) {
            let arr = row.deliveredLogs?.length ? row.deliveredLogs :
                      row.shippingLogs?.length ? row.shippingLogs : [];
            let key = false;
            if (arr.length) {
                let checkValue = row.deliveredLogs?.length ? row.estimatedDeliveryDate : row.estimatedShipmentDate;
                arr.forEach((item) => {
                    if (!key && item > checkValue) {
                        key = true;
                    }
                });
            }
            return (
                <div>
                    <p className="p-0 m-0">{row._id}</p>
                    {key && (
                        <p>
                            <span style={style}>
                                {row.deliveredLogs?.length ? "Late Delivered" : "Late Shipped"}
                            </span>
                        </p>
                    )}
                </div>
            );
        }  
    }

    return (
        <>
        <div className='flex flex-wrap'>
        <Toast ref={toast} />
            <div className='col-12 lg:col-4'>
                <h4 className="text-left w-full pl-2 m-0" style={{ color: "grey" }}>Orders Dashboard</h4>
                <div className="col-12 flex">
                    <div className='col-12'>
                        <label className='text-base pl-2'>Select Date Range</label>
                        <Calendar maxDate={new Date()} id="range" value={dateRange} onChange={(e) => setDateRange(e.value)} selectionMode="range" showIcon readOnlyInput className="bg-white w-full mt-1 p-calendar flex align-items-center" style={{ border: '2px solid #0C6291', borderRadius: "3rem" }} />
                    </div>
                </div>
            </div>
            <div className='col-12 md:col-8 flex flex-wrap md:justify-content-end'>
                    <div className='col-12 lg:col-4'>
                        <div className='displayCard pb-1 bg-white' style={{ border: "2px solid #BD7EBE", borderRadius: "15px" }}>
                            <h4 className='text-center mb-1'>Quantity</h4>
                            <h2 className='text-center mt-2'>{totalQtyAndValue?.totalQuantity ?? 0}</h2>
                        </div>
                    </div>
                    <div className='col-12 lg:col-4'>
                        <div className='displayCard pb-1 bg-white' style={{ border: "2px solid #7CAED5", borderRadius: "15px" }}>
                            <h4 className='text-center mb-1'>Gross Value</h4>
                            <h2 className='text-center mt-2'>$ {totalQtyAndValue?.amount?.toFixed(2) ?? 0}</h2>
                        </div>
                    </div>
                </div>
        </div>
            <div className='grid'>
                <div className='col-12'>
                    <div className='card'>
                        <HighchartsReact highcharts={Highcharts} style={{ height: "100%", overflow: "auto" }} options={charts["salesInsightsForDateRange"]} />
                    </div>
                </div>
            </div>
            <div className='col-12'>
                <div className='displayCard radius12 mb-3 bg-white pb-2'>
                    <div className="grid align-items-center">
                        <i className="pi-pw pi pi-filter text-lg p-1 "></i>
                        <div className="col-12 md:col-3 pt-2">
                            <InputText value={search} onChange={(e) => setSearch(e.target.value)} className="bg-white w-full" placeholder="Search" />
                        </div>
                        <div className="col-12 md:col-3">
                            <MultiSelect value={selectedStatus} options={orderStatus} onChange={(e) => setSelectedStatus(e.value)} display="chip" optionLabel="name" placeholder="Select Status" filter className="bg-white w-full" style={{ borderRadius: '20px', border: '2px solid #0C6291' }} />
                        </div>
                        <div className="col-12 md:col-2">
                            <Dropdown value={selectedMarketplace} disabled options={marketplace} /*onChange={(e) => handleFilter('marketplace', e.target.value)}*/ optionLabel="name" placeholder="MarketPlace" className="bg-white w-full" style={{ borderRadius: '20px', border: '2px solid #0C6291' }} />
                        </div>
                        <div className="col-12 md:col-3 mt-1">
                            <div className='flex gap-2 justify-content-around'>
                                <Button label="Reset" className=" p-button-danger" onClick={onReset} />
                                <Button label="Download" disabled={disableBtn} onClick={() => onOrdersDownload()} icon="pi pi-download" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="datatable-selection-demo mt-2">
                    <div className="card radius12">
                        <DataTable id="orderTable" value={ordersData} expandedRows={expandedRows} headerColumnGroup={customOrderHeader} first={first} page={page} onPage={newPage} onRowToggle={(e) => setExpandedRows(e.data)} className='datatable-box bifarcation-box' responsiveLayout="scroll" rowExpansionTemplate={rowExpansionTemplate} onRowExpand={onRowExpandClick} rows={10} paginator emptyMessage="Orders Data not found">
                            <Column expander headerClassName="bg-blue-700" bodyClassName='text-color' style={{ width: '3em' }} />
                            <Column body={(item) => dateTemplate(item.orderDate)} bodyClassName=""></Column>
                            <Column field="_id" body={(row)=> marketplaceOrderIdTemplate(row)} bodyClassName=""></Column>
                            <Column body={(row) => { return row.lastStatus && row.lastStatus.join(",") }}></Column>
                            <Column field="amount" body={amountTemplate}></Column>
                            <Column field="totalQuantity"></Column>
                            <Column field="cancelledQuantity"></Column>
                            <Column body={(row)=>trackingIDFormat(row.indiaToUSShipment)}></Column>
                            <Column body={(row)=>trackingIDFormat(row.lastMileShipment)}></Column>
                            <Column field="listingPartner"></Column>

                        </DataTable>
                    </div>
                </div>
            </div>
            <Dialog className='surface-ground w-full lg:w-9 ' style={{ backgroundColor: "red" }} header={<p>OrderLines</p>} visible={showModal} modal onHide={() => setShowModal(false)}>
                <div className="col-12">
                    <OrderLineDetails details={orderItemDetails} />
                </div>
            </Dialog>
        </>
    )
}
export default Orders