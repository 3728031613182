import React, { useEffect, useState, useRef } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { plazaServices } from './tollPlaza/plazaReportService';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import { Toast } from 'primereact/toast';

export const commonConfigCaller = async (endPoint, method, reqBody, configUrl = process.env.REACT_APP_DESIGN_AND_DEPLOY_SERVICE_URL) => {
  let jwtToken = localStorage.getItem("token")
  let header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwtToken
  };
  let res = await axios({
    method: method,
    url: `${configUrl}/${endPoint}`,
    data: reqBody,
    headers: header,
  });
  return res;
}

export const GetDialog = (dialog, showDialog, newBenifit, setNewBenifit, selectedBenifit, setSelectedBenifit, addBenifit, benifitCategory, showType = true) => {
  return <Dialog showHeader={false} visible={dialog} modal onHide={() => { showDialog() }} className='featuresDialog' style={{ borderRadius: "12px" }}>
    <div className=" col-12 m-0 ">
      <div className="grid col-12 m-0">
        <label htmlFor="light" className={"col-12 m-0 featuresDialogLabel inline-flex align-items-center justify-content-between"}>Create/edit Feature <span><i className="pi pi-times mt-2 inline-flex justify-content-end " style={{ cursor: "pointer" }} onClick={() => showDialog(false)}></i></span></label>
        <InputText className='col-12 m-0 addFeatureInput' value={newBenifit} onChange={(e) => { setNewBenifit(e.target.value) }} style={{ height: "3rem" }} />
      </div>
      <div className="grid col-12 m-0">
        <label htmlFor="light" className={"col-12 m-0 featuresDialogLabel"}>Benifit Category</label>
        <Dropdown className='col-12 m-0 addFeatureInput' value={selectedBenifit} onChange={(e) => { setSelectedBenifit(e.target.value) }} style={{ height: "3rem" }} options={benifitCategory} optionLabel="name" />
      </div>
      <div className='col-12 grid justify-content-between m-0 align-items-center'>
        <Button className='addFeatureButton addFeatureButtonFont' label='Create' onClick={() => addBenifit()} />
      </div>
    </div>
  </Dialog>
}


const FeatureDetails = ({benifitListCollection ="nq-plan-benefit-masters",isDesignerPlanBenefit=false}) => {
  const [dialog, showDialog] = useState({
    feature: false,
    deleteFeature: false
  });
  const [admin, setAdmin] = useState(false)
  const [benifitCategory, setBenifitCategory] = useState([])
  const [newBenifit, setNewBenifit] = useState("");
  const [selectedBenifit, setSelectedBenifit] = useState("")
  const [benifits, setBenifits] = useState([])
  const [delItem, setDelItem] = useState({})
  const [refresh, setRefresh] = useState(true)
  const toast = useRef(null);
  const serviceId = localStorage.getItem("serviceId");
  const showSuccess = (content) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: content, life: 3000 });
  }

  const showError = (content) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: content, life: 3000 });
  }

  const showWarn = (content) => {
    toast.current.show({ severity: 'warn', summary: 'Warning', detail: content, life: 3000 });
  }

  const addBenifit = () => {
    if (!newBenifit.trim()) {
      showWarn("Benefit name is required.")
      return;
    }

    if (!selectedBenifit) {
      showWarn("Please select a benefit category.")
      return;
    }

    let reqObj = {
      serviceId: serviceId,
      benefitNm: newBenifit.trim(),
      category: selectedBenifit,
      isDesignerPlanBenefit
    };

    commonConfigCaller("addPlanBenefit", "post", reqObj)
      .then(() => {
        setRefresh((prev) => !prev);
        setNewBenifit(""); // Clear the input field
        setSelectedBenifit(""); // Reset the dropdown
        showDialog({
          "feature": false,
          "deleteFeature": false
        })
        showSuccess("Benefit Added Sucessfully")
      })
      .catch((err) => {
        console.error("Error adding benefit:", err);
        showError("Error adding benefit:")
      });
  };

  useEffect(() => {
    Promise.all([plazaServices.general(serviceId, {}, "nq-benefit_categories"), plazaServices.general(serviceId, {}, benifitListCollection)]).then((res) => {
      let arr = res[0].map((item) => {
        return {
          name: item.category,
          value: item.value
        }
      })
      setBenifitCategory([...arr])
      setBenifits((prev) => [...res[1]]);
      let array = JSON.parse(localStorage.getItem("permissions"));
      if (array.includes("UserManagement.READ") && array.includes("UserManagement.WRITE")) setAdmin(true)
    })
  }, [refresh])

  const editFeature = (item, hide) => {
    let arr = [...benifits];
    arr.map((Item) => {
      if (item.key === Item.key && !Item["showEdit"]) Item["showEdit"] = true
      else Item["showEdit"] = false
      return Item;
    })
    setBenifits(arr)
  }

  const editItem = (benifit, item) => {
    let arr = [...benifits];
    arr.map((Item) => {
      if (item.key === Item.key) Item["benefitNm"] = benifit
      return item;
    })
    setBenifits(arr)

  }

  const submitHandler = async (item, del) => {
    try {
      // Prepare the request body
      const reqBody = {
        serviceId: serviceId,
        key: del ? delItem.key : item.key,
        isDesignerPlanBenefit
      };

      // Add benefit name if not deleting
      if (!del) {
        if (!item.benefitNm.trim()) {
          showWarn("Benefit name cannot be empty.");
          return;
        }
        reqBody.benefitNm = item.benefitNm.trim();
      }
      const endPoint = del ? "deleteBenefit" : "editBenefit";
      await commonConfigCaller(endPoint, "post", reqBody);
      showDialog({
        feature: false,
        deleteFeature: false,
      });
      setRefresh((prev) => !prev);
      showSuccess()
    } catch (error) {
      console.error("Error in submitHandler:", error);
      showError()
    }
  };


  return (
    <div className='grid p-2'>
      <Toast ref={toast} />
      <div className='grid justify-content-between col-12 mt-1 align-items-center'>
        <p className='m-0 featureHeading'>Features</p>
        {admin ? (
          <Button
            className='addFeatureButton addFeatureButtonFont ml-3'
            label='Create feature'
            icon="pi pi-plus"
            onClick={() => showDialog({ ...dialog, feature: true })}
          />
        ) : null}
      </div>
      <div className='grid justify-content-between col-12 mt-3 featureSubHeading align-items-center'>
        <p className='m-0'>Features Name</p>
        <p className='m-0 ml-2'>Category</p>
        <p className='m-0'>{admin ? "Action" : "    "}</p>
      </div>
      <div
        className='grid col-12 align-items-center'
        style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
      >
        {benifits &&
          benifits.map((item) => {
            return (
              <>
                <div
                  className='grid col-6 align-items-center'
                  style={{ color: "#1D1B1C" }}
                >
                  {item["showEdit"] ? (
                    <InputText
                      value={item.benefitNm}
                      className='addFeatureInput'
                      onChange={(e) => {
                        editItem(e.target.value, item);
                      }}
                    />
                  ) : (
                    item.benefitNm + "             "
                  )}
                  {item["showEdit"] ? (
                    <Button
                      className=' m-0 ml-2 lg:mt-0 mt-2'
                      label='Submit'
                      onClick={() => {
                        submitHandler(item, false);
                      }}
                      style={{
                        backgroundColor: "#9AAB89",
                        color: "#1D1B1C",
                        fontSize: "14px",
                        fontStyle: "Larken",
                        fontWeight: 400,
                      }}
                    ></Button>
                  ) : null}
                </div>
                <div className='col-2'>
                  <span
                    className='ml-2'
                    style={{ color: "#9AAB89", fontWeight: 700 }}
                  >
                    {item.category}
                  </span>
                </div>
                <div
                  className='grid col-4 justify-content-end lg:ml-3 ml-0 mt-1'
                  style={{ cursor: "pointer", color: "#BFAD5B" }}
                >
                  {admin ? (
                    <i
                      className={
                        item["showEdit"]
                          ? "pi pi-pencil mr-2"
                          : `pi pi-pencil mr-2 `
                      }
                      onClick={() => {
                        editFeature(item);
                      }}
                    ></i>
                  ) : null}
                </div>
                <hr className='featureshr mt-3' />
              </>
            );
          })}
      </div>
      {dialog && GetDialog(dialog["feature"], () => showDialog({
        "feature": false,
        "deleteFeature": false
      }), newBenifit, setNewBenifit, selectedBenifit, setSelectedBenifit, addBenifit, benifitCategory)}
    </div>
  )
}

export default FeatureDetails