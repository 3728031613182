import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Messages } from "primereact/messages";
import { commonConfigCaller } from "./FeatureDetails";
import CustomInputSwitch from "./CustomInputSwitch";
import { RadioButton } from "primereact/radiobutton";

const EditPlanImgHome = ({ plan, showplanDet, setshowPlanDet, create, allPlan, setCreate, createPath, updatePath, designerPlan }) => {
    const [tempPlan, setTempPlan] = useState(JSON.parse(JSON.stringify(plan)));
    const [disableButton, setDisableButton] = useState(false)
    const msgs = useRef(null);
    const serviceId = localStorage.getItem("serviceId");
    const inputStyle = {
        borderRadius: "20px",
        background: "#FFF",
        border: "1px solid var(--Sage, #9AAB89)",
        padding: "12px 24px",
    };

    const CustomCheckBox = ({ item }) => {
        let Checked = item.value > 0;
        let toggleFun = () => {
            let arr = [...tempPlan.quantifiableBenefits]
            arr.map((Item) => {
                if (Item.key === item.key) item.value = item.value > 0 ? 0 : 1
            })
            setTempPlan({
                ...tempPlan,
                quantifiableBenefits: arr
            })
        }
        return <Checkbox className="ml-1" checked={Checked} onChange={toggleFun} />;
    };

    const showError = (msg) => {
        msgs.current.state.messages = []
        msgs.current.show({
            id: "1",
            sticky: true,
            severity: "error",
            summary: "",
            detail: msg,
            closable: true,
        });

    }

    const editBenifit = (arr, key, e, type) => {
        let keyType = type === "bool" ? "booleanBenefits" : "quantifiableBenefits";

        const updatedArr = arr.map((item) =>
            item.key === key
                ? { ...item, value: type === "bool" ? e.target.checked : Number(e.target.value) }
                : item
        );

        setTempPlan({
            ...tempPlan,
            [keyType]: updatedArr,
        });
    };

    const addSupports = (arr, key, e) => {
        const updatedArr = arr.map((item) => {
            if (item.key === key) item["supports"] = e.target.value || ""
            return item;
        }
        );
        setTempPlan({
            ...tempPlan,
            "booleanBenefits": updatedArr,
        });
    }

    const createPlanHandler = () => {
        let flag = false;

        allPlan.forEach((item) => {
            if (item.planNm === tempPlan.planNm && item.status === "enabled") {
                flag = true;
            }
        });

        if (flag) {
            showError("Please Provide A unique planName");
            return;
        }

        if (!tempPlan.planNm || !tempPlan.planNm.length || !tempPlan.charge || !tempPlan.charge.length) {
            showError("Please provide all required fields for creating a plan.");
            return;
        }

        const reqObj = {
            planId: plan.planId,
            serviceId,
            planNm: tempPlan.planNm,
            charge: Number(tempPlan.charge),
            enabled: tempPlan.enabled,
            quantifiableBenefits: tempPlan.quantifiableBenefits,
            booleanBenefits: tempPlan.booleanBenefits,
        };
        commonConfigCaller(createPath, "post", reqObj)
            .then((res) => {
                msgs.current.clear();
                msgs.current.show({
                    id: "1",
                    sticky: true,
                    severity: "success",
                    summary: "",
                    detail: "Plan successfully created.",
                    closable: true,
                });
                setDisableButton(true);
            })
            .catch((err) => {
                console.error("Error creating plan:", err);
                showError("Failed to create plan.");
            });
    };

    const updatePlanHandler = () => {
        let editedPlan = {};
        let flag = true;

        if (tempPlan.planNm !== plan.planNm) {
            editedPlan["planNm"] = tempPlan.planNm;
            flag = false;
        }
        if (Number(tempPlan.charge) !== Number(plan.charge)) {
            editedPlan["charge"] = Number(tempPlan.charge);
            flag = false;
        }
        if (tempPlan.enabled !== plan.enabled) {
            editedPlan["enabled"] = tempPlan.enabled;
            flag = false;
        }

        const quantifiableBenefits = tempPlan.quantifiableBenefits
            .filter((item, index) => item.value !== plan.quantifiableBenefits[index]?.value)
            .map(item => ({ key: item.key, value: item.value }));
        const booleanBenefits = tempPlan.booleanBenefits
            .filter((item, index) => {
                const currentItem = plan.booleanBenefits[index];

                // Safeguard against undefined currentItem
                if (!currentItem) return true;

                // Compare values and supports logic
                const valueChanged = item.value !== currentItem.value;
                const supportChanged = (!currentItem.supports && item.supports) || currentItem.supports !== item.supports;

                return valueChanged || supportChanged;
            })
            .map(item => ({
                key: item.key,
                value: item.value,
                supports: item.supports,
            }));
        if (quantifiableBenefits.length) {
            editedPlan["quantifiableBenefits"] = quantifiableBenefits;
            flag = false;
        }
        if (booleanBenefits.length) {
            editedPlan["booleanBenefits"] = booleanBenefits;
            flag = false;
        }

        if (flag) {
            showError("Please Update Plan Items");
            return;
        }

        const reqObj = {
            planId: plan.planId,
            serviceId,
            ...editedPlan,
        };
        if (designerPlan) reqObj["planUser"] = "brandAmbassador"
        if (
            ("planNm" in reqObj && (!reqObj.planNm || !reqObj.planNm.length))
        ) {
            showError("Please Don't Leave Any Fields Empty");
            return;
        }
        commonConfigCaller(updatePath, "post", reqObj)
            .then((res) => {
                msgs.current.clear();
                msgs.current.show({
                    id: "1",
                    sticky: true,
                    severity: "success",
                    summary: "",
                    detail: "Plan successfully updated.",
                    closable: true,
                });
                setDisableButton(true);
            })
            .catch((err) => {
                console.error("Error updating plan:", err);
                showError("Failed to update plan.");
            });
    };

    const submitHandler = () => {
        if (isNaN(tempPlan.charge)) {
            showError("Please Give Valid Plan Price")
            return
        }
        if (create) {
            createPlanHandler();
        } else {
            updatePlanHandler();
        }
    };
    const optionWidth = designerPlan ? "lg:col-6" : "lg:col-4"

    return (
        <div className="grid col-12 lg:p-2 p-3">
            <div className="grid col-12 mt-1 align-items-center">
                <Button
                    style={{ background: "#FBF9F3", color: "#1D1B1C" }}
                    icon="pi pi-arrow-left"
                    onClick={() => {
                        setshowPlanDet(!showplanDet)
                        setCreate(!create)
                    }}
                />
                <p className="m-0 featureHeading">Edit/Create Plan</p>
            </div>

            <div className="col-12 grid">
                <div className="grid lg:col-3 col-12 m-0">
                    <label className="col-12 m-0 editFeatureLabel" style={{ fontSize: "13px" }}>Plan Name</label>
                    <InputText
                        className="col-12 m-0"
                        value={tempPlan.planNm}
                        style={inputStyle}
                        disabled={designerPlan}
                        onChange={(e) => setTempPlan({ ...tempPlan, planNm: e.target.value })}
                    />
                </div>
                <div className="grid lg:col-3 col-12 m-0">
                    <label className="col-12 m-0 editFeatureLabel" style={{ fontSize: "13px" }}>Cost in USD$</label>
                    <InputText
                        className="col-12 m-0"
                        value={tempPlan.charge}
                        style={inputStyle}
                        onChange={(e) => setTempPlan({ ...tempPlan, charge: e.target.value })}
                    />
                </div>
            </div>

            <div className="col-12 grid m-0">
                <p className="col-12 m-0 editFeatureLabel" style={{ fontSize: "13px" }}>Activation Status</p>
                <CustomInputSwitch
                    status={tempPlan.enabled}
                    OnChange={(stat) => setTempPlan({ ...tempPlan, enabled: stat })}
                />
            </div>

            <p className="m-0 featureHeading col-12 mt-1" style={{ fontSize: "25px" }}>Features</p>
            <p className="m-0 editFeatureLabel col-12 mt-1" style={{ fontSize: "13px" }}>Available Features</p>
            <div className="col-12 grid justify-content-between align-items-center m-0 p-0">
                {tempPlan.booleanBenefits && [...tempPlan.booleanBenefits].map((item) => (
                    <div key={item.key} className={`${optionWidth} m-0 col-12 grid align-items-center mt-1`}>
                        <div className="col-12 grid p-2 align-items-center" style={{ borderRadius: "8px", border: "1px solid #E5E1CF" }}>
                            <div className="lg:col-9 col-6 grid align-items-center">
                                <Checkbox
                                    className="ml-1"
                                    checked={item.value}
                                    onChange={(e) => editBenifit(tempPlan.booleanBenefits, item.key, e, "bool")}
                                />
                                <p className="m-0 ml-2 editPlanlistf" style={{ fontWeight: 400 }}>{item.name}</p>
                            </div>
                            <div className="lg:col-3 col-6 grid align-items-center ml-4">
                                <p className="m-0 editPlanlistf" style={{ fontWeight: 400 }}>Supports:</p>
                            </div>
                            <InputText
                                className=" p-2 col-12  ml-1"
                                value={item.supports}
                                style={{ ...inputStyle, borderRadius: "8px", height: 25 }}
                                onChange={(e) => addSupports(tempPlan.booleanBenefits, item.key, e, "num")}
                            />
                        </div>
                    </div>
                ))}

                {tempPlan.quantifiableBenefits && [...tempPlan.quantifiableBenefits].map((item) => (
                    <div key={item.key} className={`${optionWidth} m-0 col-12 grid align-items-center mt-1`}>
                        <div className="col-12 grid p-2 align-items-center" style={{ borderRadius: "8px", border: "1px solid #E5E1CF" ,height:"5rem"}}>
                            <div className="lg:col-9 col-6 grid align-items-center">
                                <CustomCheckBox item={item} />
                                <p className="m-0 ml-2 editPlanlistf" style={{ fontWeight: 400 }}>{item.name}</p>
                            </div>
                            <div className="lg:col-3 col-6 grid align-items-center ml-4">
                                <p className="m-0 editPlanlistf" style={{ fontWeight: 400 }}>Limit:</p>
                                <InputText
                                    className="ml-1 p-1"
                                    value={item.value}
                                    disabled={designerPlan}
                                    style={{ ...inputStyle, borderRadius: "2px", width: "50px", height: "20px" }}
                                    onChange={(e) => editBenifit(tempPlan.quantifiableBenefits, item.key, e, "num")}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <hr style={{ backgroundColor: "black" }} />
            <div className="col-12 grid m-0">
                <div className="col-9"></div>
                <div className="lg:col-4 col-12 grid justify-content-between mt-2" style={{ fontWeight: "600" }}>
                    <Button
                        className="addFeatureButton addFeatureButtonFont lg:col-5 col-12 lg:ml-1"
                        label="Save Plan"
                        onClick={submitHandler}
                        disabled={disableButton}
                    />
                </div>
                <Messages ref={msgs} style={{ backgroundColor: "#FBF9F3", color: "#47422F" }} />
            </div>
        </div>
    );
};

export default EditPlanImgHome;
