import { Button } from 'primereact/button'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import service from '../../service/apiService'
import FileListComp from '../../components/FileListComp'
import { InputText } from 'primereact/inputtext'
import DesignerProfile from './DesignerProfile'
import { Customer } from './customer'
import BulkUserCreate from '../../components/BulkUserCreate'

const DesignerList = () => {
    const serviceId = localStorage.getItem("serviceId")
    const [designers, setDesigners] = useState([])
    const [designersDuplicate, setDesignersDuplicate] = useState([])
    const [designerActProj, setDesignerActProj] = useState({})
    const [search, setSearch] = useState("")
    const [openBulkUserCreate, setOpenBulkUserCreate] = useState(false)
    const [clickedDesigner, setClickedDesigner] = useState("")
    const [showAssignCust, setAssignCust] = useState(false)

    useEffect(() => {
        const promises = []
        let nqPayload = { "filter": { "serviceId": serviceId, "user.role": "ihdesigner" }, sort: [["user.name", 1]] }
        let activeProjPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    "$match": {
                        "isProjectActive": true,
                    }
                },
                {
                    "$group": {
                        "_id": "$designerID",
                        "activeProjects": {
                            "$sum": 1
                        }
                    }
                }
            ]
        }

        promises.push(service.httpDBService(nqPayload, "nextqore", "nq-userpermissions"))
        promises.push(service.httpDBService(activeProjPayload, serviceId, "nq-customer-project-designer-mappings"))

        Promise.all(promises).then((res) => {
            const designers = res[0]
            const activeProjects = res[1]

            let designerActiveProjMap = {}
            if (activeProjects.length) {
                for (const obj of activeProjects) {
                    designerActiveProjMap[obj["_id"]] = obj["activeProjects"]
                }
            }
            if (designers.length) {
                setDesigners(designers)
                setDesignersDuplicate(designers)
                setDesignerActProj(designerActiveProjMap)
            }
        }).catch((err) => {
            console.log(err)
        })

    }, [])

    const dataviewGridItem = (data) => {
        return (
            <div className='col-12 md:col-4 p-2 lg:col-3 flex'>
                <div id="" style={{ minWidth: "25%" }} className="flex flex-grow-1">
                    <div className='displayCard designer-card-border p-0 w-full cursor-pointer' id={data.user.email} onClick={(e) => setClickedDesigner(e.currentTarget.id)}>
                        <div className='designerProfilePicture mt-3 mx-auto'>
                            <img style={{ width: "100%", objectFit: "cover" }} src={process.env.REACT_APP_IMAGE_STATIC_URL + "/" + data.image} alt={`${data.user.name}'s profile`} loading='lazy' onError={(e) => e.target.src = (process.env.REACT_APP_IMAGE_STATIC_URL + "/" + "images/1727708277753_img1.jpg")} />
                        </div>
                        <div className='flex flex-column mt-2 align-items-center pb-3 px-3'>
                            <h3 className='mb-1 font-Gerstner-ProgramRegular '>{data.user.name}</h3>
                            <p className='m-1 font-Gerstner-ProgramRegular ' style={{ color: "#47422F", wordBreak: "break-all" }}><i className='pi pi-phone mr-2'></i>{`${data.user.countryCode ?? ""} ${data.user.mobile.split(data.user.countryCode)[1]}`}</p>
                            <p className='m-1 font-Gerstner-ProgramRegular ' style={{ color: "#47422F", wordBreak: "break-all" }}><i className='pi pi-envelope mr-2'></i>{data.user.email}</p>
                            <p className="text-lg font-semibold" style={{ color: "#47422F" }}>Active Projects: {designerActProj[data.user.email] ?? "--"}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const handleSearch = (val) => {
        let filteredDesigner = designersDuplicate.filter((item) => item.user.name.includes(val) || item.user.email.includes(val));
        setSearch(val);
        setDesigners(filteredDesigner);
    };

    const onBack = useCallback(() => {
        setClickedDesigner("")
    },[])

    if(showAssignCust) return <Customer setShowPage={setAssignCust} />
    if (clickedDesigner) return <DesignerProfile user={clickedDesigner} back={onBack} />
    if(openBulkUserCreate) return <BulkUserCreate setOpenBulkUserCreate={setOpenBulkUserCreate} />
    else return (
        <>
            <div className='flex align-items-center justify-content-between flex-wrap'>
                <div className='p-0 lg:col-2 col-12'>
                    <h2 className='font-larken m-0 charcoal-color'>Designers</h2>
                </div>
                <div className='p-0 lg:col-5 col-12'>
                    <div className="p-input-icon-right overflow-hidden w-full bg-white designer-card-border" style={{ borderRadius: "2rem" }}>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon bg-white p-2 pr-0" style={{ background: "#FAFAFA" }}>
                                <i className="pi pi-search text-2xl" style={{ color: "#BFAD5B" }}></i>
                            </span>
                            <InputText value={search} onChange={(e) => handleSearch(e.target.value)} style={{ background: "#FAFAFA" }} className="bg-white p-2 w-full outline-none" placeholder="Search" />
                        </div>
                    </div>
                </div>
                <div className='p-0 lg:col-5 col-12 flex justify-content-end' style={{gap: 10}}>
                <div className='p-0'>
                    <Button onClick={() => setAssignCust(!showAssignCust)} label="ASSIGN CUSTOMER" icon="" className="p-button-rounded p-button-info font-Gerstner-ProgramRegular text-base font-semibold px-4 charcoal-color" style={{ float: "right", letterSpacing: "1.44px", backgroundColor: "#BFAD5B" }} />
                </div>
                <div className='p-0'>
                    <Button onClick={() => setOpenBulkUserCreate(!openBulkUserCreate)} label="ADD DESIGNERS" icon="pi pi-plus" className="p-button-rounded p-button-info font-Gerstner-ProgramRegular text-base font-semibold px-4 charcoal-color" style={{ float: "right", letterSpacing: "1.44px", backgroundColor: "#9AAB89 " }} />
                </div>
                </div>
                
            </div>
            <FileListComp id={"fu_list_recent_projects"} rows={8} defLayout="grid" switchLayout={false} emptyMessage="No Designers Found" dataviewGridItem={dataviewGridItem} dataviewValue={designers} />
        </>
    )
}

export default DesignerList